define("bb-web/pods/categories/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y+fF7TCh",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"mkt/alert-bar\"],false],[0,\"\\n\"],[1,[28,\"mkt-nav\",null,[[\"isMkt\"],[true]]],false],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/categories/template.hbs"
    }
  });

  _exports.default = _default;
});