define("bb-web/pods/user/locations/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "elZgO0o+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"section-block\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"section-title flex-between\"],[8],[0,\"\\n\\t\\t\"],[7,\"h6\",true],[8],[0,\"Locations\"],[9],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"openModal\",\"location/location-form\",[24,[\"model\"]]]],[8],[0,\"Add Location\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"section-body lg\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"location/location-list\",null,[[\"model\",\"dataLoading\",\"showList\"],[[24,[\"model\"]],true,true]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/user/locations/index/template.hbs"
    }
  });

  _exports.default = _default;
});