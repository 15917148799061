define("bb-web/pods/components/service/service-duplicate-btn/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    classNames: '',
    text: '',
    // Models
    model: null,
    service: null,
    provider: null,
    dropdown: null,
    // Properties
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    },
    // Actions
    click: function click() {
      this._duplicateService(Ember.get(this, 'service'), Ember.get(this, 'provider'));
    },
    // Methods
    _duplicateService: function _duplicateService(service, provider) {
      this._genericSuccess("Coming soon..."); // let tagProps = tag.getProperties('photo', 'description');
      // set(tagProps, 'name', 'Copy ' + tag.get('name'));
      // let newTag = this.store.createRecord('tag');
      // newTag.setProperties(tagProps);
      // newTag.set('organizerId', organizer.get('id'));
      // get(this, 'dropdown').actions.close();
      // this.send('openModal', 'tag/tag-form', service, provider);

    }
  });

  _exports.default = _default;
});