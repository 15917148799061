define("bb-web/pods/components/promo/promo-card/component", ["exports", "bb-web/mixins/scheduler", "ember-concurrency"], function (_exports, _scheduler, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_scheduler.default, {
    tagName: 'div',
    classNames: 'app-card',
    classNameBindings: ['showList', 'selected', 'css'],
    showList: false,
    css: '',
    // Models
    promo: null,
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});