define("bb-web/pods/components/customer/customer-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iIGK6m0r",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"fetchData\",\"isRunning\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"loading-indicator\",null,[[\"size\",\"classNames\"],[\"40\",\"loading-section\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"customers\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"showTable\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"table-responsive\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"customer/customer-table\",null,[[\"model\",\"customers\",\"provider\",\"sortValue\",\"sortDirection\",\"sortTable\"],[[24,[\"model\"]],[24,[\"customers\"]],[24,[\"provider\"]],[24,[\"sortValue\"]],[24,[\"sortDirection\"]],[28,\"action\",[[23,0,[]],\"sortList\"],null]]]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showPaginator\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"simple-pagination\",null,[[\"pageNumber\",\"pageSize\",\"recordCount\",\"class\",\"onPageSelect\"],[[24,[\"customers\",\"meta\",\"pagination\",\"page\"]],[24,[\"customers\",\"meta\",\"pagination\",\"page_size\"]],[24,[\"customers\",\"meta\",\"pagination\",\"total_records\"]],\"paginator flex-center\",[28,\"action\",[[23,0,[]],\"fetchPage\"],null]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"empty-message\",null,[[\"faIcon\",\"title\",\"type\",\"description\"],[\"user\",\"Customers\",[24,[\"type\"]],[24,[\"description\"]]]]],false],[0,\"\\n\\t\"]],\"parameters\":[]}]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/customer/customer-list/template.hbs"
    }
  });

  _exports.default = _default;
});