define("bb-web/pods/admin/bookings/detail/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['include'],
    include: 'customer,location,line_items,misc_items,review,card',
    taskRunning: false
  });

  _exports.default = _default;
});