define("bb-web/initializers/api-response", ["exports", "bb-web/mixins/api-response"], function (_exports, _apiResponse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.Controller.reopen(_apiResponse.default);
    Ember.Route.reopen(_apiResponse.default);
    Ember.Component.reopen(_apiResponse.default);
    Ember.Service.reopen(_apiResponse.default);
  }

  var _default = {
    name: 'api-response',
    initialize: initialize
  };
  _exports.default = _default;
});