define("bb-web/templates/footers/simple-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KbrbvnIW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"footer\",true],[10,\"class\",\"container-fluid simple-footer flex-start flex-height-footer\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"simple-footer-links\"],[8],[0,\"\\n\\t\\t\"],[7,\"ul\",true],[10,\"class\",\"flex-center\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"li\",true],[10,\"class\",\"primary-font\"],[8],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-text-dark-gray\",\"mkt.index\"]],{\"statements\":[[7,\"b\",true],[8],[0,\"© Beauty Broker\"],[9]],\"parameters\":[]},null],[9],[0,\"\\n\\t\\t\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-text-dark-gray\",\"mkt.legal.terms\"]],{\"statements\":[[0,\"Terms\"]],\"parameters\":[]},null],[9],[0,\"\\n\\t\\t\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-text-dark-gray\",\"mkt.legal.privacy\"]],{\"statements\":[[0,\"Privacy\"]],\"parameters\":[]},null],[9],[0,\"\\n\\t\\t\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-text-dark-gray\",\"mkt.contact\"]],{\"statements\":[[0,\"Support\"]],\"parameters\":[]},null],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/templates/footers/simple-footer.hbs"
    }
  });

  _exports.default = _default;
});