define("bb-web/pods/mkt/news/detail/route", ["exports", "bb-web/config/environment", "buttercms"], function (_exports, _environment, _buttercms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var butter = (0, _buttercms.default)(_environment.default.butterCMS.apiKey);

  var _default = Ember.Route.extend({
    model: function model(params) {
      return butter.post.retrieve(params.slug).then(function (response) {
        return response.data.data;
      });
    },
    afterModel: function afterModel(model) {
      this._setHeadTags(model);
    },
    setupController: function setupController(controller, model) {
      controller.set('article', model);
      controller.set('article.url', _environment.default.APP.defaultWebHost + '/news/' + model.url);
    },
    // Methods
    _setHeadTags: function _setHeadTags(model) {
      Ember.set(this, 'headData.pageTitle', "".concat(model.title, " - News - Beauty Broker"));
      Ember.set(this, 'headData.title', "".concat(model.title));
      Ember.set(this, 'headData.description', "".concat(model.summary));
      Ember.set(this, 'headData.image', "".concat(model.featured_image));
      Ember.set(this, 'headData.twitterImage', "".concat(model.featured_image));
    }
  });

  _exports.default = _default;
});