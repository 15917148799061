define("bb-web/pods/components/provider/provider-claim-starter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2jFuGOd2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"sticky-element\",null,[[\"top\",\"bottom\",\"enabled\"],[125,340,[24,[\"isDesktop\"]]]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"section-block no-m\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-title\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h6\",true],[8],[0,\"Claim Provider\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-body\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h6\",true],[10,\"className\",\"gray\"],[8],[0,\"\\n\\t\\t\\t\\t\\tThis provider hasn't activate their account yet.\\n\\t\\t\\t\\t\\tIf this is you, please claim your profile so you can accept bookings via Beauty Broker.\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-footer\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-primary btn-xl btn-block\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"openModal\",\"provider/provider-claim-form\",[24,[\"provider\"]]]],[8],[0,\"\\n\\t\\t\\t\\tSubmit Claim\"],[1,[28,\"fa-icon\",[\"chevron-right\"],[[\"class\"],[\"right small\"]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/provider/provider-claim-starter/template.hbs"
    }
  });

  _exports.default = _default;
});