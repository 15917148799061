define("bb-web/pods/booking/model", ["exports", "ember-data", "moment", "bb-web/mixins/statuses"], function (_exports, _emberData, _moment, _statuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend(_statuses.default, {
    card: belongsTo(),
    conversation: belongsTo(),
    customer: belongsTo(),
    location: belongsTo(),
    provider: belongsTo(),
    review: belongsTo(),
    user: belongsTo(),
    charges: hasMany(),
    lineItems: hasMany('line-item'),
    miscItems: hasMany('misc-item'),
    notes: hasMany(),
    refunds: hasMany(),
    services: hasMany(),
    // Keys
    cardId: attr(),
    customerId: attr(),
    // locationId: attr(),
    providerId: attr(),
    userId: attr(),
    // Attrs
    status: attr(),
    subtotal: attr(),
    discount: attr(),
    travelCharge: attr(),
    startDate: attr(),
    duration: attr(),
    confirmationCode: attr(),
    specialInstructions: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    // Accessors
    skipFutureCheck: attr(),
    skipPolicyCheck: attr(),
    skipScheduleCheck: attr(),
    // Misc
    summary: attr(),
    depositPaid: attr(),
    primaryPaid: attr(),
    // Computed
    displayCC: Ember.computed('confirmationCode', function () {
      return '#' + Ember.get(this, 'confirmationCode');
    }),
    // Statuses
    statusOptions: Ember.computed('status', function () {
      return ['pending', 'accepted', 'declined', 'completed', 'canceled'];
    }),
    isDraft: Ember.computed.equal('status', 'draft'),
    isPending: Ember.computed.equal('status', 'pending'),
    isAccepted: Ember.computed.equal('status', 'accepted'),
    isDeclined: Ember.computed.equal('status', 'declined'),
    isCompleted: Ember.computed.equal('status', 'completed'),
    isCanceled: Ember.computed.equal('status', 'canceled'),
    // Dates
    startTime: Ember.computed('startDate', function () {
      var startDate = !Ember.isEmpty(Ember.get(this, 'startDate')) ? (0, _moment.default)(Ember.get(this, 'startDate')) : (0, _moment.default)().hour(0);
      var hour = startDate.hour();
      return hour != 0 ? startDate : null;
    }),
    displayStartDate: Ember.computed('startDate', function () {
      return (0, _moment.default)(Ember.get(this, 'startDate'));
    }),
    displayEndDate: Ember.computed('startDate', 'duration', function () {
      return (0, _moment.default)(Ember.get(this, 'startDate')).add(Ember.get(this, 'duration'), 'm');
    }),
    // Line Items
    lineItem: Ember.computed('lineItems.[]', function () {
      return Ember.get(this, 'lineItems.firstObject');
    }),
    lineItemCount: Ember.computed('lineItems.[]', function () {
      return Ember.get(this, 'lineItems.length');
    }),
    // Finances
    revenue: Ember.computed('subtotal', 'travelCharge', 'discount', function () {
      var subtotal = Ember.get(this, 'subtotal') ? parseFloat(Ember.get(this, 'subtotal')) : 0;
      var travelCharge = Ember.get(this, 'travelCharge') ? parseFloat(Ember.get(this, 'travelCharge')) : 0;
      var discount = Ember.get(this, 'discount') ? parseFloat(Ember.get(this, 'discount')) : 0;
      return subtotal + travelCharge - discount;
    }),
    platformFee: Ember.computed('revenue', function () {
      var revenue = Ember.get(this, 'revenue');
      return revenue ? revenue * 0.07 : 0;
    }),
    stripeFee: Ember.computed('revenue', 'platformFee', function () {
      var revenue = Ember.get(this, 'revenue');
      var platformFee = Ember.get(this, 'platformFee');
      return revenue ? (revenue + platformFee) / 0.971 + 0.60 - (revenue + platformFee) : 0;
    }),
    serviceFee: Ember.computed('platformFee', 'stripeFee', function () {
      var platformFee = Ember.get(this, 'platformFee');
      var stripeFee = Ember.get(this, 'stripeFee');
      return platformFee + stripeFee;
    }),
    total: Ember.computed('revenue', 'serviceFee', function () {
      return Ember.get(this, 'revenue') + Ember.get(this, 'serviceFee');
    }),
    // Finance Preview
    lineItemSubtotals: Ember.computed('lineItems.[]', 'lineItems.@each.{amount,qty}', function () {
      return Ember.get(this, 'lineItems').map(function (item) {
        return parseFloat(item.get('amount') * parseFloat(item.get('qty')));
      });
    }),
    miscItemSubtotals: Ember.computed('miscItems.[]', 'miscItems.@each.{amount,qty}', function () {
      return Ember.get(this, 'miscItems').map(function (item) {
        return parseFloat(item.get('amount') * parseFloat(item.get('qty')));
      });
    }),
    durations: Ember.computed('lineItems.[]', 'lineItems.@each.{amount,qty}', function () {
      return Ember.get(this, 'lineItems') ? Ember.get(this, 'lineItems').mapBy('duration') : [];
    }),
    lineItemSubtotal: Ember.computed.sum('lineItemSubtotals'),
    itemSubtotal: Ember.computed.sum('miscItemSubtotals'),
    previewDuration: Ember.computed.sum('durations'),
    previewSubtotal: Ember.computed('lineItemSubtotal', 'itemSubtotal', function () {
      var lineItemSubtotal = parseFloat(Ember.get(this, 'lineItemSubtotal')) || 0;
      var itemSubtotal = parseFloat(Ember.get(this, 'itemSubtotal')) || 0;
      return lineItemSubtotal + itemSubtotal;
    }),
    previewRevenue: Ember.computed('previewSubtotal', 'travelCharge', 'discount', function () {
      var previewSubtotal = parseFloat(Ember.get(this, 'previewSubtotal')) || 0;
      var travelCharge = parseFloat(Ember.get(this, 'travelCharge')) || 0;
      var discount = parseFloat(Ember.get(this, 'discount')) || 0;
      return previewSubtotal + travelCharge - discount;
    }),
    previewPlatformFee: Ember.computed('previewRevenue', function () {
      var previewRevenue = Ember.get(this, 'previewRevenue');
      return previewRevenue ? previewRevenue * 0.07 : 0;
    }),
    previewStripeFee: Ember.computed('previewRevenue', 'previewPlatformFee', 'status', 'depositPaid', 'primaryPaid', function () {
      var previewRevenue = Ember.get(this, 'previewRevenue');
      var previewPlatformFee = Ember.get(this, 'previewPlatformFee');

      if (Ember.get(this, 'isCompleted') && !Ember.get(this, 'depositPaid')) {
        return previewRevenue ? (previewRevenue + previewPlatformFee) / 0.971 + 0.30 - (previewRevenue + previewPlatformFee) : 0;
      }

      return previewRevenue ? (previewRevenue + previewPlatformFee) / 0.971 + 0.60 - (previewRevenue + previewPlatformFee) : 0;
    }),
    previewServiceFee: Ember.computed('previewPlatformFee', 'previewStripeFee', function () {
      return Ember.get(this, 'previewPlatformFee') + Ember.get(this, 'previewStripeFee');
    }),
    previewTotal: Ember.computed('previewRevenue', 'previewServiceFee', function () {
      return Ember.get(this, 'previewRevenue') + Ember.get(this, 'previewServiceFee');
    }),
    previewDueToday: Ember.computed('previewRevenue', 'status', 'depositPaid', function () {
      var revenue = Ember.get(this, 'previewRevenue');

      if (Ember.get(this, 'isAccepted') && !Ember.get(this, 'depositPaid')) {
        var charge = revenue * 0.20;
        var platformFees = parseFloat(charge * 0.07);
        return charge + this.calcFees(charge, platformFees);
      }

      if (Ember.get(this, 'isCompleted') && !Ember.get(this, 'depositPaid') && !Ember.get(this, 'primaryPaid')) {
        var _charge = revenue * 1;

        var _platformFees = parseFloat(_charge * 0.07);

        return _charge + this.calcFees(_charge, _platformFees);
      } else if (Ember.get(this, 'isCompleted') && Ember.get(this, 'depositPaid') && !Ember.get(this, 'primaryPaid')) {
        var _charge2 = revenue * 0.8;

        var _platformFees2 = parseFloat(_charge2 * 0.07);

        return _charge2 + this.calcFees(_charge2, _platformFees2);
      }

      return 0;
    }),
    // Methods
    calcFees: function calcFees(revenue, platformFee) {
      return platformFee + ((revenue + platformFee) / 0.971 + 0.30 - (revenue + platformFee));
    }
  });

  _exports.default = _default;
});