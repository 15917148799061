define("bb-web/pods/components/city/city-card/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'app-card',
    classNameBindings: ['showCard', 'showList', 'css'],
    showCard: false,
    showList: false,
    css: '',
    // Models
    service: null
  });

  _exports.default = _default;
});