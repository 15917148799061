define("bb-web/pods/components/breadcrumb-trail/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'breadcrumb-trail',
    classNameBindings: ['isMkt', 'isAdmin']
  });

  _exports.default = _default;
});