define("bb-web/pods/components/wrapper/date-picker-wrapper/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Props
    type: 'dropdown',
    size: 'md',
    date: '',
    // Computed
    momentDate: Ember.computed('date', function () {
      if (this.get('date')) {
        return (0, _moment.default)(Ember.get(this, 'date'));
      }

      return (0, _moment.default)();
    }),
    centerDate: Ember.computed('date', function () {
      if (this.get('date')) {
        return new Date((0, _moment.default)(this.get('date')).format('MMM DD, YYYY HH:MM'));
      }

      return new Date((0, _moment.default)().format('MMM DD, YYYY HH:MM'));
    }),
    selectedDate: Ember.computed('date', function () {
      if (this.get('date')) {
        return new Date((0, _moment.default)(this.get('date')).format('MMM DD, YYYY HH:MM'));
      }

      return new Date((0, _moment.default)().format('MMM DD, YYYY HH:MM'));
    }),
    // Hooks
    didInsertElement: function didInsertElement() {},
    // Actions
    actions: {
      setWidth: function setWidth() {
        var triggerWidth = document.getElementById('dp_trigger').clientWidth + 'px';
        document.getElementsByClassName('datedrop')[0].style.width = triggerWidth;
      },
      setDate: function setDate(date) {
        var hours = (0, _moment.default)(Ember.get(this, 'momentDate')).hours();
        var minutes = (0, _moment.default)(Ember.get(this, 'momentDate')).minutes();
        var newDate = date.moment.hours(hours).minutes(minutes);
        this.setDate(newDate.format());
      }
    }
  });

  _exports.default = _default;
});