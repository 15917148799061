define("bb-web/pods/browse/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['latitude', 'longitude', 'city', 'city_id', 'category_id', 'date'],
    latitude: null,
    longitude: null,
    city: null,
    city_id: null,
    category_id: null,
    date: null
  });

  _exports.default = _default;
});