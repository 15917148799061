define("bb-web/pods/components/photo/photo-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'app-card',
    classNameBindings: ['css'],
    css: '',
    isCollage: false,
    // Model
    model: null,
    provider: null,
    photos: null,
    photo: null,
    // Events
    click: function click() {
      Ember.get(this, 'showCollage') ? this.showPhotoSwiper(Ember.get(this, 'photo')) : null;
    },
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    },
    // Actions
    actions: {
      editPhoto: function editPhoto(photo) {
        var model = Ember.Object.create();
        model.set('photo', photo);
        model.set('photos', Ember.get(this, 'photos'));
        model.set('provider', Ember.get(this, 'provider'));
        this.send('openModal', 'photo/photo-form', model);
      }
    }
  });

  _exports.default = _default;
});