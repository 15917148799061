define("bb-web/pods/components/mkt/provider-tour/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: '',
    swiper: null,
    swipeOptions: null,
    slideIndex: 0,
    // Models
    photos: null,
    photo: null,
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);

      this._setSwipeOptions();

      Ember.set(this, 'isLoaded', true);
    },
    didInsertElement: function didInsertElement() {
      this._setSwiper();
    },
    // Actions
    actions: {
      closeSwiper: function closeSwiper() {
        Ember.set(this, 'showPhotoSwiper', false);
      },
      prevSlide: function prevSlide() {
        Ember.get(this, 'swiper').slidePrev();

        this._setSwiper();
      },
      nextSlide: function nextSlide() {
        Ember.get(this, 'swiper').slideNext();

        this._setSwiper();
      },
      showSlide: function showSlide(slide) {
        Ember.get(this, 'swiper').slideTo(slide);

        this._setSwiper();
      },
      slideChanged: function slideChanged(slide) {
        var swiper = Ember.get(this, 'swiper');
        Ember.set(this, 'slideIndex', swiper.activeIndex);
      }
    },
    // Methods
    _setSwipeOptions: function _setSwipeOptions() {
      Ember.set(this, 'swipeOptions', {
        autoplay: {
          delay: 7000
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        loop: true,
        parallax: true,
        slidesPerView: 1
      });
    },
    _setSwiper: function _setSwiper() {
      var swiper = document.querySelector('.swiper-container').swiper;
      Ember.set(this, 'swiper', swiper);
      Ember.set(this, 'slideIndex', Ember.isEmpty(swiper.activeIndex) ? 1 : swiper.activeIndex);
    }
  });

  _exports.default = _default;
});