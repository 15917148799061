define("bb-web/pods/components/setup/setup-flow-empty/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C4bZBwZ1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"empty-img primary-color\"],[8],[0,\"\\n\\t\"],[1,[28,\"fa-icon\",[\"check-circle\"],[[\"size\"],[\"3x\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"empty-content\"],[8],[0,\"\\n\\t\"],[7,\"h5\",true],[10,\"class\",\"bold\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\\t\"],[7,\"p\",true],[10,\"class\",\"\"],[8],[1,[22,\"msg\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"flex-center\"],[8],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-text-gray margin-right\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"editStep\"]]],null]]],[8],[0,\"\\n\\t\\t\"],[1,[28,\"fa-icon\",[\"edit\"],[[\"class\"],[\"left\"]]],false],[0,\"Edit Step\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-text-primary\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"nextAction\"]]],null]]],[8],[0,\"\\n\\t\\tNext Step\"],[1,[28,\"fa-icon\",[\"chevron-right\"],[[\"class\"],[\"right small\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/setup/setup-flow-empty/template.hbs"
    }
  });

  _exports.default = _default;
});