define("bb-web/pods/location/serializer", ["exports", "bb-web/pods/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    // Props
    nested: true,
    serialize: function serialize(snapshot) {
      var data = this._super.apply(this, arguments);

      if (Ember.get(this, 'nested')) {
        var json = {
          id: snapshot.attr('locationableType') != 'Booking' ? null : snapshot.id,
          name: snapshot.attr('name'),
          address: snapshot.attr('address'),
          addressTwo: snapshot.attr('addressTwo'),
          city: snapshot.attr('city'),
          state: snapshot.attr('state'),
          country: snapshot.attr('country'),
          postalCode: snapshot.attr('postalCode'),
          latitude: snapshot.attr('latitude'),
          longitude: snapshot.attr('longitude')
        };
        return json;
      }

      return data;
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key) {
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});