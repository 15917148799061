define("bb-web/pods/payout/model", ["exports", "ember-data", "bb-web/mixins/statuses"], function (_exports, _emberData, _statuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend(_statuses.default, {
    booking: belongsTo(),
    promo: belongsTo(),
    provider: belongsTo(),
    bankAccount: belongsTo('bank-account'),
    // Keys
    bankAccountId: attr(),
    providerId: attr(),
    // Attrs
    status: attr(),
    amount: attr(),
    statementDescriptor: attr(),
    failureReason: attr(),
    createdAt: attr(),
    // Computed
    statusOptions: Ember.computed('status', function () {
      return ['draft', 'pending', 'succeeded', 'failed'];
    })
  });

  _exports.default = _default;
});