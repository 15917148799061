define("bb-web/mixins/api-response", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Ember Data
    _serverSuccess: function _serverSuccess(msg) {
      var flashMessages = Ember.get(this, 'flashMessages');
      flashMessages.success("".concat(msg));
    },
    _serverError: function _serverError(errors) {
      var flashMessages = Ember.get(this, 'flashMessages');

      if (Array.isArray(errors.errors)) {
        errors.errors.forEach(function (error) {
          flashMessages.danger(error.detail.capitalize());
          console.log(error.detail.capitalize());
        });
      } else {
        console.log(errors.message);
      }
    },
    _serverException: function _serverException(errors) {
      var flashMessages = Ember.get(this, 'flashMessages');
      errors.errors.forEach(function (error) {
        flashMessages.danger(error.detail.capitalize());
        console.log(error.detail.capitalize());
      });
    },
    _quietServerError: function _quietServerError(errors) {
      errors.errors.forEach(function (error) {
        console.log(error.detail.capitalize());
      });
    },
    // General
    _genericSuccess: function _genericSuccess(msg) {
      var flashMessages = Ember.get(this, 'flashMessages');
      flashMessages.success("".concat(msg));
      console.log(msg);
    },
    _genericError: function _genericError(msg) {
      var flashMessages = Ember.get(this, 'flashMessages');
      flashMessages.danger("".concat(msg));
      console.log(msg);
    },
    _quietError: function _quietError(msg) {
      console.log(msg);
    }
  });

  _exports.default = _default;
});