define("bb-web/components/stripe-card-cvc", ["exports", "ember-stripe-elements/components/stripe-card-cvc"], function (_exports, _stripeCardCvc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _stripeCardCvc.default;
    }
  });
});