define("bb-web/pods/admin/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Services
    fastboot: Ember.inject.service(),
    sideMenu: Ember.inject.service(),
    // Hooks
    model: function model(params) {
      return this.store.findRecord('provider', params.provider_id, {
        include: 'location'
      });
    },
    afterModel: function afterModel(model, transition) {
      this._setHeadTags(model, transition);

      this._setAdapters(model);
    },
    setupController: function setupController(controller, model) {
      controller.set('provider', model);
    },
    activate: function activate() {
      var _this = this;

      this.sideMenu.set('isAdmin', true);

      if (Ember.get(this, 'fastboot.isFastBoot')) {
        return;
      }

      ;
      Ember.run.scheduleOnce('afterRender', function () {
        window.mixpanel.track('Viewed dashboard', {
          provider: _this.controller.get('provider.id')
        });
      });
    },
    deactivate: function deactivate() {
      this.sideMenu.set('isAdmin', false);

      if (Ember.get(this, 'fastboot.isFastBoot')) {
        return;
      }

      ;
      this.store ? this._clearAdapters() : null;
    },
    // Methods
    _setHeadTags: function _setHeadTags(model, transition) {
      Ember.set(this, 'headData.pageTitle', "".concat(model.get('name'), " - Dashboard - Beauty Broker"));
      Ember.set(this, 'headData.title', "".concat(model.get('name'), "'s Dashboard"));
    },
    _setAdapters: function _setAdapters(model) {
      this.store.adapterFor('activity').set('providerID', model.get('id'));
      this.store.adapterFor('analytic').set('providerID', model.get('id'));
      this.store.adapterFor('balance').set('providerID', model.get('id'));
      this.store.adapterFor('bank-account').set('providerID', model.get('id'));
      this.store.adapterFor('booking').set('providerID', model.get('id'));
      this.store.adapterFor('card').set('providerID', model.get('id'));
      this.store.adapterFor('charge').set('providerID', model.get('id'));
      this.store.adapterFor('conversation').set('providerID', model.get('id'));
      this.store.adapterFor('customer').set('providerID', model.get('id'));
      this.store.adapterFor('event').set('providerID', model.get('id'));
      this.store.adapterFor('interval').set('providerID', model.get('id'));
      this.store.adapterFor('location').set('providerID', model.get('id'));
      this.store.adapterFor('message').set('providerID', model.get('id'));
      this.store.adapterFor('note').set('providerID', model.get('id'));
      this.store.adapterFor('payout').set('providerID', model.get('id'));
      this.store.adapterFor('photo').set('providerID', model.get('id'));
      this.store.adapterFor('policy').set('providerID', model.get('id'));
      this.store.adapterFor('promo').set('providerID', model.get('id'));
      this.store.adapterFor('refund').set('providerID', model.get('id'));
      this.store.adapterFor('review').set('providerID', model.get('id'));
      this.store.adapterFor('schedule').set('providerID', model.get('id'));
      this.store.adapterFor('service').set('providerID', model.get('id'));
      this.store.adapterFor('setup').set('providerID', model.get('id'));
    },
    _clearAdapters: function _clearAdapters() {
      this.store.adapterFor('activity').set('providerID', null);
      this.store.adapterFor('analytic').set('providerID', null);
      this.store.adapterFor('balance').set('providerID', null);
      this.store.adapterFor('bank-account').set('providerID', null);
      this.store.adapterFor('booking').set('providerID', null);
      this.store.adapterFor('card').set('providerID', null);
      this.store.adapterFor('charge').set('providerID', null);
      this.store.adapterFor('conversation').set('providerID', null);
      this.store.adapterFor('customer').set('providerID', null);
      this.store.adapterFor('event').set('providerID', null);
      this.store.adapterFor('interval').set('providerID', null);
      this.store.adapterFor('location').set('providerID', null);
      this.store.adapterFor('message').set('providerID', null);
      this.store.adapterFor('note').set('providerID', null);
      this.store.adapterFor('payout').set('providerID', null);
      this.store.adapterFor('photo').set('providerID', null);
      this.store.adapterFor('policy').set('providerID', null);
      this.store.adapterFor('promo').set('providerID', null);
      this.store.adapterFor('refund').set('providerID', null);
      this.store.adapterFor('review').set('providerID', null);
      this.store.adapterFor('schedule').set('providerID', null);
      this.store.adapterFor('service').set('providerID', null);
      this.store.adapterFor('setup').set('providerID', null);
    }
  });

  _exports.default = _default;
});