define("bb-web/pods/components/loading-indicator/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'loading-indicator',
    classNameBindings: [],
    // Properties
    size: '40',
    button: false,
    color: null,
    svg: '',
    // Computed
    defaultColors: Ember.computed(function () {
      return {
        white: '#FFFFFF',
        purp: '#B2499D',
        red: '#FA5655',
        gray: '#9EA7B3'
      };
    }),
    svgColor: Ember.computed('color', function () {
      var color = Ember.get(this, 'color');
      return this.defaultColors[color] || this.defaultColors['purp'];
    }),
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);

      var size = Ember.get(this, 'size');
      Ember.set(this, 'width', size);
      Ember.set(this, 'height', size);
    }
  });

  _exports.default = _default;
});