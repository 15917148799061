define("bb-web/pods/admin/payouts/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    status: '',
    // Actions
    actions: {
      setFilter: function setFilter(filter, value) {
        Ember.set(this, filter, value);
      }
    }
  });

  _exports.default = _default;
});