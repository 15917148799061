define("bb-web/pods/mkt/welcome/route", ["exports", "bb-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Computed
    isAuthenticated: Ember.computed.alias('session.isAuthenticated'),
    stagingSession: Ember.computed.alias('currentUser.stagingSession'),
    // Actions
    actions: {
      willTransition: function willTransition(transition) {
        var isStaging = _environment.default.environment == 'staging';

        if (isStaging && !Ember.get(this, 'isAuthenticated') && !Ember.get(this, 'stagingSession')) {
          this._genericError('Please enter the correct password to continue.');

          transition.abort();
        }
      }
    }
  });

  _exports.default = _default;
});