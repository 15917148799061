define("bb-web/extensions/controller-extensions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.reopen({
    // Services
    intercom: Ember.inject.service(),
    // Actions
    actions: {
      copySuccess: function copySuccess() {
        this._serverSuccess('URL copied to clipboard');
      },
      openModal: function openModal(name, model, modelOne, modelTwo) {
        this.routeFor('application').send('openModal', name, model, modelOne, modelTwo);
      },
      closeModal: function closeModal() {
        this.routeFor('application').send('closeModal');
      },
      openIntercom: function openIntercom() {
        Ember.get(this, 'intercom').showNewMessage();
      }
    },
    // Methods
    routeFor: function routeFor() {
      var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'application';
      return this._lookupFor('route', path);
    },
    controllerFor: function controllerFor() {
      var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'application';
      return this._lookupFor('controller', path);
    },
    componentFor: function componentFor(path) {
      return this._lookupFor('component', path);
    },
    _lookupFor: function _lookupFor(type, path) {
      return Ember.getOwner(this).lookup("".concat(type, ":").concat(path));
    },
    _refreshModel: function _refreshModel() {
      this.routeFor(this.router.currentRoute.name).refresh();
    }
  });

  _exports.default = _default;
});