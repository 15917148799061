define("bb-web/pods/components/message/message-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3s8R3GLx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\\t\"],[7,\"label\",true],[8],[0,\"Your Message\"],[9],[0,\"\\n\\t\"],[1,[28,\"misc/modified-text-area\",null,[[\"elementId\",\"rows\",\"value\",\"class\",\"placeholder\",\"modifiedSubmit\"],[[24,[\"elementID\"]],2,[24,[\"message\",\"message\"]],\"form-control\",\"Say something...\",[28,\"action\",[[23,0,[]],\"saveMessage\",[24,[\"message\"]]],null]]]],false],[0,\"\\t\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"flex-between\"],[8],[0,\"\\n\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-primary\"],[10,\"type\",\"submit\"],[8],[0,\"\\n\\t\\t\"],[4,\"if\",[[24,[\"saveMessage\",\"isRunning\"]]],null,{\"statements\":[[1,[28,\"loading-indicator\",null,[[\"color\",\"size\"],[\"white\",24]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[]},{\"statements\":[[1,[28,\"fa-icon\",[\"paper-plane\"],null],false]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"small d-none d-md-block\"],[8],[0,\"Press \"],[7,\"code\",true],[8],[0,\"ctrl + enter\"],[9],[0,\" to break line\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/message/message-form/template.hbs"
    }
  });

  _exports.default = _default;
});