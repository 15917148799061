define("bb-web/pods/components/misc/modified-text-area/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextArea.extend({
    elementId: '',
    // Events
    keyDown: function keyDown(event) {
      if (this._pressedModifier(event) && this._pressedEnter(event)) {
        var element = document.getElementById(Ember.get(this, 'elementId'));
        element.value += "\n";
        return;
      }

      if (this._pressedEnter(event)) {
        this.modifiedSubmit();
      }
    },
    // Methods
    _pressedModifier: function _pressedModifier(event) {
      return event.ctrlKey || event.metaKey;
    },
    _pressedEnter: function _pressedEnter(event) {
      return event.keyCode === 13; // Enter key
    }
  });

  _exports.default = _default;
});