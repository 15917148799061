define("bb-web/pods/cities/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Hooks
    model: function model(params) {
      return this.store.query('city', {
        status: 'active',
        order: 'city asc'
      });
    },
    afterModel: function afterModel(model, transition) {
      this._setHeadTags(model, transition);
    },
    setupController: function setupController(controller, model) {
      controller.set('cities', model);
    },
    // Methods
    _setHeadTags: function _setHeadTags(model, transition) {
      Ember.set(this, 'headData.pageTitle', "Cities - Beauty Broker");
      Ember.set(this, 'headData.title', "Browse Cities");
      Ember.set(this, 'headData.description', "Browse our network of providers by city to find and book beauty professionals in your area.");
    }
  });

  _exports.default = _default;
});