define("bb-web/pods/admin/settings/banking/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Hooks
    model: function model(params) {
      return Ember.RSVP.hash({
        provider: this.modelFor('admin')
      });
    }
  });

  _exports.default = _default;
});