define("bb-web/pods/components/customer/customer-duplicate-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BTKr5pXk",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"text\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/customer/customer-duplicate-btn/template.hbs"
    }
  });

  _exports.default = _default;
});