define("bb-web/pods/components/review/review-card/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'app-card',
    classNameBindings: ['css'],
    css: '',
    isPreview: false,
    isCard: false,
    isList: false,
    fillColor: '#B2499D',
    baseColor: '#CED9DE',
    // Services
    fastboot: Ember.inject.service(),
    // Models
    review: null,
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});