define("bb-web/blueprints/ember-string-helpers", ["exports", "ember-string-helpers/blueprints/ember-string-helpers"], function (_exports, _emberStringHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberStringHelpers.default;
    }
  });
});