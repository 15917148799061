define("bb-web/helpers/format-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (number) {
    var newNumber = ("" + number).replace(/\D/g, '');
    var m = newNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    return !m ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
  });

  _exports.default = _default;
});