define("bb-web/pods/browse/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model, transition) {
      this._setHeadTags(transition);
    },
    _setHeadTags: function _setHeadTags(model, transition) {
      Ember.set(this, 'headData.pageTitle', 'Browse - Beauty Broker');
      Ember.set(this, 'headData.title', 'Browse Beauty Broker');
      Ember.set(this, 'headData.description', "Find and Book Beauty Professionals in your area based on their availability and the services they offer.");
    }
  });

  _exports.default = _default;
});