define("bb-web/pods/user/conversations/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h7EeZkpM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"section-block\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"section-title flex-between\"],[8],[0,\"\\n\\t\\t\"],[7,\"h6\",true],[8],[0,\"Inbox\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"margin-left\"],[8],[0,\"\\n\"],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"section-body md\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"conversation/conversation-list\",null,[[\"order\",\"include\",\"model\",\"showPaginator\",\"showList\",\"dataLoading\",\"type\",\"description\"],[\"id desc\",\"provider,booking\",[24,[\"model\"]],true,true,true,\"index\",\"Looks like you haven't started any conversations yet.\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/user/conversations/index/template.hbs"
    }
  });

  _exports.default = _default;
});