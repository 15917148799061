define("bb-web/components/stripe-postal-code", ["exports", "ember-stripe-elements/components/stripe-postal-code"], function (_exports, _stripePostalCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _stripePostalCode.default;
    }
  });
});