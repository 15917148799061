define("bb-web/pods/components/photo-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UAhUCvx5",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"photo\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"img\",true],[11,\"src\",[22,\"photo\"]],[8],[9],[0,\"\\n\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-text-primary\"],[10,\"type\",\"button\"],[8],[0,\"Upload Photo\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[24,[\"isUser\"]],[24,[\"isProvider\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"img\",true],[10,\"src\",\"/placeholders/users.jpg\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-text-primary\"],[10,\"type\",\"button\"],[8],[0,\"Upload Photo\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\n\"],[4,\"if\",[[24,[\"isService\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"img\",true],[10,\"src\",\"/placeholders/services.jpg\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-text-primary\"],[10,\"type\",\"button\"],[8],[0,\"Upload Photo\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\n\"],[4,\"if\",[[24,[\"isPhoto\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"img\",true],[10,\"src\",\"/placeholders/services.jpg\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-text-primary\"],[10,\"type\",\"button\"],[8],[0,\"Upload Photo\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/photo-upload/template.hbs"
    }
  });

  _exports.default = _default;
});