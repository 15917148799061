define("bb-web/pods/components/setup/setup-flow-activate/component", ["exports", "bb-web/pods/components/provider/provider-form/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'div',
    classNames: '',
    // Models
    provider: null,
    setup: null,
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      window.mixpanel.track('Viewed setup flow activate', {
        provider: Ember.get(this, 'provider.id')
      });
    },
    // Actions
    actions: {}
  });

  _exports.default = _default;
});