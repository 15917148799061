define("bb-web/pods/components/provider/provider-share/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    classNames: '',
    // Models
    model: null,
    provider: Ember.computed.alias('model') // Computed
    // Hooks
    // Actions

  });

  _exports.default = _default;
});