define("bb-web/pods/components/setup/setup-flow-photos/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wJyHmXOQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flow-block-title\"],[8],[0,\"\\n\\t\"],[7,\"h4\",true],[10,\"class\",\"bold\"],[8],[0,\"Photos\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"flow-block-body\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"setup\",\"photoComplete\"]],[24,[\"notEditing\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"setup/setup-flow-empty\",null,[[\"type\",\"title\",\"msg\",\"editStep\",\"nextAction\"],[\"index\",\"Photo Complete!\",\"Please proceed to the next step.\",[28,\"action\",[[23,0,[]],\"editStep\"],null],[24,[\"nextAction\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"photo/photo-list\",null,[[\"type\",\"description\",\"model\",\"dataLoading\",\"showPaginator\",\"showCard\",\"showCreate\",\"isAdmin\"],[\"index\",\"Looks like you haven't added any photos yet.\",[24,[\"model\"]],true,true,true,true,true]]],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\\n\"],[7,\"div\",true],[10,\"class\",\"flow-block-footer flex-between\"],[8],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-text-gray\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"prevAction\"]]],null]]],[8],[0,\"\\n\\t\\t\"],[1,[28,\"fa-icon\",[\"chevron-left\"],[[\"class\"],[\"left small\"]]],false],[0,\"Back\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"disabled\",[22,\"isDisabled\"]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"nextAction\",[24,[\"provider\"]]]],[8],[0,\"\\n\\t\\t\"],[4,\"if\",[[24,[\"next\",\"isRunning\"]]],null,{\"statements\":[[1,[28,\"loading-indicator\",null,[[\"size\",\"color\"],[\"20\",\"white\"]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[]},{\"statements\":[[0,\"Next\"],[1,[28,\"fa-icon\",[\"chevron-right\"],[[\"class\"],[\"right small\"]]],false]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/setup/setup-flow-photos/template.hbs"
    }
  });

  _exports.default = _default;
});