define("bb-web/pods/components/note/note-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fJv2M7l3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-card-content\"],[8],[0,\"\\n\\t\"],[7,\"h6\",true],[10,\"class\",\"badge badge-light-gray\"],[8],[1,[28,\"moment-format\",[[24,[\"note\",\"createdAt\"]],\"MMM Do, YYYY\"],null],false],[9],[0,\"\\n\\t\"],[7,\"p\",true],[10,\"class\",\"\"],[8],[1,[24,[\"note\",\"message\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"flex-start\"],[8],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-text-gray sm margin-right\"],[3,\"action\",[[23,0,[]],\"openModal\",\"note/note-form\",[24,[\"model\"]],[24,[\"note\"]]]],[8],[0,\"Edit\"],[9],[0,\"\\n\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-text-gray sm margin-right\"],[11,\"onclick\",[28,\"perform\",[[24,[\"deleteNote\"]],[24,[\"note\"]]],null]],[8],[0,\"\\n\\t\\t\"],[4,\"if\",[[24,[\"deleteNote\",\"isRunning\"]]],null,{\"statements\":[[1,[28,\"loading-indicator\",null,[[\"size\",\"color\"],[\"18\",\"gray\"]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[]},{\"statements\":[[0,\"Delete\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/note/note-card/template.hbs"
    }
  });

  _exports.default = _default;
});