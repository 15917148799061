define("bb-web/pods/components/misc-item/misc-item-form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    classNames: 'flow-detail',
    type: 'index',
    // Services
    bookingService: Ember.inject.service('booking'),
    // Models
    booking: null,
    provider: null,
    miscItem: null,
    // Computed:
    isDisabled: Ember.computed('miscItem.{title,description,qty,amount}', function () {
      return Ember.isEmpty(Ember.get(this, 'miscItem.title')) || Ember.isEmpty(Ember.get(this, 'miscItem.description')) || Ember.isEmpty(Ember.get(this, 'miscItem.qty')) || Ember.isEmpty(Ember.get(this, 'miscItem.amount')) ? true : false;
    }),
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);

      Ember.isEmpty(Ember.get(this, 'miscItem')) ? this._createMiscItem() : null;
    },
    didInsertElement: function didInsertElement() {
      window.mixpanel.track('View booking flow miscItem', {
        provider: Ember.get(this, 'provider.id')
      });
    },
    willDestroyElement: function willDestroyElement() {
      if (!Ember.get(this, 'booking.miscItems').includes(Ember.get(this, 'miscItem'))) {
        Ember.get(this, 'miscItem').rollbackAttributes();
        Ember.set(this, 'miscItem', null);
      }
    },
    // Events
    submit: function submit() {
      this.bookingService._addMiscItem(Ember.get(this, 'miscItem'));

      this.nextAction();
      return false;
    },
    // Misc Item
    _createMiscItem: function _createMiscItem() {
      var miscItem = this.store.createRecord('misc-item');
      Ember.set(this, 'miscItem', miscItem);
    }
  });

  _exports.default = _default;
});