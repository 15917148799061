define("bb-web/pods/components/schedule/schedule-flow/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    classNames: 'form-block',
    showInterval: false,
    showEvent: false,
    // Models
    model: null,
    record: null,
    provider: Ember.computed.alias('model.provider'),
    schedule: Ember.computed.alias('model.schedule'),
    interval: Ember.computed.alias('model.interval'),
    startDate: Ember.computed.alias('model.startDate'),
    endDate: Ember.computed.alias('model.endDate'),
    isFlow: Ember.computed.alias('record'),
    // Actions
    actions: {
      showInterval: function showInterval() {
        this.send('openModal', 'interval/interval-form', Ember.get(this, 'model'), Ember.get(this, 'isFlow'));
      },
      showEvent: function showEvent() {
        this.send('openModal', 'event/event-form', Ember.get(this, 'model'), Ember.get(this, 'isFlow'));
      }
    }
  });

  _exports.default = _default;
});