define("bb-web/pods/admin/services/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "we/KeceX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\",true],[10,\"class\",\"admin-header flex-between\"],[8],[0,\"\\n\\t\"],[7,\"h4\",true],[10,\"class\",\"bold\"],[8],[0,\"Services\"],[9],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"openModal\",\"service/service-form\",[24,[\"model\"]]]],[8],[0,\"New Service\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"main\",true],[10,\"class\",\"admin-body lg\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-block\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-title flex-between\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"filters flex-start\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"filters/status-filter\",null,[[\"type\",\"currentStatus\",\"setFilter\"],[\"Service\",[24,[\"status\"]],[28,\"action\",[[23,0,[]],\"setFilter\"],null]]]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"search/search-form\",null,[[\"provider\",\"indices\",\"placeholder\",\"isAdmin\",\"css\"],[[24,[\"model\",\"provider\"]],\"service\",\"Search for a service...\",true,\"\"]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-body md no-pad\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"service/service-list\",null,[[\"type\",\"status\",\"model\",\"dataLoading\",\"showPaginator\",\"showTable\",\"isAdmin\"],[\"index\",[24,[\"status\"]],[24,[\"model\"]],true,true,true,true]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/admin/services/index/template.hbs"
    }
  });

  _exports.default = _default;
});