define("bb-web/pods/components/booking/booking-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CJVJlO/5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"section-title mobile\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isNew\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"h4\",true],[8],[7,\"b\",true],[8],[0,\"Create Booking\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"h4\",true],[8],[7,\"b\",true],[8],[0,\"Edit Booking\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"power-select-wrapper small margin-left\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"booking/booking-manager-actions\",null,[[\"booking\",\"renderInPlace\"],[[24,[\"booking\"]],true]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"section-block no-border no-margin\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"section-body md no-pad\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"booking\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"booking/booking-flow\",null,[[\"bookings\",\"booking\",\"provider\",\"isAdmin\",\"isEdit\"],[[24,[\"bookings\"]],[24,[\"booking\"]],[24,[\"provider\"]],true,[24,[\"isEdit\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/booking/booking-form/template.hbs"
    }
  });

  _exports.default = _default;
});