define("bb-web/pods/components/auth/google-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zp4ncu5t",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"user\",\"googleId\"]]],null,{\"statements\":[[0,\"\\t\"],[4,\"if\",[[24,[\"googleDisconnnect\",\"isRunning\"]]],null,{\"statements\":[[1,[28,\"loading-indicator\",null,[[\"size\",\"color\"],[\"20\",\"white\"]]],false],[0,\"\\n\\t\"]],\"parameters\":[]},{\"statements\":[[1,[28,\"fa-icon\",[\"check\"],[[\"class\"],[\"margin-right\"]]],false],[0,\"Connected\"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"text\"]]],null,{\"statements\":[[0,\"\\t\"],[4,\"if\",[[24,[\"googleAuth\",\"isRunning\"]]],null,{\"statements\":[[1,[28,\"loading-indicator\",null,[[\"size\",\"color\"],[\"20\",\"white\"]]],false],[0,\"\\n\\t\"]],\"parameters\":[]},{\"statements\":[[1,[28,\"fa-icon\",[\"google\"],[[\"class\",\"prefix\"],[\"margin-right\",\"fab\"]]],false],[1,[22,\"text\"],false]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[4,\"if\",[[24,[\"googleAuth\",\"isRunning\"]]],null,{\"statements\":[[1,[28,\"loading-indicator\",null,[[\"size\",\"color\"],[\"20\",\"white\"]]],false],[0,\"\\n\\t\"]],\"parameters\":[]},{\"statements\":[[14,1]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/auth/google-btn/template.hbs"
    }
  });

  _exports.default = _default;
});