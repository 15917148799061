define("bb-web/pods/admin/schedule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qCRJlst7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\",true],[10,\"class\",\"admin-header flex-between\"],[8],[0,\"\\n\\t\"],[7,\"h4\",true],[10,\"class\",\"bold\"],[8],[0,\"Schedule\"],[9],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"openModal\",\"booking/booking-form\",[24,[\"model\"]]]],[8],[0,\"New\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"main\",true],[10,\"class\",\"admin-body lg\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n\\t\\t\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-block\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-title flex-between\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"filters flex-start\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"filters/status-filter\",null,[[\"type\",\"currentStatus\",\"setFilter\"],[\"Booking\",[24,[\"status\"]],[28,\"action\",[[23,0,[]],\"setFilter\"],null]]]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"search/search-form\",null,[[\"provider\",\"indices\",\"placeholder\",\"isAdmin\",\"css\"],[[24,[\"model\",\"provider\"]],\"booking\",\"Search for a booking...\",true,\"\"]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\\t\"],[1,[28,\"booking/booking-calendar\",null,[[\"model\",\"status\",\"class\"],[[24,[\"model\"]],[24,[\"status\"]],\"lg\"]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/admin/schedule/template.hbs"
    }
  });

  _exports.default = _default;
});