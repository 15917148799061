define("bb-web/pods/admin/settings/integrations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fdd7xNsR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\",true],[10,\"class\",\"container-fluid pad-top pad-bottom flex-between border-bottom\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"flex-start\"],[8],[0,\"\\n\\t\\t\"],[7,\"h4\",true],[10,\"class\",\"pad-right\"],[8],[7,\"b\",true],[8],[0,\"Integrations\"],[9],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"main\",true],[10,\"class\",\"app-body lg\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"empty-message\",null,[[\"faIcon\",\"title\",\"type\",\"description\"],[\"puzzle-piece\",\"Integrations\",\"index\",\"Coming soon. Please check back later.\"]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/admin/settings/integrations/template.hbs"
    }
  });

  _exports.default = _default;
});