define("bb-web/pods/components/booking/booking-flow-progress/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'progress-bar-block progress',
    showSelect: false,
    showMiscItem: false,
    showScheduler: false,
    showReview: false,
    showConfirm: false,
    // Computed
    progress: Ember.computed('showSelect', 'showMiscItem', 'showScheduler', 'showReview', 'showCard', 'showCustomer', 'showLocation', 'showConfirm', function () {
      if (Ember.get(this, 'showSelect') || Ember.get(this, 'showMiscItem')) return "25";
      if (Ember.get(this, 'showScheduler')) return "50";
      if (Ember.get(this, 'showReview') || Ember.get(this, 'showCard') || Ember.get(this, 'showCustomer') || Ember.get(this, 'showLocation')) return "75";
      if (Ember.get(this, 'showConfirm')) return "100";
    }),
    progressStyle: Ember.computed('progress', function () {
      var progress = Ember.get(this, 'progress');
      return Ember.String.htmlSafe("width:".concat(progress, "%"));
    }) // Hooks
    // Methods

  });

  _exports.default = _default;
});