define("bb-web/pods/components/search/search-autocomplete/component", ["exports", "bb-web/mixins/keyboard-nav"], function (_exports, _keyboardNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_keyboardNav.default, {
    tagName: 'div',
    classNames: 'autocomplete',
    classNameBindings: ['isActive'],
    isActive: false,
    isAdmin: false,
    isMkt: false,
    isLoading: false,
    inputID: '',
    term: '',
    lastTerm: '',
    placeholder: '',
    autocomplete: '',
    index: '',
    indexes: '',
    query: '',
    results: '',
    combinedHits: '',
    highlightIndex: -1,
    // Observers
    termDidChange: Ember.observer('term', function () {
      if (Ember.get(this, 'term') != Ember.get(this, 'lastTerm')) {
        Ember.set(this, 'lastTerm', Ember.get(this, 'term'));
        Ember.set(this, 'highlightIndex', -1);
        Ember.get(this, 'term.length') > 2 ? this.updateQuery(Ember.get(this, 'term')) : Ember.set(this, 'combinedHits', null);
      }
    }),
    // Computed
    params: Ember.computed('term', function () {
      var params = Ember.Object.create();
      params.set('term', Ember.get(this, 'term'));
      return params;
    }),
    showLink: Ember.computed.not('isAdmin'),
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      Ember.get(this, 'isMkt') || Ember.get(this, 'isRoute') ? document.getElementById('search_form').focus() : null;
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
    },
    // Events
    onEnterPress: function onEnterPress() {
      var hit = Ember.get(this, 'combinedHits.length') > 0 ? Ember.get(this, 'combinedHits')[Ember.get(this, 'highlightIndex')] : null;

      if (hit) {
        var indexName = this._fetchIndexName(hit);

        if (!Ember.get(this, 'isAdmin') && indexName == 'providers') {
          this.selectResult(indexName, hit.username);
        } else if (!Ember.get(this, 'isAdmin') && indexName == 'services') {
          this.selectResult('providers', hit.provider_username);
        } else {
          this.selectResult(indexName, hit.id);
        }
      } else {
        this.router.transitionTo('search', {
          queryParams: Ember.get(this, 'params')
        });
      }
    },
    onEscPress: function onEscPress() {
      this.clearSearch();
    },
    onDownPress: function onDownPress() {
      if (Ember.get(this, 'highlightIndex') >= 0 && Ember.get(this, 'highlightIndex') <= Ember.get(this, 'combinedHits.length')) {
        Ember.set(this, 'highlightIndex', Ember.get(this, 'highlightIndex') + 1);
      } else {
        Ember.set(this, 'highlightIndex', 0);
      }
    },
    onUpPress: function onUpPress() {
      if (Ember.get(this, 'highlightIndex') >= 0) {
        Ember.set(this, 'highlightIndex', Ember.get(this, 'highlightIndex') - 1);
      } else {
        Ember.set(this, 'highlightIndex', -1);
      }
    },
    // Methods
    _fetchIndexName: function _fetchIndexName(hit) {
      var indexName = hit.index.split('_')[0];

      if (indexName == 'city') {
        return 'cities';
      }

      if (indexName == 'category') {
        return 'categories';
      }

      return indexName + 's';
    }
  });

  _exports.default = _default;
});