define("bb-web/pods/mkt/contact/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    latitude: '39.7486068',
    longitude: '-105.0559104',
    icon: '/icons/map-marker.svg',
    showMarker: true,
    // Computed
    options: Ember.computed('', function () {
      return {
        fullscreenControl: false,
        draggable: false,
        maxZoom: 18,
        zoomControl: true,
        backgroundColor: '#f4f5f7',
        mapTypeControl: false
      };
    }),
    // Actions
    actions: {
      toggleMarker: function toggleMarker() {
        this.toggleProperty('showMarker');
      }
    }
  });

  _exports.default = _default;
});