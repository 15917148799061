define("bb-web/pods/components/booking/booking-flow-progress/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QkYhpt/w",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"progress-bar\"],[10,\"role\",\"progressbar\"],[11,\"style\",[22,\"progressStyle\"]],[11,\"aria-valuenow\",[22,\"progress\"]],[10,\"aria-valuemin\",\"0\"],[10,\"aria-valuemax\",\"100\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/booking/booking-flow-progress/template.hbs"
    }
  });

  _exports.default = _default;
});