define("bb-web/pods/search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+m6Efg9P",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"mkt/alert-bar\"],false],[0,\"\\n\"],[1,[28,\"mkt-nav\",null,[[\"isRoute\",\"term\"],[true,[24,[\"term\"]]]]],false],[0,\"\\n\\n\"],[4,\"breadcrumb-trail\",null,null,{\"statements\":[[0,\"\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"mkt.index\"]],{\"statements\":[[0,\"Home\"]],\"parameters\":[]},null],[9],[0,\"\\n\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"search\"]],{\"statements\":[[0,\"Search\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"main\",true],[10,\"class\",\"app-body md\"],[8],[0,\"\\n\\t\"],[7,\"section\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-section-block\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-section-title\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"term\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"h2\",true],[8],[0,\"Search for \"],[7,\"span\",true],[10,\"class\",\"primary-color\"],[8],[0,\"\\\"\"],[1,[22,\"term\"],false],[0,\"\\\"\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"h2\",true],[8],[0,\"Search for \"],[7,\"span\",true],[10,\"class\",\"primary-color\"],[8],[0,\"something...\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-section-body\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"search/search-list\",null,[[\"term\"],[[24,[\"term\"]]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[15,\"footers/simple-footer\",[]]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bb-web/pods/search/template.hbs"
    }
  });

  _exports.default = _default;
});