define("bb-web/pods/components/receipt/receipt-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FQ+TRh5F",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"section-title flex-start\"],[8],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"btn btn-text-gray primary margin-right \",[28,\"if\",[[24,[\"showCharges\"]],\"active\"],null]]]],[3,\"action\",[[23,0,[]],\"showCharges\"]],[8],[0,\"Charges\"],[9],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"btn btn-text-gray primary margin-right \",[28,\"if\",[[24,[\"showRefunds\"]],\"active\"],null]]]],[3,\"action\",[[23,0,[]],\"showRefunds\"]],[8],[0,\"Refunds\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"section-body lg\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showCharges\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"charge/charge-list\",null,[[\"userID\",\"model\",\"dataLoading\",\"showList\",\"type\",\"description\"],[[24,[\"user\",\"id\"]],[24,[\"model\"]],true,true,\"index\",\"Looks like you haven't made any payments yet.\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"showRefunds\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"refund/refund-list\",null,[[\"userID\",\"model\",\"dataLoading\",\"showList\",\"type\",\"description\"],[[24,[\"user\",\"id\"]],[24,[\"model\"]],true,true,\"index\",\"Looks like you haven't received any refunds yet.\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/receipt/receipt-list/template.hbs"
    }
  });

  _exports.default = _default;
});