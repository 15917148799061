define("bb-web/pods/interval/model", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    schedule: belongsTo(),
    // Keys
    scheduleId: attr(),
    // Attrs
    daysOfWeek: attr(),
    startDate: attr(),
    endDate: attr(),
    // Computed
    formattedStartDate: Ember.computed('startDate', function () {
      return (0, _moment.default)(Ember.get(this, 'startDate')).format();
    }),
    formattedEndDate: Ember.computed('endDate', function () {
      return (0, _moment.default)(Ember.get(this, 'endDate')).format();
    }),
    // Computed
    occursSunday: Ember.computed('daysOfWeek.[]', function () {
      return Ember.get(this, 'daysOfWeek').includes(0);
    }),
    occursMonday: Ember.computed('daysOfWeek.[]', function () {
      return Ember.get(this, 'daysOfWeek').includes(1);
    }),
    occursTuesday: Ember.computed('daysOfWeek.[]', function () {
      return Ember.get(this, 'daysOfWeek.[]').includes(2);
    }),
    occursWednesday: Ember.computed('daysOfWeek.[]', function () {
      return Ember.get(this, 'daysOfWeek').includes(3);
    }),
    occursThursday: Ember.computed('daysOfWeek.[]', function () {
      return Ember.get(this, 'daysOfWeek').includes(4);
    }),
    occursFriday: Ember.computed('daysOfWeek.[]', function () {
      return Ember.get(this, 'daysOfWeek').includes(5);
    }),
    occursSaturday: Ember.computed('daysOfWeek.[]', function () {
      return Ember.get(this, 'daysOfWeek').includes(6);
    })
  });

  _exports.default = _default;
});