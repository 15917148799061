define("bb-web/pods/components/simple-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RDu9t00+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex-start\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"navbar-brand\"],[8],[0,\"\\n\\t\\t\"],[7,\"a\",false],[3,\"action\",[[23,0,[]],\"linkToHomepage\"]],[8],[7,\"img\",true],[10,\"src\",\"/branding/bb-icon.svg\"],[8],[9],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"flex-end\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"li\",true],[10,\"class\",\"nav-item\"],[8],[15,\"nav/user-dropdown\",[]],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[1,[22,\"misc/hamburger-btn\"],false],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bb-web/pods/components/simple-nav/template.hbs"
    }
  });

  _exports.default = _default;
});