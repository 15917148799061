define("bb-web/mixins/query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: {
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      category_slug: {
        refreshModel: true
      },
      refresh: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});