define("bb-web/pods/components/photo/photo-swiper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q5WBak3K",
    "block": "{\"symbols\":[\"photo\"],\"statements\":[[4,\"if\",[[24,[\"isLoaded\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"photos-swiper-header flex-between\"],[8],[0,\"\\n\\t\\t\"],[7,\"a\",false],[12,\"class\",\"photos-swiper-close\"],[3,\"action\",[[23,0,[]],\"closeSwiper\"]],[8],[1,[28,\"fa-icon\",[\"times\"],null],false],[9],[0,\"\\n\\n\\t\\t\"],[7,\"h6\",true],[10,\"class\",\"no-margin white\"],[8],[1,[22,\"slideIndex\"],false],[0,\" / \"],[1,[24,[\"photos\",\"length\"]],false],[0,\" Photos\"],[9],[0,\"\\n\\t\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"photos-swiper-btns flex-end\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"btn icon-btn-white lg photos-swiper-btn-prev\"],[3,\"action\",[[23,0,[]],\"prevSlide\"]],[8],[1,[28,\"fa-icon\",[\"chevron-left\"],null],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"btn icon-btn-white lg photos-swiper-btn-next\"],[3,\"action\",[[23,0,[]],\"nextSlide\"]],[8],[1,[28,\"fa-icon\",[\"chevron-right\"],null],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"photos-swiper-block flex-center\"],[8],[0,\"\\n\"],[4,\"swiper-container\",null,[[\"tagName\",\"options\"],[\"section\",[24,[\"swipeOptions\"]]]],{\"statements\":[[0,\"\\t\\t\\t\\n\"],[4,\"each\",[[24,[\"photos\"]]],null,{\"statements\":[[4,\"swiper-slide\",null,[[\"class\"],[\"col-12\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[1,[28,\"photo/photo-card\",null,[[\"photo\",\"showPreview\",\"css\"],[[23,1,[]],true,\"no-border no-pad\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/photo/photo-swiper/template.hbs"
    }
  });

  _exports.default = _default;
});