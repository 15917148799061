define("bb-web/pods/components/message/message-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NiuAYFf/",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"fetchData\",\"isRunning\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"loading-indicator\",null,[[\"size\",\"classNames\"],[\"40\",\"loading-section\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"conversation\"]],[24,[\"messages\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\\n\"],[4,\"each\",[[24,[\"messages\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"message/message-card\",null,[[\"message\",\"model\",\"isAdmin\"],[[23,1,[]],[24,[\"model\"]],[24,[\"isAdmin\"]]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\\t\\t\"],[1,[28,\"empty-message\",null,[[\"faIcon\",\"title\",\"type\",\"description\"],[\"comments-alt\",[24,[\"title\"]],[24,[\"type\"]],[24,[\"description\"]]]]],false],[0,\"\\n\\n\\t\"]],\"parameters\":[]}]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/message/message-list/template.hbs"
    }
  });

  _exports.default = _default;
});