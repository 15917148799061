define("bb-web/pods/components/booking/booking-manager-actions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    classNames: '',
    isDetail: false,
    openManager: false,
    size: '',
    // Computed
    css: Ember.computed('booking.status', 'size', function () {
      return "badge ".concat(Ember.get(this, 'size'), " ").concat(Ember.get(this, 'booking.statusClass'));
    }),
    // Models
    booking: null,
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    },
    // Actions
    actions: {
      openBookingManager: function openBookingManager(booking, status) {
        booking.set('status', status);

        if (Ember.get(this, 'openManager')) {
          this.send('openModal', 'booking/booking-manager', booking);
        }
      }
    }
  });

  _exports.default = _default;
});