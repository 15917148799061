define("bb-web/pods/admin/settings/banking/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GPGlL/R7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"main\",true],[10,\"class\",\"admin-body xl\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n\\t\\t\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-block\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"admin-header flex-between\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h4\",true],[10,\"class\",\"bold\"],[8],[0,\"Banking\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[3,\"action\",[[23,0,[]],\"openModal\",\"bank-account/bank-account-form\",[24,[\"model\"]]]],[8],[1,[28,\"fa-icon\",[\"plus\"],[[\"class\"],[\"left small\"]]],false],[0,\"Bank Account\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-body md\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"bank-account/bank-account-list\",null,[[\"model\",\"dataLoading\",\"showCard\"],[[24,[\"model\"]],true,true]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/admin/settings/banking/template.hbs"
    }
  });

  _exports.default = _default;
});