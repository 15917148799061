define("bb-web/pods/customer/serializer", ["exports", "bb-web/pods/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    attrs: {
      location: {
        serialize: 'records'
      },
      totalBookings: {
        serialize: false
      },
      totalRevenue: {
        serialize: false
      }
    },
    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);

      if (!Ember.isEmpty(json.data.location)) {
        json.data.attributes.location_attributes = json.data.location;
      }

      delete json.data.location;
      return json;
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key) {
      if (snapshot.attr(key) != null && snapshot.attr(key) != "") {
        this._super.apply(this, arguments);
      }

      if (key == 'phone' && !Ember.isEmpty(json.attributes[key])) {
        json.attributes[key] = '1' + snapshot.attr(key);
      }
    },
    normalize: function normalize(typeClass, hash) {
      var fields = Ember.get(typeClass, 'fields');
      fields.forEach(function (type, field) {
        if (field == 'phone' && !Ember.isEmpty(hash.attributes[field])) {
          hash.attributes[field] = hash.attributes[field].slice(1);
        }
      });
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});