define("bb-web/pods/components/note/note-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fl3MBBkO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-title\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"note\",\"isNew\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"h3\",true],[8],[0,\"Add Note\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"h3\",true],[8],[0,\"Edit Note\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"form-body\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[8],[0,\"Message\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"textarea\",null,[[\"rows\",\"value\",\"class\",\"placeholder\"],[5,[24,[\"note\",\"message\"]],\"form-control\",\"What would you like to share with your future self?\"]]],false],[0,\"\\t\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"form-footer\"],[8],[0,\"\\n\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-primary btn-xl btn-block\"],[10,\"type\",\"submit\"],[8],[0,\"\\n\\t\\t\"],[4,\"if\",[[24,[\"saveNote\",\"isRunning\"]]],null,{\"statements\":[[1,[28,\"loading-indicator\",null,[[\"color\",\"size\"],[\"white\",24]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[]},{\"statements\":[[0,\"Post\"],[1,[28,\"fa-icon\",[\"check\"],[[\"class\"],[\"right\"]]],false]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/note/note-form/template.hbs"
    }
  });

  _exports.default = _default;
});