define("bb-web/pods/components/loading-overlay/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'loading-overlay',
    classNameBindings: ['overlay', 'solid', 'background'],
    solid: false,
    overlay: false,
    background: null,
    title: null,
    message: null,
    // Models
    model: null
  });

  _exports.default = _default;
});