define("bb-web/pods/components/provider/provider-flow-progress/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'progress-bar-block progress',
    showLogin: false,
    showRegister: false,
    showProvider: false,
    showLocation: false,
    showPromo: false,
    // Computed
    progress: Ember.computed('showLogin', 'showRegister', 'showProvider', 'showLocation', 'showPromo', function () {
      if (Ember.get(this, 'showLogin') || Ember.get(this, 'showRegister')) {
        return "25";
      }

      if (Ember.get(this, 'showProvider')) {
        return "50";
      }

      if (Ember.get(this, 'showLocation')) {
        return "75";
      }

      if (Ember.get(this, 'showPromo')) {
        return "100";
      }
    }),
    progressStyle: Ember.computed('progress', function () {
      var progress = Ember.get(this, 'progress');
      return Ember.String.htmlSafe("width:".concat(progress, "%"));
    }) // Hooks
    // Methods

  });

  _exports.default = _default;
});