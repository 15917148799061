define("bb-web/pods/components/app/app-login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8DEqkQiE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\\t\"],[7,\"label\",true],[8],[0,\"Password\"],[9],[0,\"\\n\\t\"],[5,\"input\",[[12,\"class\",\"form-control\"],[12,\"placeholder\",\"••••••••\"],[12,\"type\",\"password\"]],[[\"@value\"],[[22,\"password\"]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"button\",true],[10,\"class\",\"btn btn-primary\"],[10,\"type\",\"submit\"],[8],[0,\"\\n\\t\"],[4,\"if\",[[24,[\"stagingLogin\",\"isRunning\"]]],null,{\"statements\":[[1,[28,\"loading-indicator\",null,[[\"color\",\"size\"],[\"white\",24]]],false],[0,\"\\n\\t\"]],\"parameters\":[]},{\"statements\":[[0,\"Continue\"],[1,[28,\"fa-icon\",[\"chevron-right\"],[[\"class\"],[\"right\"]]],false]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/app/app-login/template.hbs"
    }
  });

  _exports.default = _default;
});