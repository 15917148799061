define("bb-web/pods/conversation/model", ["exports", "ember-data", "bb-web/mixins/statuses"], function (_exports, _emberData, _statuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend(_statuses.default, {
    booking: belongsTo(),
    customer: belongsTo(),
    user: belongsTo(),
    provider: belongsTo(),
    messages: hasMany(),
    // Keys
    bookingId: attr(),
    customerId: attr(),
    providerId: attr(),
    userId: attr(),
    // Attrs
    status: attr(),
    // Draft, Active, Closed
    subject: attr(),
    updatedAt: attr(),
    createdAt: attr(),
    // Computed
    statusOptions: Ember.computed(function () {
      return ['draft', 'active', 'closed'];
    }),
    isActive: Ember.computed.equal('status', 'active'),
    isClosed: Ember.computed.equal('status', 'closed'),
    bookingURL: Ember.computed('provider.id', 'booking.id', function () {
      return "/admin/".concat(Ember.get(this, 'provider.id'), "/bookings/").concat(Ember.get(this, 'booking.id'));
    }),
    displayUser: Ember.computed('customer.id', 'user.id', function () {
      return Ember.get(this, 'user.id') ? Ember.get(this, 'user') : Ember.get(this, 'customer');
    })
  });

  _exports.default = _default;
});