define("bb-web/pods/note/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    provider: belongsTo(),
    // Keys
    notableId: attr(),
    notableType: attr(),
    providerId: attr(),
    // Attrs
    message: attr(),
    createdAt: attr()
  });

  _exports.default = _default;
});