define("bb-web/pods/components/admin/admin-nav/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'nav',
    classNames: 'navbar admin navbar-expand-lg white-bg flex-between sticky-top border-bottom',
    // Properties
    whiteBg: false,
    borderBottom: false,
    // Actions
    actions: {
      invalidateSession: function invalidateSession() {
        this.currentUser._invalidateSession();
      },
      linkToHomepage: function linkToHomepage() {
        if (this.router.currentRoute.name != 'mkt.index') {
          this.router.transitionTo('mkt.index');
        } else {
          window.location.reload();
        }
      }
    }
  });

  _exports.default = _default;
});