define("bb-web/pods/categories/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kpWi2pse",
    "block": "{\"symbols\":[],\"statements\":[[4,\"breadcrumb-trail\",null,null,{\"statements\":[[0,\"\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"mkt.index\"]],{\"statements\":[[0,\"Home\"]],\"parameters\":[]},null],[9],[0,\"\\n\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"categories.index\"]],{\"statements\":[[0,\"Categories\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"main\",true],[10,\"class\",\"mkt-body pad-top pad-bottom\"],[8],[0,\"\\n\\t\\n\\t\"],[7,\"section\",true],[10,\"class\",\"container \"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-section-block no-border\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-section-title\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h2\",true],[8],[0,\"Categories\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h6\",true],[8],[0,\"Find providers based on the service you're looking for.\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-section-body\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"category/category-list\",null,[[\"status\",\"categories\",\"dataLoading\",\"showPaginator\",\"showCard\"],[\"active\",[24,[\"categories\"]],true,true,true]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[15,\"footers/main-footer\",[]]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bb-web/pods/categories/index/template.hbs"
    }
  });

  _exports.default = _default;
});