define("bb-web/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zBtojF/v",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"head-layout\"],false],[0,\"\\n\"],[1,[22,\"side-nav\"],false],[0,\"\\n\\n\"],[1,[22,\"app/app-verification\"],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showModal\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"onClickOverlay\",\"translucentOverlay\",\"targetAttachment\",\"containerClass\",\"overlayClass\",\"wrapperClass\"],[\"\",true,\"none\",\"centered-scrolling-container\",\"centered-scrolling-overlay\",\"centered-scrolling-wrapper\"]],{\"statements\":[[0,\"\\t\\t\"],[7,\"a\",false],[12,\"class\",\"modal-close\"],[12,\"data-dismiss\",\"modal\"],[3,\"action\",[[23,0,[]],\"closeModal\"]],[8],[1,[28,\"fa-icon\",[\"times\"],null],false],[9],[0,\"\\n\\t\\t\"],[1,[28,\"component\",[[24,[\"componentPath\"]]],[[\"model\",\"record\"],[[24,[\"model\"]],[24,[\"record\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[1,[22,\"intercom-io\"],false],[0,\"\\n\"],[1,[22,\"flash-messages\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});