define("bb-web/pods/components/category/category-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VYmFQhcz",
    "block": "{\"symbols\":[\"category\"],\"statements\":[[4,\"each\",[[24,[\"categories\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"section\",true],[10,\"class\",\"container \"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-section-block\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-section-title\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h2\",true],[8],[1,[28,\"capitalize\",[[23,1,[\"name\"]]],null],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h6\",true],[8],[1,[28,\"capitalize\",[[23,1,[\"description\"]]],null],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-section-body\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"provider/provider-list\",null,[[\"featured\",\"order\",\"pageSize\",\"categoryID\",\"cityID\",\"dataLoading\",\"showCard\"],[true,\"created_at desc\",4,[23,1,[\"id\"]],[24,[\"city\",\"id\"]],true,true]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-section-footer\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"btn btn-text-primary\",\"categories.detail\",[23,1,[\"slug\"]]]],{\"statements\":[[0,\"Show more\"],[1,[28,\"fa-icon\",[\"chevron-right\"],[[\"class\"],[\"right small\"]]],false]],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/category/category-preview/template.hbs"
    }
  });

  _exports.default = _default;
});