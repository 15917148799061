define("bb-web/pods/components/table-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Attributes
    tagName: 'th',
    classNameBindings: ['isSortField:active'],
    // Properties
    field: '',
    text: '',
    action: 'sortTable',
    value: null,
    direction: null,
    // Computed
    isSortField: Ember.computed('field', 'value', function () {
      var field = Ember.get(this, 'field');
      var value = Ember.get(this, 'value');
      return value == field ? true : false;
    }),
    // Hooks
    click: function click() {
      var field = Ember.get(this, 'field');
      var direction = Ember.get(this, 'direction');
      this.sortTable(field, direction);
    }
  });

  _exports.default = _default;
});