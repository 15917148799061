define("bb-web/pods/components/booking/booking-form/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: '',
    // Services
    bookingService: Ember.inject.service('booking'),
    // Model
    model: null,
    bookings: Ember.computed.alias('model.bookings'),
    customer: Ember.computed.alias('model.customer'),
    provider: Ember.computed.alias('model.provider'),
    status: Ember.computed.alias('model.status'),
    startDate: Ember.computed.alias('model.startDate'),
    policy: null,
    booking: Ember.computed('model.id', function () {
      return Ember.get(this, 'model.id') ? Ember.get(this, 'model') : null;
    }),
    // Computed
    isNew: false,
    isEdit: Ember.computed.not('isNew'),
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);

      this.bookingService.set('customer', Ember.get(this, 'customer'));
      this.bookingService.set('isAdmin', true);
      Ember.isEmpty(Ember.get(this, 'booking')) ? this._createBooking(Ember.get(this, 'startDate')) : null;
    },
    didInsertElement: function didInsertElement() {
      document.getElementsByClassName('ember-modal-dialog')[0].style = 'width:90%';
    },
    // Methods
    _createBooking: function _createBooking(startDate) {
      var booking = this.store.createRecord('booking', {
        providerId: Ember.get(this, 'provider.id'),
        miscItems: [],
        startDate: (0, _moment.default)(startDate).format(),
        status: Ember.get(this, 'status') ? Ember.get(this, 'status') : 'pending',
        discount: 0,
        duration: 0
      });
      Ember.set(this, 'isNew', true);
      Ember.set(this, 'booking', booking);
    }
  });

  _exports.default = _default;
});