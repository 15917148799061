define("bb-web/pods/components/loading-overlay/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "49kOx1P1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"overlay\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"loading-block\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"loading-indicator\",null,[[\"size\",\"stroke\",\"color\"],[60,2,\"white\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"loading-block\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"margin-bottom\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"img\",true],[10,\"class\",\"circle\"],[11,\"src\",[24,[\"model\",\"photo\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"img\",true],[11,\"src\",[22,\"imgPath\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\\t\"],[7,\"h4\",true],[8],[7,\"b\",true],[8],[1,[22,\"title\"],false],[9],[9],[0,\"\\n\\t\\t\\t\"],[7,\"h6\",true],[8],[7,\"i\",true],[8],[1,[22,\"message\"],false],[9],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"loading-indicator\",null,[[\"size\",\"stroke\",\"color\"],[30,2,\"blue\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/loading-overlay/template.hbs"
    }
  });

  _exports.default = _default;
});