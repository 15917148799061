define("bb-web/pods/admin/payouts/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        provider: this.modelFor('admin'),
        payout: this.store.findRecord('payout', params.payout_id, params)
      });
    }
  });

  _exports.default = _default;
});