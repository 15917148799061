define("bb-web/pods/components/simple-nav/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'nav',
    classNames: 'navbar simple navbar-expand-lg white-bg flex-between sticky-top',
    classNameBindings: ['css'],
    css: 'border-bottom',
    // Actions
    actions: {
      linkToHomepage: function linkToHomepage() {
        if (this.router.currentRoute.name != 'mkt.index') {
          this.router.transitionTo('mkt.index');
        } else {
          window.location.reload();
        }
      },
      invalidateSession: function invalidateSession() {
        this.currentUser._invalidateSession();
      }
    }
  });

  _exports.default = _default;
});