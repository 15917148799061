define("bb-web/pods/components/receipt/receipt-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'section-block',
    showCharges: false,
    showRefunds: false,
    // Models
    model: null,
    user: Ember.computed.alias('model.user'),
    // Actions
    actions: {
      showCharges: function showCharges() {
        Ember.set(this, 'showCharges', true);
        Ember.set(this, 'showRefunds', false);
      },
      showRefunds: function showRefunds() {
        Ember.set(this, 'showCharges', false);
        Ember.set(this, 'showRefunds', true);
      }
    }
  });

  _exports.default = _default;
});