define("bb-web/pods/mkt/welcome/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h/mVzqsy",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"mkt/alert-bar\"],false],[0,\"\\n\"],[1,[28,\"simple-nav\",null,[[\"isMkt\"],[true]]],false],[0,\"\\n\\n\"],[7,\"main\",true],[10,\"class\",\"flex app-body sm\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container-small\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"flex-column text-left\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h2\",true],[8],[0,\"Welcome to Beauty Broker\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"h6\",true],[8],[0,\"This is our staging environment which is designed for development purposes. If you're looking for our main site please visit \"],[5,\"link-external\",[[12,\"href\",\"https://www.beautybroker.io\"]],[[],[]],{\"statements\":[[0,\"www.beautybroker.io\"]],\"parameters\":[]}],[0,\". Otherwise, please login to continue.\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"margin-top margin-bottom\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"app/app-login\",[],[[],[]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[15,\"footers/simple-footer\",[]]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bb-web/pods/mkt/welcome/template.hbs"
    }
  });

  _exports.default = _default;
});