define("bb-web/pods/service/model", ["exports", "ember-data", "bb-web/mixins/statuses", "bb-web/utils/compress-photos"], function (_exports, _emberData, _statuses, _compressPhotos) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend(_statuses.default, {
    provider: belongsTo(),
    category: belongsTo(),
    bookings: hasMany(),
    providerId: attr(),
    categoryId: attr(),
    name: attr(),
    status: attr(),
    description: attr(),
    photo: attr(),
    price: attr('number'),
    duration: attr('number'),
    position: attr('number'),
    createdAt: attr(),
    // Computed
    amount: Ember.computed.alias('price'),
    statusOptions: Ember.computed('status', function () {
      return ['draft', 'active', 'inactive'];
    }),
    displayDurations: Ember.computed(function () {
      var index = 0;
      var durations = [];

      while (index < 180) {
        durations.pushObject(index += 5);
      }

      return durations;
    }),
    // Photos
    photoMin: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'), '1080', '540');
    }),
    photoFB: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'), '1080', '540');
    }),
    photoTwitter: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'), '600', '600', 'crop');
    })
  });

  _exports.default = _default;
});