define("bb-web/pods/categories/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Hooks
    model: function model(params) {
      return this.store.query('category', {
        status: 'active'
      });
    },
    afterModel: function afterModel(model, transition) {
      this._setHeadTags(model, transition);
    },
    setupController: function setupController(controller, model) {
      controller.set('categories', model);
    },
    // Methods
    _setHeadTags: function _setHeadTags(model, transition) {
      Ember.set(this, 'headData.pageTitle', "Categories - Beauty Broker");
      Ember.set(this, 'headData.title', "Browse Categories");
      Ember.set(this, 'headData.description', "Browse our network of providers by category to find and book the right beauty professional.");
    }
  });

  _exports.default = _default;
});