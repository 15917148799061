define("bb-web/mixins/stripe-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    options: Ember.computed(function () {
      return {
        iconStyle: 'solid',
        style: {
          base: {
            iconColor: '#9EA7B3',
            color: '#404E5C',
            '::placeholder': {
              color: '#9ea7b3'
            },
            fontWeight: 600,
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontSmoothing: 'antialiased'
          },
          invalid: {
            iconColor: '#FB4E5D',
            color: '#FB4E5D'
          }
        }
      };
    })
  });

  _exports.default = _default;
});