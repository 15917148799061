define("bb-web/pods/components/video-player/component", ["exports", "bb-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'video-player-block',
    youTubeID: '',
    width: '',
    height: '',
    // Computed
    playerVars: Ember.computed('height', 'width', function () {
      return {
        height: Ember.get(this, 'height'),
        width: Ember.get(this, 'width'),
        origin: _environment.default.APP.defaultWebHost
      };
    }),
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});