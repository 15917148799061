define("bb-web/pods/components/photo/photo-swiper/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'photos-swiper-bg',
    swipeOptions: null,
    showPhotoSwiper: false,
    slideIndex: 0,
    // Models
    swiper: null,
    photos: null,
    photo: null,
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);

      this._setSwipeOptions();

      Ember.set(this, 'isLoaded', true);
    },
    didInsertElement: function didInsertElement() {
      this._updateSwiper();
    },
    // Actions
    actions: {
      closeSwiper: function closeSwiper() {
        Ember.set(this, 'showPhotoSwiper', false);
      },
      prevSlide: function prevSlide() {
        Ember.get(this, 'swiper').slidePrev();

        this._updateSwiper();
      },
      nextSlide: function nextSlide() {
        Ember.get(this, 'swiper').slideNext();

        this._updateSwiper();
      }
    },
    // Methods
    _setSwipeOptions: function _setSwipeOptions() {
      Ember.set(this, 'swipeOptions', {
        initialSlide: Ember.get(this, 'photo.position'),
        loop: true,
        slidesPerView: 1,
        parallax: true
      });
    },
    _updateSwiper: function _updateSwiper() {
      var swiper = document.querySelector('.swiper-container').swiper;
      Ember.set(this, 'swiper', swiper);

      if (!swiper.activeIndex > Ember.get(this, 'photos.length')) {
        Ember.set(this, 'slideIndex', Ember.isEmpty(swiper.activeIndex) ? 1 : swiper.activeIndex);
      } else {
        Ember.set(this, 'slideIndex', 1);
      }
    }
  });

  _exports.default = _default;
});