define("bb-web/pods/user/cards/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ehzbLpT2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"main\",true],[10,\"class\",\"app-body sm\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"app-block\"],[8],[0,\"\\n\\t\\t\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\t\\t\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-3\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"user/user-card\",null,[[\"user\",\"showCard\",\"css\"],[[24,[\"model\",\"user\"]],true,\"no-border\"]]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-9\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-block\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-title flex-between\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[7,\"h6\",true],[8],[0,\"Cards\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"openModal\",\"card/card-form\",[24,[\"model\"]]]],[8],[0,\"Add Card\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-body lg\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[1,[28,\"card/card-list\",null,[[\"model\",\"dataLoading\",\"showList\"],[[24,[\"model\"]],true,true]]],false],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/user/cards/template.hbs"
    }
  });

  _exports.default = _default;
});