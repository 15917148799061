define("bb-web/pods/components/side-nav/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Services
    sideMenu: Ember.inject.service(),
    // Computed
    isActive: Ember.computed.alias('sideMenu.isOpen'),
    isAdmin: Ember.computed.alias('sideMenu.isAdmin'),
    // Models
    provider: Ember.computed.alias('sideMenu.provider'),
    // Actions
    actions: {
      invalidateSession: function invalidateSession() {
        Ember.get(this, 'session').invalidate();
      }
    }
  });

  _exports.default = _default;
});