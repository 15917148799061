define("bb-web/pods/user/charges/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g/Ni/qLO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"main\",true],[10,\"class\",\"app-body sm\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"app-block\"],[8],[0,\"\\n\\t\\t\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"container pad-bottom\"],[8],[0,\"\\n\\t\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-text-primary inline\",\"user.receipts\"]],{\"statements\":[[1,[28,\"fa-icon\",[\"chevron-left\"],[[\"class\"],[\"margin-right\"]]],false],[0,\"Back to Receipts\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\t\\t\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-3\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"user/user-card\",null,[[\"user\",\"showCard\",\"css\"],[[24,[\"model\",\"user\"]],true,\"no-border\"]]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-9\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-block\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-title\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[7,\"h6\",true],[8],[0,\"Charges #\"],[1,[24,[\"model\",\"charge\",\"id\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-body lg\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/user/charges/template.hbs"
    }
  });

  _exports.default = _default;
});