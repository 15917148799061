define("bb-web/authenticators/google-oauth2", ["exports", "ember-simple-auth/authenticators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // Services
    fetch: Ember.inject.service('fetch'),
    // Methods
    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate: function authenticate(data) {
      var _this = this;

      var url = '/auth/google-connect';
      var formattedData = this.fetch.formatData(data);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.fetch.post(url, formattedData).then(function (response) {
          return response.json();
        }).then(function (json) {
          if (json.data) {
            resolve({
              user_id: json.data.id,
              token: json.data.attributes.token
            });
          }

          throw json;
        }).catch(function (e) {
          return reject(e);
        });
      });
    }
  });

  _exports.default = _default;
});