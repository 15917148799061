define("bb-web/pods/components/search/search-autocomplete-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v5qY/NKu",
    "block": "{\"symbols\":[\"hit\"],\"statements\":[[4,\"if\",[[24,[\"result\",\"hits\"]]],null,{\"statements\":[[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"autocomplete-section-title\"],[8],[0,\"\\n\\t\\t\"],[7,\"h6\",true],[8],[7,\"b\",true],[8],[1,[28,\"capitalize\",[[24,[\"indexName\"]]],null],false],[9],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"autocomplete-section-body\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"result\",\"hits\"]]],null,{\"statements\":[[0,\"\\t    \"],[1,[28,\"search/search-autocomplete-row\",null,[[\"query\",\"indexName\",\"hit\",\"combinedHits\",\"highlightIndex\",\"selectResult\",\"isAdmin\"],[[24,[\"query\"]],[24,[\"indexName\"]],[23,1,[]],[24,[\"combinedHits\"]],[24,[\"highlightIndex\"]],[24,[\"selectResult\"]],[24,[\"isAdmin\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/search/search-autocomplete-section/template.hbs"
    }
  });

  _exports.default = _default;
});