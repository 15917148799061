define("bb-web/pods/user/notifications/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tjG1c4o4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"main\",true],[10,\"class\",\"app-body pad-top pad-bottom sm\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\t\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-3\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"user/user-card\",null,[[\"user\",\"showCard\",\"css\"],[[24,[\"model\",\"user\"]],true,\"no-border\"]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-9\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-block\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-title\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"h6\",true],[8],[0,\"Notifications\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-body lg\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[28,\"notification/notification-list\",null,[[\"user\",\"dataLoading\",\"showList\",\"type\",\"description\"],[[24,[\"user\"]],true,true,\"index\",\"Looks like you don't have any notifications yet.\"]]],false],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/user/notifications/template.hbs"
    }
  });

  _exports.default = _default;
});