define("bb-web/pods/components/receipt/receipt-card/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'app-card',
    classNameBindings: ['isPreview', 'isCard', 'isList', 'css'],
    css: '',
    isPreview: false,
    isCard: false,
    isList: false,
    // Models
    charge: null
  });

  _exports.default = _default;
});