define("bb-web/pods/components/misc/city-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MczATbBB",
    "block": "{\"symbols\":[\"state\"],\"statements\":[[4,\"power-select\",null,[[\"options\",\"selected\",\"dropdownClass\",\"searchEnabled\",\"placeholder\",\"onChange\"],[[24,[\"states\"]],[24,[\"state\"]],\"scale-fade\",false,[24,[\"placeholder\"]],[28,\"action\",[[23,0,[]],\"setState\"],null]]],{\"statements\":[[0,\"\\t\"],[1,[28,\"capitalize\",[[23,1,[\"name\"]]],null],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/misc/city-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});