define("bb-web/pods/components/browse/browse-header/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'header',
    classNames: 'header-block xs white-bg border-bottom',
    panelOpen: false,
    // Services
    fastboot: Ember.inject.service(),
    panelActions: Ember.inject.service(),
    // Hooks
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!Ember.get(this, 'fastboot.isFastBoot')) {
        this._updatePanel();
      }
    },
    // Methods
    _updatePanel: function _updatePanel() {
      console.log('Document Width: ', document.documentElement.clientWidth);

      if (document.documentElement.clientWidth >= 992) {
        Ember.get(this, 'panelActions').openAll();
      } else {
        Ember.get(this, 'panelActions').closeAll();
      }
    }
  });

  _exports.default = _default;
});