define("bb-web/pods/components/mkt/mkt-hero-swiper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sJLSTJnM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isLoaded\"]]],null,{\"statements\":[[4,\"swiper-container\",null,[[\"options\"],[[24,[\"swipeOptions\"]]]],{\"statements\":[[0,\"\\t\\t\\n\"],[4,\"swiper-slide\",null,null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"img\",true],[10,\"class\",\"mkt-hero-img lazyload\"],[10,\"data-src\",\"/heros/hair.jpg\"],[8],[9],[0,\"\\t\\t\\t\\t\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"swiper-slide\",null,null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"img\",true],[10,\"class\",\"mkt-hero-img lazyload\"],[10,\"data-src\",\"/heros/makeup.jpg\"],[8],[9],[0,\"\\t\\t\\t\\t\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"swiper-slide\",null,null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"img\",true],[10,\"class\",\"mkt-hero-img lazyload\"],[10,\"data-src\",\"/heros/nail.jpg\"],[8],[9],[0,\"\\t\\t\\t\\t\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"mkt-hero-swiper-pagination\"],[8],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/mkt/mkt-hero-swiper/template.hbs"
    }
  });

  _exports.default = _default;
});