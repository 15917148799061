define("bb-web/pods/user/adapter", ["exports", "bb-web/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    userID: null,
    username: null,
    // Methods
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super.apply(this, arguments);

      var userID = Ember.get(this, 'userID');

      if (!Ember.isEmpty(userID)) {
        return url.replace('v1/', "v1/users/".concat(userID, "/"));
      }

      return url;
    },
    // Standard Calls
    urlForCreateRecord: function urlForCreateRecord() {
      var url = this._super.apply(this, arguments);

      return this._buildURL() + '/register';
    },
    urlForQueryRecord: function urlForQueryRecord() {
      var username = Ember.get(this, 'username');
      return this._buildURL() + "/username/".concat(username);
    }
  });

  _exports.default = _default;
});