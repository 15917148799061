define("bb-web/pods/admin/customers/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cXXgvZ3j",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\",true],[10,\"class\",\"admin-header flex-between\"],[8],[0,\"\\n\\t\"],[7,\"h4\",true],[10,\"class\",\"bold\"],[8],[0,\"Customers\"],[9],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"openModal\",\"customer/customer-form\",[24,[\"model\"]]]],[8],[0,\"New Customer\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"main\",true],[10,\"class\",\"admin-body lg\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-block\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-title flex-between\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"filters flex-start\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"search/search-form\",null,[[\"provider\",\"indices\",\"placeholder\",\"isAdmin\",\"css\"],[[24,[\"model\",\"provider\"]],\"customer\",\"Search for a customer...\",true,\"\"]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-body md no-pad\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"customer/customer-list\",null,[[\"model\",\"dataLoading\",\"showPaginator\",\"showTable\",\"type\",\"description\"],[[24,[\"model\"]],true,true,true,\"index\",\"Looks like you don't have any customers yet.\"]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/admin/customers/index/template.hbs"
    }
  });

  _exports.default = _default;
});