define("bb-web/initializers/reset-scroll", ["exports", "bb-web/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.Route.reopen(_resetScroll.default);
  }

  var _default = {
    name: 'reset-scroll',
    initialize: initialize
  };
  _exports.default = _default;
});