define("bb-web/mixins/mp-stubing", ["exports", "bb-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    stubMp: function stubMp() {
      window.mixpanel = {
        track: function track(st, obj) {
          console.log('mixpanel', 'track', st, obj);
        },
        track_links: function track_links(element, st) {
          console.log('mixpanel', 'track_links', element, st);
        },
        identify: function identify(st) {
          console.log('mixpanel', 'identify', st);
        },
        alias: function alias(st) {
          console.log('mixpanel', 'alias', st);
        },
        init: function init(key) {
          console.log('mixpanel', 'boot', key);
        },
        people: {
          set: function set(st) {
            console.log('mixpanel', st);
          }
        },
        track_charge: function track_charge(amount) {
          console.log('mixpanel', 'charged', amount);
        }
      };
    }
  });

  _exports.default = _default;
});