define("bb-web/pods/components/user/user-context/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Models
    user: null,
    // Hooks	
    init: function init() {
      this._super();

      this._setAdapters(this.get('user'));

      Ember.set(this, 'isLoaded', true);
    },
    willDestroyElement: function willDestroyElement() {
      this._clearAdapters();
    },
    // Methods
    _setAdapters: function _setAdapters(user) {
      this.store.adapterFor('booking').set('userID', user.get('id'));
      this.store.adapterFor('card').set('userID', user.get('id'));
      this.store.adapterFor('charge').set('userID', user.get('id'));
      this.store.adapterFor('conversation').set('userID', user.get('id'));
      this.store.adapterFor('location').set('userID', user.get('id'));
      this.store.adapterFor('message').set('userID', user.get('id'));
      this.store.adapterFor('notification').set('userID', user.get('id'));
      this.store.adapterFor('refund').set('userID', user.get('id'));
    },
    _clearAdapters: function _clearAdapters() {
      this.store.adapterFor('booking').set('userID', null);
      this.store.adapterFor('card').set('userID', null);
      this.store.adapterFor('charge').set('userID', null);
      this.store.adapterFor('conversation').set('userID', null);
      this.store.adapterFor('location').set('userID', null);
      this.store.adapterFor('message').set('userID', null);
      this.store.adapterFor('notification').set('userID', null);
      this.store.adapterFor('refund').set('userID', null);
    }
  });

  _exports.default = _default;
});