define("bb-web/pods/components/table-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VZ2j1MOA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isSortField\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"direction\"]],\"asc\"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[22,\"text\"],false],[1,[28,\"fa-icon\",[\"sort-up\"],[[\"class\"],[\"right small\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"direction\"]],\"desc\"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[22,\"text\"],false],[1,[28,\"fa-icon\",[\"sort-down\"],[[\"class\"],[\"right small\"]]],false],[0,\"\\n\\t\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[1,[22,\"text\"],false],[1,[28,\"fa-icon\",[\"sort\"],[[\"class\"],[\"right small\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/table-header/template.hbs"
    }
  });

  _exports.default = _default;
});