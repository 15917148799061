define("bb-web/pods/booking/serializer", ["exports", "bb-web/pods/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    attrs: {
      location: {
        serialize: 'records'
      },
      lineItems: {
        serialize: 'records'
      },
      miscItems: {
        serialize: 'records'
      },
      depositPaid: {
        serialize: false
      },
      primaryPaid: {
        serialize: false
      },
      summary: {
        serialize: false
      }
    },
    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);

      if (!Ember.isEmpty(json.data.location)) {
        json.data.attributes.location_attributes = json.data.location;
      }

      if (!Ember.isEmpty(json.data['line-items'])) {
        json.data.attributes.line_items_attributes = json.data['line-items'];
      }

      if (!Ember.isEmpty(json.data['misc-items'])) {
        json.data.attributes.misc_items_attributes = json.data['misc-items'];
      }

      if (!Ember.isEmpty(json.data.services)) {
        json.data.attributes.services_attributes = json.data.services;
      }

      delete json.data.location;
      delete json.data['line-items'];
      delete json.data['misc-items'];
      return json;
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key) {
      if (snapshot.attr(key) !== null && snapshot.attr(key) !== "") {
        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});