define("bb-web/pods/cities/detail/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['category_id', 'date', 'time'],
    category_id: '',
    date: '',
    time: ''
  });

  _exports.default = _default;
});