define("bb-web/pods/conversation/serializer", ["exports", "bb-web/pods/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    attrs: {
      messages: {
        serialize: 'records'
      }
    },
    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);

      if (!Ember.isEmpty(json.data.messages)) {
        json.data.attributes.messages_attributes = json.data.messages;
      }

      delete json.data.messages;
      return json;
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key) {
      if (snapshot.attr(key) != null && snapshot.attr(key) != "") {
        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});