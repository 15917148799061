define("bb-web/pods/components/google-map/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'map-block',
    classNameBindings: ['size', 'border'],
    isProvider: false,
    radius: null,
    size: 'md',
    border: false,
    // Models
    model: null,
    location: Ember.computed('model.latitude', function () {
      if (Ember.get(this, 'model.latitude')) {
        return Ember.get(this, 'model');
      }

      return Ember.get(this, 'model.location');
    }),
    // Options
    draggable: false,
    icon: '/icons/map-marker.svg',
    mapTypeControl: false,
    zoom: 14,
    zoomControl: true,
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});