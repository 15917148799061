define("bb-web/templates/svgs/puff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ITH2At0r",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[11,\"stroke\",[22,\"svgColor\"]],[11,\"width\",[22,\"width\"]],[11,\"height\",[22,\"height\"]],[10,\"viewBox\",\"0 0 44 44\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"g\",true],[10,\"fill\",\"none\"],[10,\"fill-rule\",\"evenodd\"],[10,\"stroke-width\",\"2\"],[8],[0,\"\\n\\t\\t\"],[7,\"circle\",true],[10,\"cx\",\"22\"],[10,\"cy\",\"22\"],[10,\"r\",\"1\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"animate\",true],[10,\"attributeName\",\"r\"],[10,\"begin\",\"0s\"],[10,\"dur\",\"1.8s\"],[10,\"values\",\"1; 20\"],[10,\"calcMode\",\"spline\"],[10,\"keyTimes\",\"0; 1\"],[10,\"keySplines\",\"0.165, 0.84, 0.44, 1\"],[10,\"repeatCount\",\"indefinite\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[7,\"animate\",true],[10,\"attributeName\",\"stroke-opacity\"],[10,\"begin\",\"0s\"],[10,\"dur\",\"1.8s\"],[10,\"values\",\"1; 0\"],[10,\"calcMode\",\"spline\"],[10,\"keyTimes\",\"0; 1\"],[10,\"keySplines\",\"0.3, 0.61, 0.355, 1\"],[10,\"repeatCount\",\"indefinite\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"circle\",true],[10,\"cx\",\"22\"],[10,\"cy\",\"22\"],[10,\"r\",\"1\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"animate\",true],[10,\"attributeName\",\"r\"],[10,\"begin\",\"-0.9s\"],[10,\"dur\",\"1.8s\"],[10,\"values\",\"1; 20\"],[10,\"calcMode\",\"spline\"],[10,\"keyTimes\",\"0; 1\"],[10,\"keySplines\",\"0.165, 0.84, 0.44, 1\"],[10,\"repeatCount\",\"indefinite\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[7,\"animate\",true],[10,\"attributeName\",\"stroke-opacity\"],[10,\"begin\",\"-0.9s\"],[10,\"dur\",\"1.8s\"],[10,\"values\",\"1; 0\"],[10,\"calcMode\",\"spline\"],[10,\"keyTimes\",\"0; 1\"],[10,\"keySplines\",\"0.3, 0.61, 0.355, 1\"],[10,\"repeatCount\",\"indefinite\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/templates/svgs/puff.hbs"
    }
  });

  _exports.default = _default;
});