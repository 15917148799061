define("bb-web/pods/message/adapter", ["exports", "bb-web/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    providerID: null,
    userID: null,
    status: null,
    // Methods
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super.apply(this, arguments);

      var providerID = Ember.get(this, 'providerID');
      var userID = Ember.get(this, 'userID');

      if (!Ember.isEmpty(providerID)) {
        return url.replace("v1/", "v1/providers/".concat(providerID, "/"));
      }

      if (!Ember.isEmpty(userID)) {
        return url.replace("v1/", "v1/users/".concat(userID, "/"));
      }

      return url;
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var url = this._super.apply(this, arguments);

      if (Ember.get(this, 'status') == 'read') {
        return this._buildURL() + "/messages/".concat(id, "/read");
      }

      return url;
    }
  });

  _exports.default = _default;
});