define("bb-web/pods/components/search/search-autocomplete-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'autocomplete-section-block',
    classNameBindings: ['lastItem', 'isEmpty'],
    // Models
    query: '',
    results: null,
    result: null,
    combinedHits: null,
    highlightIndex: '',
    // Computed
    indexName: Ember.computed('result', function () {
      var indexName = Ember.get(this, 'result.index').split('_')[0];

      if (indexName == 'city') {
        return 'cities';
      }

      if (indexName == 'category') {
        return 'categories';
      }

      return indexName + 's';
    }),
    isEmpty: Ember.computed('result', function () {
      return Ember.isEmpty(Ember.get(this, 'result.hits')) ? true : false;
    }),
    lastItem: Ember.computed('results.[]', 'result', function () {
      var index = Ember.get(this, 'results').filter(function (result) {
        return result.hits.length > 0;
      });
      return index.get('lastObject') == Ember.get(this, 'result');
    }),
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});