define("bb-web/utils/inject-scripts", ["exports", "ember-inject-scripts/utils/inject-scripts"], function (_exports, _injectScripts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _injectScripts.default;
    }
  });
});