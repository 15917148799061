define("bb-web/pods/components/browse/browse-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+O04lNNz",
    "block": "{\"symbols\":[\"provider\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"fetchData\",\"isRunning\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"loading-indicator\",null,[[\"size\",\"classNames\"],[\"40\",\"loading-section\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"providers\"]]],null,{\"statements\":[[0,\"\\t\\t\\n\"],[4,\"each\",[[24,[\"providers\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"browse/browse-card\",null,[[\"categoryID\",\"providerID\",\"date\",\"category\",\"provider\",\"css\"],[[24,[\"categoryID\"]],[23,1,[\"id\"]],[24,[\"date\"]],[24,[\"category\"]],[23,1,[]],\"no-pad pad-top pad-bottom no-border border-bottom\"]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showPaginator\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"simple-pagination\",null,[[\"pageNumber\",\"pageSize\",\"recordCount\",\"classNames\",\"onPageSelect\"],[[24,[\"providers\",\"meta\",\"pagination\",\"page\"]],[24,[\"providers\",\"meta\",\"pagination\",\"page_size\"]],[24,[\"providers\",\"meta\",\"pagination\",\"total_records\"]],\"paginator flex-start\",[28,\"action\",[[23,0,[]],\"fetchPage\"],null]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"empty-message\",null,[[\"faIcon\",\"type\",\"title\",\"description\",\"showLink\",\"link\"],[\"search\",\"index\",\"No Results\",\"Looks like we don't have any providers that match your search.\",true,\"providers.new\"]]],false],[0,\"\\n\\t\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/browse/browse-list/template.hbs"
    }
  });

  _exports.default = _default;
});