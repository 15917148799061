define("bb-web/pods/components/empty-message/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'col-12 empty-block',
    classNameBindings: ['type', 'dark', 'color'],
    type: null,
    color: null,
    icon: null,
    faColor: null,
    faIcon: null,
    faType: null,
    dark: false
  });

  _exports.default = _default;
});