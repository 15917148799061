define("bb-web/pods/receipt/model", ["exports", "ember-data", "bb-web/mixins/statuses"], function (_exports, _emberData, _statuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend(_statuses.default, {
    // Relationships
    card: belongsTo(),
    booking: belongsTo(),
    refunds: hasMany('refund'),
    // Foreign Keys
    className: attr(),
    // Attributes
    status: attr(),
    amount: attr(),
    discount: attr(),
    platformFee: attr(),
    stripeFee: attr(),
    serviceFee: attr(),
    total: attr(),
    reason: attr(),
    createdAt: attr(),
    // Computed
    statusOptions: Ember.computed('status', function () {
      return ['draft', 'active', 'inactive'];
    }),
    reasons: Ember.computed(function () {
      return ['deposit', 'primary', 'misc'];
    }),
    // Charge Preview
    subtotal: Ember.computed('amount', function () {
      return parseFloat(Ember.get(this, 'amount'));
    }),
    previewPlatformFee: Ember.computed('subtotal', function () {
      var subtotal = Ember.get(this, 'subtotal');
      return subtotal * 0.10;
    }),
    previewStripeFee: Ember.computed('subtotal', 'previewPlatformFee', function () {
      var subtotal = Ember.get(this, 'subtotal');
      var platformFee = Ember.get(this, 'previewPlatformFee');
      return (subtotal + platformFee) / 0.971 + 0.30 - (subtotal + platformFee);
    }),
    previewServiceFee: Ember.computed('subtotal', 'previewPlatformFee', 'previewStripeFee', function () {
      var platformFee = Ember.get(this, 'previewPlatformFee');
      var stripeFee = Ember.get(this, 'previewStripeFee');
      return platformFee + stripeFee;
    }),
    previewTotal: Ember.computed('subtotal', 'previewServiceFee', function () {
      var subtotal = Ember.get(this, 'subtotal');
      var serviceFee = Ember.get(this, 'previewServiceFee');
      return subtotal + serviceFee;
    })
  });

  _exports.default = _default;
});