define("bb-web/templates/tables/charge-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "69o+lz2r",
    "block": "{\"symbols\":[],\"statements\":[[7,\"table\",true],[10,\"class\",\"preview\"],[8],[0,\"\\n\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\"],[7,\"td\",true],[8],[0,\"Subtotal\"],[9],[0,\"\\n\\t\\t\"],[7,\"td\",true],[8],[1,[28,\"format-price\",[[24,[\"charge\",\"amount\"]]],null],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\"],[7,\"td\",true],[8],[4,\"if\",[[24,[\"charge\",\"isCustomerTip\"]]],null,{\"statements\":[[0,\"Processing Fee\"]],\"parameters\":[]},{\"statements\":[[0,\"Service Fee\"]],\"parameters\":[]}],[9],[0,\"\\n\\t\\t\"],[7,\"td\",true],[8],[1,[28,\"format-price\",[[24,[\"charge\",\"previewServiceFee\"]]],null],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\"],[7,\"td\",true],[8],[7,\"b\",true],[8],[0,\"Total\"],[9],[9],[0,\"\\n\\t\\t\"],[7,\"td\",true],[8],[7,\"b\",true],[8],[1,[28,\"format-price\",[[24,[\"charge\",\"previewTotal\"]]],null],false],[9],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"charge\",\"isCustomerTip\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"small\",true],[10,\"class\",\"italic pad text-center\"],[8],[0,\"Beauty Broker doesn't charge a service fee for customer tips.\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/templates/tables/charge-preview.hbs"
    }
  });

  _exports.default = _default;
});