define("bb-web/pods/components/payout/payout-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Attributes
    tagName: 'table',
    classNames: 'table index',
    filteredModel: null,
    sortDesc: '',
    sortValue: '',
    sortDirection: ''
  });

  _exports.default = _default;
});