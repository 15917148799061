define("bb-web/pods/components/setup/setup-flow-progress/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'nav',
    classNames: 'section-subnav border-bottom',
    // Models
    provider: null,
    setup: null,
    steps: null,
    currentStep: null,
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});