define("bb-web/pods/components/user/user-card/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'app-card',
    classNameBindings: ['showPreview', 'showList', 'showCard', 'showDetail', 'css'],
    showCard: false,
    showList: false,
    css: '',
    // Models
    user: null,
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});