define("bb-web/templates/tables/payout-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i1QCmrOq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"table\",true],[10,\"class\",\"preview\"],[8],[0,\"\\n\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\"],[7,\"td\",true],[8],[0,\"Bank Account\"],[9],[0,\"\\n\\t\\t\"],[7,\"td\",true],[8],[1,[24,[\"bank\",\"summary\"]],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\"],[7,\"td\",true],[8],[0,\"Available Balance\"],[9],[0,\"\\n\\t\\t\"],[7,\"td\",true],[8],[1,[28,\"format-price\",[[24,[\"balance\",\"availableBalance\"]]],null],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\"],[7,\"td\",true],[8],[7,\"b\",true],[8],[0,\"Total\"],[9],[9],[0,\"\\n\\t\\t\"],[7,\"td\",true],[8],[7,\"b\",true],[8],[1,[28,\"format-price\",[[24,[\"payout\",\"amount\"]]],null],false],[9],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/templates/tables/payout-preview.hbs"
    }
  });

  _exports.default = _default;
});