define("bb-web/pods/components/wrapper/time-picker-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N7YkjGVu",
    "block": "{\"symbols\":[\"date\"],\"statements\":[[4,\"if\",[[24,[\"dropdown\"]]],null,{\"statements\":[[4,\"power-select\",null,[[\"options\",\"selected\",\"searchEnabled\",\"renderInPlace\",\"placeholder\",\"onChange\"],[[24,[\"dates\"]],[24,[\"momentDate\"]],false,true,\"Select time...\",[28,\"action\",[[23,0,[]],\"setTime\"],null]]],{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"moment-format\",[[23,1,[]],\"hh:mma\"],null],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/wrapper/time-picker-wrapper/template.hbs"
    }
  });

  _exports.default = _default;
});