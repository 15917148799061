define("bb-web/pods/mkt/not-authorized/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "reYiPYEJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"mkt/alert-bar\"],false],[0,\"\\n\"],[1,[28,\"mkt-nav\",null,[[\"isMkt\"],[true]]],false],[0,\"\\n\\n\"],[7,\"main\",true],[10,\"class\",\"app-body sm\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"empty-block index text-center\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"container-small\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h1\",true],[8],[0,\"Hold On One Second.\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"h5\",true],[8],[0,\"Sorry, it looks like you're not authorized to view that resource.\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"flex-middle margin-top pad-top\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-primary btn-lg margin-right\",\"mkt.index\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\\tHomepage\"],[1,[28,\"fa-icon\",[\"chevron-right\"],[[\"class\"],[\"right small\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-gray btn-lg\",\"mkt.contact\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\\tContact us\"],[1,[28,\"fa-icon\",[\"chevron-right\"],[[\"class\"],[\"right small\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[15,\"footers/main-footer\",[]]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bb-web/pods/mkt/not-authorized/template.hbs"
    }
  });

  _exports.default = _default;
});