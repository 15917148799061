define("bb-web/pods/activity/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    // Relationships
    provider: belongsTo(),
    // Keys
    providerId: attr(),
    activitableId: attr(),
    // Attributes
    activitableType: attr('string'),
    subject: attr('string'),
    message: attr('string'),
    createdAt: attr(),
    // Computed
    activityURL: Ember.computed('activitableType', 'activitableId', function () {
      var ID = Ember.get(this, 'activitableId');
      var activity = Ember.get(this, 'activitableType');

      if (activity == 'Service') {
        return 'admin.services.detail';
      }

      if (activity == 'BankAccount') {
        return 'admin.settings.banking';
      }

      if (activity == 'Booking') {
        return 'admin.bookings.detail';
      }

      if (activity == 'Payout') {
        return 'admin.payouts.detail';
      }

      if (activity == 'Provider') {
        return 'admin.settings.profile';
      }
    })
  });

  _exports.default = _default;
});