define("bb-web/pods/provider/adapter", ["exports", "bb-web/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    verify: false,
    merchant: false,
    urlForUpdateRecord: function urlForUpdateRecord() {
      var url = this._super.apply(this, arguments);

      if (Ember.get(this, 'verify')) {
        return url + '/verify';
      }

      if (Ember.get(this, 'merchant')) {
        return url + '/merchant';
      }

      return url;
    }
  });

  _exports.default = _default;
});