define("bb-web/pods/components/bank-account/bank-account-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F/gts6Vu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-title\"],[8],[0,\"\\n\\t\"],[7,\"h4\",true],[8],[0,\"Add Bank Account\"],[9],[0,\"\\n\\t\"],[7,\"h6\",true],[8],[1,[28,\"fa-icon\",[\"lock\"],[[\"class\"],[\"left\"]]],false],[0,\"This form is secure.\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"form-body xs no-pad-bottom\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[8],[0,\"Account Holder Name\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"value\",\"class\",\"placeholder\"],[\"text\",[24,[\"accountHolderName\"]],\"form-control\",\"John Doe\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[8],[0,\"Account Number\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"value\",\"class\",\"placeholder\"],[\"text\",[24,[\"accountNumber\"]],\"form-control\",\"000123456789\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[8],[0,\"Routing Number\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"value\",\"class\",\"placeholder\"],[\"text\",[24,[\"routingNumber\"]],\"form-control\",\"110000000\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"form-footer\"],[8],[0,\"\\n\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-primary btn-xl btn-block\"],[10,\"type\",\"submit\"],[8],[0,\"\\n\\t\\t\"],[4,\"if\",[[24,[\"fetchToken\",\"isRunning\"]]],null,{\"statements\":[[1,[28,\"loading-indicator\",null,[[\"color\",\"size\"],[\"white\",24]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[]},{\"statements\":[[0,\"Save\"],[1,[28,\"fa-icon\",[\"check\"],[[\"class\"],[\"right\"]]],false]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/bank-account/bank-account-form/template.hbs"
    }
  });

  _exports.default = _default;
});