define("bb-web/pods/card/adapter", ["exports", "bb-web/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    userID: null,
    providerID: null,
    bookingID: null,
    customerID: null,
    // Methods
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super.apply(this, arguments);

      var userID = Ember.get(this, 'userID');
      var providerID = Ember.get(this, 'providerID');
      var bookingID = Ember.get(this, 'bookingID');
      var customerID = Ember.get(this, 'customerID');

      if (!Ember.isEmpty(customerID) && !Ember.isEmpty(providerID)) {
        return url.replace("v1/", "v1/providers/".concat(providerID, "/customers/").concat(customerID, "/"));
      }

      if (!Ember.isEmpty(providerID)) {
        return url.replace("v1/", "v1/providers/".concat(providerID, "/"));
      }

      if (!Ember.isEmpty(userID)) {
        return url.replace("v1/", "v1/users/".concat(userID, "/"));
      }

      return url;
    }
  });

  _exports.default = _default;
});