define("bb-web/pods/components/misc/hamburger-btn/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Services
    sideMenu: Ember.inject.service(),
    // Computed
    isActive: Ember.computed.alias('sideMenu.isOpen'),
    // Actions
    actions: {
      toggleMenu: function toggleMenu() {
        Ember.get(this, 'sideMenu').isOpen ? Ember.get(this, 'sideMenu').close() : Ember.get(this, 'sideMenu').open();
      }
    }
  });

  _exports.default = _default;
});