define("bb-web/pods/components/mkt-nav/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    classNames: '',
    showSearch: true,
    showSearchBar: false,
    term: '',
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    },
    // Actions
    actions: {
      clearSearch: function clearSearch() {
        this.controllerFor('search').set('term', '');
        this.routeFor('search').refresh();
      },
      exitSearch: function exitSearch() {
        this.toggleProperty('showSearchBar');
      },
      toggleSearchBar: function toggleSearchBar() {
        Ember.set(this, 'displayTerm', Ember.get(this, 'term'));
        this.toggleProperty('showSearchBar');
      },
      invalidateSession: function invalidateSession() {
        this.currentUser._invalidateSession();
      },
      linkToHomepage: function linkToHomepage() {
        if (this.router.currentRoute.name != 'mkt.index') {
          this.router.transitionTo('mkt.index');
        } else {
          window.location.reload();
        }
      }
    }
  });

  _exports.default = _default;
});