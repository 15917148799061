define("bb-web/mixins/keyboard-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    keyUp: function keyUp(event) {
      switch (event.keyCode) {
        case 13:
          this.onEnterPress();
          break;

        case 27:
          this.onEscPress();
          break;

        case 38:
          this.onUpPress();
          break;

        case 40:
          this.onDownPress();
          break;
      }
    }
  });

  _exports.default = _default;
});