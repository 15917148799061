define("bb-web/pods/components/line-item/line-item-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sU1ZWjFg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showPreview\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"flex-between\"],[8],[0,\"\\n\\t\\t\"],[7,\"h6\",true],[8],[1,[24,[\"item\",\"qty\"]],false],[0,\"x \"],[1,[28,\"capitalize\",[[24,[\"item\",\"title\"]]],null],false],[9],[0,\"\\n\\t\\t\"],[7,\"h5\",true],[8],[1,[28,\"format-price\",[[24,[\"item\",\"amount\"]]],null],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"app-card-content no-pad\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"flex-start\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h5\",true],[10,\"class\",\"bold margin-right\"],[8],[1,[24,[\"item\",\"qty\"]],false],[0,\"x \"],[1,[28,\"capitalize\",[[24,[\"item\",\"title\"]]],null],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"h6\",true],[10,\"class\",\"gray-color\"],[8],[1,[28,\"format-price\",[[24,[\"item\",\"amount\"]]],null],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[10,\"class\",\"no-margin\"],[8],[1,[28,\"truncate\",[[24,[\"item\",\"description\"]],120],null],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/line-item/line-item-card/template.hbs"
    }
  });

  _exports.default = _default;
});