define("bb-web/pods/components/search/search-autocomplete-row/component", ["exports", "bb-web/mixins/statuses"], function (_exports, _statuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_statuses.default, {
    tagName: 'div',
    classNames: 'search-result autocomplete flex-between',
    classNameBindings: ['css', 'highlight'],
    // Models
    query: '',
    indexName: '',
    hit: '',
    combinedHits: null,
    highlightIndex: '',
    // Computed
    highlight: Ember.computed('combinedHits.[]', 'highlightIndex', function () {
      if (Ember.get(this, 'highlightIndex') >= 0) {
        return Ember.get(this, 'combinedHits')[Ember.get(this, 'highlightIndex')].itemID == Ember.get(this, 'hit.itemID') ? true : false;
      }

      return false;
    }),
    // Events
    click: function click() {
      if (!Ember.get(this, 'isAdmin') && Ember.get(this, 'indexName') == 'providers') {
        this.selectResult(Ember.get(this, 'indexName'), Ember.get(this, 'hit.username'));
      } else if (!Ember.get(this, 'isAdmin') && Ember.get(this, 'indexName') == 'services') {
        this.selectResult('providers', Ember.get(this, 'hit.provider_username'));
      } else if (!Ember.get(this, 'isAdmin') && Ember.get(this, 'indexName') == 'categories') {
        this.selectResult(Ember.get(this, 'indexName'), Ember.get(this, 'hit.slug'));
      } else if (!Ember.get(this, 'isAdmin') && Ember.get(this, 'indexName') == 'cities') {
        this.selectResult(Ember.get(this, 'indexName'), Ember.get(this, 'hit.slug'));
      } else {
        this.selectResult(Ember.get(this, 'indexName'), Ember.get(this, 'hit.id'));
      }
    }
  });

  _exports.default = _default;
});