define("bb-web/pods/user/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Services
    fastboot: Ember.inject.service(),
    // Hooks
    model: function model(params) {
      if (this.currentUser.get('user.username') == params.username) {
        return this.currentUser.get('user');
      }
    },
    afterModel: function afterModel(model, transition) {
      !Ember.isEmpty(model) ? this._setHeadTags(model) : null;
    },
    setupController: function setupController(controller, model) {
      controller.set('user', model);
    },
    deactivate: function deactivate() {
      if (Ember.get(this, 'fastboot.isFastBoot')) {
        return;
      }

      ;
      this.store ? this._clearAdapters() : null;
    },
    // Methods
    _setAdapters: function _setAdapters(user) {
      this.store.adapterFor('booking').set('userID', user.get('id'));
      this.store.adapterFor('card').set('userID', user.get('id'));
      this.store.adapterFor('charge').set('userID', user.get('id'));
      this.store.adapterFor('conversation').set('userID', user.get('id'));
      this.store.adapterFor('location').set('userID', user.get('id'));
      this.store.adapterFor('message').set('userID', user.get('id'));
      this.store.adapterFor('notification').set('userID', user.get('id'));
      this.store.adapterFor('refund').set('userID', user.get('id'));
    },
    _clearAdapters: function _clearAdapters() {
      this.store.adapterFor('booking').set('userID', null);
      this.store.adapterFor('card').set('userID', null);
      this.store.adapterFor('charge').set('userID', null);
      this.store.adapterFor('conversation').set('userID', null);
      this.store.adapterFor('location').set('userID', null);
      this.store.adapterFor('message').set('userID', null);
      this.store.adapterFor('notification').set('userID', null);
      this.store.adapterFor('refund').set('userID', null);
    },
    // Methods
    _setHeadTags: function _setHeadTags(model) {
      Ember.set(this, 'headData.pageTitle', "".concat(model.get('name'), "'s Profile - Beauty Broker"));
      Ember.set(this, 'headData.title', "".concat(model.get('name'), "'s Profile"));
      Ember.set(this, 'headData.image', "".concat(model.get('photoFB')));
      Ember.set(this, 'headData.twitterImage', "".concat(model.get('photoTwitter')));
    }
  });

  _exports.default = _default;
});