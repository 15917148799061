define("bb-web/pods/components/provider/provider-card/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'app-card',
    classNameBindings: ['showLine', 'showPreview', 'showList', 'showCard', 'showSummary', 'showSwiper', 'showFeatured', 'css'],
    showLine: false,
    showPreview: false,
    showFeatured: false,
    showSwiper: false,
    showCard: false,
    showList: false,
    css: null,
    fillColor: '#B2499D',
    baseColor: '#CED9DE',
    // Models
    provider: null,
    photos: Ember.computed.alias('provider.photos'),
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    } // Actions

  });

  _exports.default = _default;
});