define("bb-web/pods/user/conversations/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KGYNXZCj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"section-block\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"section-title\"],[8],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-text-primary inline\",\"user.conversations.index\"]],{\"statements\":[[1,[28,\"fa-icon\",[\"chevron-left\"],[[\"class\"],[\"margin-right\"]]],false],[0,\"Back\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"section-inner\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"conversation\",\"id\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-title\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"conversation/conversation-card\",null,[[\"conversation\",\"model\",\"showPreview\",\"css\"],[[24,[\"model\",\"conversation\"]],[24,[\"model\"]],true,\"show-line\"]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-body lg scroll\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"message/message-list\",null,[[\"model\",\"conversationID\",\"dataLoading\",\"showList\"],[[24,[\"model\"]],[24,[\"model\",\"conversation\",\"id\"]],true,true]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"conversation\",\"id\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-footer border-top\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"message/message-form\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/user/conversations/detail/template.hbs"
    }
  });

  _exports.default = _default;
});