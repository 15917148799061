define("bb-web/pods/mkt/news/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6LsL4HMb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixed-top\"],[8],[0,\"\\n\\t\"],[1,[22,\"mkt/alert-bar\"],false],[0,\"\\n\\t\"],[1,[28,\"mkt-nav\",null,[[\"isMkt\"],[true]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"mkt-header-spacer\"],[8],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[1,[28,\"mkt/mkt-prefooter\",null,[[\"status\",\"dataLoading\"],[\"active\",true]]],false],[0,\"\\n\\n\"],[15,\"footers/main-footer\",[]]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bb-web/pods/mkt/news/template.hbs"
    }
  });

  _exports.default = _default;
});