define("bb-web/pods/browse/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gMOV6+oi",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"mkt/alert-bar\"],false],[0,\"\\n\"],[1,[28,\"mkt-nav\",null,[[\"isMkt\"],[true]]],false],[0,\"\\n\\n\"],[4,\"breadcrumb-trail\",null,null,{\"statements\":[[0,\"\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"mkt.index\"]],{\"statements\":[[0,\"Home\"]],\"parameters\":[]},null],[9],[0,\"\\n\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"browse\"]],{\"statements\":[[0,\"Browse\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"browse/browse-header\",null,[[\"latitude\",\"longitude\",\"city\",\"categoryID\",\"date\",\"isLoading\"],[[24,[\"latitude\"]],[24,[\"longitude\"]],[24,[\"city\"]],[24,[\"category_id\"]],[24,[\"date\"]],[24,[\"isLoading\"]]]]],false],[0,\"\\n\\n\"],[7,\"main\",true],[10,\"class\",\"mkt-body\"],[8],[0,\"\\n\\n\\t\"],[7,\"section\",true],[10,\"class\",\"container \"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-section-block no-border no-pad\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-section-body md\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"browse/browse-list\",null,[[\"latitude\",\"longitude\",\"categoryID\",\"date\"],[[24,[\"latitude\"]],[24,[\"longitude\"]],[24,[\"category_id\"]],[24,[\"date\"]]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[15,\"footers/main-footer\",[]]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bb-web/pods/browse/template.hbs"
    }
  });

  _exports.default = _default;
});