define("bb-web/pods/components/schedule/schedule-flow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P5rx0Yol",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-title\"],[8],[0,\"\\n\\t\"],[7,\"h3\",true],[8],[0,\"Edit Schedule\"],[9],[0,\"\\n\\t\"],[7,\"h6\",true],[8],[0,\"What would you like to add to your schedule?\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"form-body md\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row flex-between\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-6\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",false],[12,\"class\",\"flow-select\"],[3,\"action\",[[23,0,[]],\"showInterval\"]],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"fa-icon\",[\"calendar-check\"],[[\"size\",\"class\"],[\"2x\",\"gray-color\"]]],false],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h5\",true],[8],[7,\"b\",true],[8],[0,\"Add Interval\"],[9],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"p\",true],[8],[0,\"Add intervals to create your schedule of availability.\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-6\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",false],[12,\"class\",\"flow-select\"],[3,\"action\",[[23,0,[]],\"showEvent\"]],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"fa-icon\",[\"calendar-times\"],[[\"size\",\"class\"],[\"2x\",\"red-color\"]]],false],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h5\",true],[8],[7,\"b\",true],[8],[0,\"Add Personal Time\"],[9],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"p\",true],[8],[0,\"Add personal time to block out your schedule.\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/schedule/schedule-flow/template.hbs"
    }
  });

  _exports.default = _default;
});