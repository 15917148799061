define("bb-web/pods/components/setup/setup-flow-progress/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "44pzQ1kz",
    "block": "{\"symbols\":[\"step\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"flex-wrap\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"steps\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"li\",true],[10,\"class\",\"flex-start\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",[29,[\"nav-link \",[28,\"if\",[[23,1,[\"isComplete\"]],\"complete\"],null],\" \",[28,\"if\",[[23,1,[\"isActive\"]],\"active\"],null]]]],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"showStep\"]],[23,1,[\"name\"]]],null]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[4,\"if\",[[23,1,[\"isComplete\"]]],null,{\"statements\":[[1,[28,\"fa-icon\",[\"check\"],[[\"class\"],[\"f-sm left\"]]],false]],\"parameters\":[]},{\"statements\":[[1,[28,\"fa-icon\",[\"times\"],[[\"class\"],[\"f-sm left\"]]],false]],\"parameters\":[]}],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"capitalize\",[[23,1,[\"name\"]]],null],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/setup/setup-flow-progress/template.hbs"
    }
  });

  _exports.default = _default;
});