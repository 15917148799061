define("bb-web/pods/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model, transition) {
      this._setHeadTags(model);
    },
    _setHeadTags: function _setHeadTags(model) {
      Ember.set(this, 'headData.pageTitle', "Login - Beauty Broker");
    }
  });

  _exports.default = _default;
});