define("bb-web/pods/components/provider/provider-details/component", ["exports", "bb-web/mixins/mask"], function (_exports, _mask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mask.default, {
    tagName: 'form',
    classNames: 'section-block',
    // Models
    provider: null,
    location: null,
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    },
    // Actions
    actions: {
      setLocation: function setLocation(location) {
        Ember.get(this, 'location.updateLocation').perform(location);
      }
    }
  });

  _exports.default = _default;
});