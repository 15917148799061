define("bb-web/pods/line-item/model", ["exports", "ember-data", "bb-web/utils/compress-photos"], function (_exports, _emberData, _compressPhotos) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    booking: belongsTo(),
    service: belongsTo(),
    // Keys
    bookingId: attr(),
    serviceId: attr(),
    // Attrs
    photo: attr(),
    title: attr(),
    description: attr(),
    amount: attr(),
    qty: attr(),
    duration: attr(),
    _destroy: attr(),
    // Computed
    displayAmount: Ember.computed('amount', 'service', 'qty', function () {
      if (Ember.get(this, 'amount')) {
        return Ember.get(this, 'amount');
      }

      return parseFloat(Ember.get(this, 'service.price') * parseInt(Ember.get(this, 'qty')));
    }),
    photoMin: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'), '1080', '540');
    }),
    photoFB: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'), '1080', '540');
    }),
    photoTwitter: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'), '600', '600', 'crop');
    })
  });

  _exports.default = _default;
});