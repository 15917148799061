define("bb-web/pods/user/model", ["exports", "ember-data", "bb-web/utils/compress-photos"], function (_exports, _emberData, _compressPhotos) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    // Relationships
    conversations: hasMany(),
    provider: belongsTo(),
    location: belongsTo(),
    bookings: hasMany(),
    messages: hasMany(),
    cards: hasMany(),
    // Attrs
    type: attr(),
    photo: attr(),
    name: attr(),
    username: attr(),
    email: attr(),
    phone: attr(),
    password: attr(),
    newPassword: attr(),
    newPasswordConfirmation: attr(),
    createdAt: attr(),
    // Misc
    facebookId: attr(),
    googleId: attr(),
    // Settings
    settings: attr(),
    smsNotifications: Ember.computed.alias('settings.sms_notifications'),
    emailNotifications: Ember.computed.alias('settings.email_notifications'),
    mktNotifications: Ember.computed.alias('settings.mkt_notifications'),
    // Computed
    photoMin: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'), '320', '320');
    }),
    photoFB: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'), '600', '600');
    }),
    photoTwitter: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'), '600', '600');
    }),
    displayPhone: Ember.computed('phone', function () {
      return Ember.get(this, 'phone') ? Ember.get(this, 'phone').slice(1) : null;
    })
  });

  _exports.default = _default;
});