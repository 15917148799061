define("bb-web/pods/admin/settings/widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5XsA5x6N",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\",true],[10,\"class\",\"container-fluid pad-top pad-bottom flex-between border-bottom\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"flex-start\"],[8],[0,\"\\n\\t\\t\"],[7,\"h4\",true],[10,\"class\",\"pad-right\"],[8],[7,\"b\",true],[8],[0,\"Widget\"],[9],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-primary\"],[10,\"type\",\"button\"],[8],[1,[28,\"fa-icon\",[\"code\"],[[\"class\"],[\"left\"]]],false],[0,\"Get Code\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"main\",true],[10,\"class\",\"app-body md\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/admin/settings/widget/template.hbs"
    }
  });

  _exports.default = _default;
});