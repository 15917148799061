define("bb-web/pods/mkt/index/route", ["exports", "bb-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model, transition) {
      this._setHeadTags(transition);
    },
    _setHeadTags: function _setHeadTags(model, transition) {
      Ember.set(this, 'headData.pageTitle', 'Find and Book Beauty Professionals - Beauty Broker');
      Ember.set(this, 'headData.title', 'Find and Book Beauty Professionals - Beauty Broker');
      Ember.set(this, 'headData.description', "Find and book certified beauty professionals that'll come to you for hair, nails, fashion, and makeup services.");
    }
  });

  _exports.default = _default;
});