define("bb-web/pods/components/line-item/line-item-card/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'app-card',
    classNameBindings: ['showPreview', 'showSelect', 'showList', 'showCard', 'ShowSummary', 'showDetail', 'selected', 'css'],
    showPreview: false,
    showList: false,
    showSelect: false,
    showCard: false,
    showDetail: false,
    showSummary: false,
    css: '',
    // Models
    model: null,
    booking: null,
    item: null,
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});