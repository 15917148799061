define("bb-web/pods/components/service/service-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IWrJmffS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"section-title\"],[8],[0,\"\\n\\t\"],[7,\"h6\",true],[8],[0,\"Service #\"],[1,[24,[\"service\",\"id\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"section-body border-bottom\"],[8],[0,\"\\n\\t\"],[7,\"img\",true],[10,\"class\",\"section-img round margin-bottom\"],[11,\"src\",[24,[\"service\",\"photoMin\"]]],[8],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"section-subsection\"],[8],[0,\"\\n\\t\\t\"],[7,\"h6\",true],[10,\"class\",\"badge sm badge-light-gray\"],[8],[1,[28,\"capitalize\",[[24,[\"service\",\"category\",\"name\"]]],null],false],[9],[0,\"\\n\\t\\t\"],[7,\"h5\",true],[10,\"class\",\"bold\"],[8],[1,[28,\"capitalize\",[[24,[\"service\",\"name\"]]],null],false],[9],[0,\"\\n\\t\\t\"],[7,\"h6\",true],[10,\"class\",\"gray-color\"],[8],[1,[24,[\"service\",\"duration\"]],false],[0,\" mins (\"],[1,[28,\"format-price\",[[24,[\"service\",\"price\"]]],null],false],[0,\")\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[1,[24,[\"service\",\"description\"]],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"section-footer\"],[8],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-primary btn-xl btn-block\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"startBooking\",[24,[\"provider\"]]]],[8],[0,\"Book Now\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/service/service-preview/template.hbs"
    }
  });

  _exports.default = _default;
});