define("bb-web/pods/admin/customers/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        provider: this.modelFor('admin'),
        customer: this.store.findRecord('customer', params.customer_id, params)
      });
    },
    afterModel: function afterModel(model) {
      this.store.adapterFor('card').set('customerID', model.customer.id);
      this.store.adapterFor('location').set('customerID', model.customer.id);
    },
    setupController: function setupController(controller, model) {
      model.notableId = model.customer.id;
      model.notableType = 'Customer';
      controller.set('model', model);
    },
    deactivate: function deactivate() {
      this.store.adapterFor('card').set('customerID', null);
      this.store.adapterFor('location').set('customerID', null);
    }
  });

  _exports.default = _default;
});