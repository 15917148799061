define("bb-web/pods/components/service/service-card/component", ["exports", "bb-web/mixins/scheduler", "ember-concurrency"], function (_exports, _scheduler, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_scheduler.default, {
    tagName: 'div',
    classNames: 'app-card',
    classNameBindings: ['showPreview', 'showSelect', 'showList', 'showCard', 'ShowSummary', 'showDetail', 'selected', 'css'],
    showPreview: false,
    showList: false,
    showSelect: false,
    showCard: false,
    showDetail: false,
    showSummary: false,
    selectedID: null,
    css: '',
    serviceQtys: null,
    // Models
    model: null,
    booking: null,
    lineItems: null,
    service: null,
    provider: null,
    availability: null,
    // Computed
    modalModel: Ember.computed('service', function () {
      return {
        service: Ember.get(this, 'service')
      };
    }),
    lineItem: Ember.computed('lineItems.[]', 'lineItems.@each.{qty}', function () {
      var _this = this;

      var lineItems = Ember.get(this, 'lineItems');
      return lineItems.find(function (lineItem) {
        return lineItem.get('serviceId') == Ember.get(_this, 'service.id') || lineItem.get('service.id') == Ember.get(_this, 'service.id');
      });
    }),
    serviceQty: Ember.computed('lineItem', function () {
      return Ember.get(this, 'lineItem.qty');
    }),
    formattedAvailableDates: Ember.computed('availability.dates.[]', function () {
      if (Ember.get(this, 'availability.dates')) {
        var dates = Ember.get(this, 'availability.dates').map(function (date) {
          return {
            date: moment(date),
            formattedDate: moment(date).format()
          };
        });
        dates = Ember.get(this, 'isAdmin') ? dates : dates.filter(function (date) {
          return moment.now() < date.date;
        });
        return dates;
      }

      return [];
    }),
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'serviceQtys', [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    },
    // Actions
    actions: {
      selectQty: function selectQty(service, qty) {
        this.selectQty(service, qty);
      },
      selectBooking: function selectBooking(availableDate) {
        if (Ember.get(this, 'provider.isUnclaimed')) {
          this.send('openModal', 'provider/provider-claim-starter', Ember.get(this, 'provider'));
        } else {
          this.bookingService._createBooking('pending', availableDate.formattedDate);

          this.bookingService._setProvider(Ember.get(this, 'provider'));

          this.bookingService._updateLineItemQty(Ember.get(this, 'service'), 1);

          this.router.transitionTo('book', Ember.get(this, 'provider.username'));
        }
      }
    }
  });

  _exports.default = _default;
});