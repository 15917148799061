define("bb-web/pods/providers/detail/route", ["exports", "bb-web/config/environment", "bb-web/mixins/query-params"], function (_exports, _environment, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_queryParams.default, {
    bookingService: Ember.inject.service('booking'),
    // Hooks
    model: function model(params) {
      return this.store.findRecord('provider', params.username, {
        include: 'location,photos,policy,categories,services'
      });
    },
    afterModel: function afterModel(model, transition) {
      this._setHeadTags(model, transition);
    },
    setupController: function setupController(controller, model) {
      controller.set('provider', model);
    },
    // Actions
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.intent.name != 'book' && this.bookingService.get('booking')) {
          this.bookingService.get('booking').rollbackAttributes();
          this.bookingService.set('booking', null);
        }
      }
    },
    // Methods
    _setHeadTags: function _setHeadTags(model, transition) {
      Ember.set(this, 'headData.pageTitle', " ".concat(model.get('name'), " - Providers - Beauty Broker"));
      Ember.set(this, 'headData.title', "Book Beauty Services with ".concat(model.get('name')));
      Ember.set(this, 'headData.description', "".concat(model.get('description')));
      Ember.set(this, 'headData.image', "".concat(model.get('photoFB')));
      Ember.set(this, 'headData.twitterImage', "".concat(model.get('photoTwitter')));
      Ember.set(this, 'headData.hasBreadcrumbs', true);
      Ember.set(this, 'headData.breadcrumbs', JSON.stringify(this._formatBreadcrumbs(model)));
    },
    _formatBreadcrumbs: function _formatBreadcrumbs(model) {
      var url = _environment.default.APP.defaultWebHost;
      var id = model.get('username');
      return [{
        "@type": "ListItem",
        "position": 1,
        "name": "Providers",
        "item": url + "/providers"
      }, {
        "@type": "ListItem",
        "position": 2,
        "name": id,
        "item": url + "/providers/" + id
      }];
    }
  });

  _exports.default = _default;
});