define("bb-web/pods/user/bookings/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        user: this.modelFor('user')
      });
    }
  });

  _exports.default = _default;
});