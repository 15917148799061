define("bb-web/pods/components/promo/promo-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GKA26uhF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"admin-form-title\"],[8],[0,\"\\n\\t\"],[7,\"h4\",true],[10,\"class\",\"bold\"],[8],[0,\"Add Promo Code\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"admin-form-section border-bottom\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container-small no-margin\"],[8],[0,\"\\t\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"label\",true],[8],[0,\"Promo Code\"],[9],[0,\"\\n\\t\\t\\t\"],[1,[28,\"input\",null,[[\"elementId\",\"type\",\"value\",\"class\",\"placeholder\"],[\"promo_input\",\"text\",[24,[\"promo\",\"code\"]],\"form-control\",\"BEAUTYTODAY\"]]],false],[0,\"\\t\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"admin-form-footer\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container-small no-margin\"],[8],[0,\"\\n\\t\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-primary\"],[10,\"type\",\"submit\"],[8],[0,\"\\n\\t\\t\\t\"],[4,\"if\",[[24,[\"fetchAndSavePromo\",\"isRunning\"]]],null,{\"statements\":[[1,[28,\"loading-indicator\",null,[[\"size\",\"color\"],[\"20\",\"white\"]]],false],[0,\"\\n\\t\\t\\t\"]],\"parameters\":[]},{\"statements\":[[0,\"Save\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/promo/promo-form/template.hbs"
    }
  });

  _exports.default = _default;
});