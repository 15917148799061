define("bb-web/templates/components/-private-api/addon-factory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ob1YscxZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[14,1,[[28,\"hash\",null,[[\"marker\",\"circle\",\"polyline\",\"infoWindow\",\"overlay\",\"control\",\"autocomplete\",\"directions\",\"route\"],[[28,\"component\",[\"g-map/marker\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[24,[\"map\"]],[24,[\"_internalAPI\"]],[24,[\"gMap\"]],\"marker\"]]],[28,\"component\",[\"g-map/circle\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[24,[\"map\"]],[24,[\"_internalAPI\"]],[24,[\"gMap\"]],\"circle\"]]],[28,\"component\",[\"g-map/polyline\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[24,[\"map\"]],[24,[\"_internalAPI\"]],[24,[\"gMap\"]],\"polyline\"]]],[28,\"component\",[\"g-map/info-window\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[24,[\"map\"]],[24,[\"_internalAPI\"]],[24,[\"gMap\"]],\"infoWindow\"]]],[28,\"component\",[\"g-map/overlay\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[24,[\"map\"]],[24,[\"_internalAPI\"]],[24,[\"gMap\"]],\"overlay\"]]],[28,\"component\",[\"g-map/control\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[24,[\"map\"]],[24,[\"_internalAPI\"]],[24,[\"gMap\"]],\"control\"]]],[28,\"component\",[\"g-map/autocomplete\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[24,[\"map\"]],[24,[\"_internalAPI\"]],[24,[\"gMap\"]],\"autocomplete\"]]],[28,\"component\",[\"g-map/directions\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[24,[\"map\"]],[24,[\"_internalAPI\"]],[24,[\"gMap\"]],\"directions\"]]],[28,\"component\",[\"g-map/route\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[24,[\"map\"]],[24,[\"_internalAPI\"]],[24,[\"gMap\"]],\"route\"]]]]]]]],[0,\"\\n    \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/templates/components/-private-api/addon-factory.hbs"
    }
  });

  _exports.default = _default;
});