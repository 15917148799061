define("bb-web/pods/components/misc/hamburger-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vGF+qNhK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",[29,[\"btn hamburger hamburger--slider \",[28,\"if\",[[24,[\"isActive\"]],\"is-active\"],null]]]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"toggleMenu\"]],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"hamburger-box\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"hamburger-inner\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"  \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/misc/hamburger-btn/template.hbs"
    }
  });

  _exports.default = _default;
});