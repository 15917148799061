define("bb-web/pods/components/mkt/alert-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'alert-bar',
    classNameBindings: ['css', 'isProvider'],
    css: '',
    isProvider: Ember.computed('currentUser.user.provider', function () {
      return !Ember.isEmpty(Ember.get(this, 'currentUser.user.provider.id')) ? true : false;
    }),
    // Events
    click: function click() {
      this.router.transitionTo('mkt.for-providers');
    }
  });

  _exports.default = _default;
});