define("bb-web/pods/review/model", ["exports", "ember-data", "bb-web/mixins/statuses"], function (_exports, _emberData, _statuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    booking: belongsTo(),
    customer: belongsTo(),
    provider: belongsTo(),
    // Keys
    bookingId: attr(),
    // Attrs
    score: attr('number'),
    comment: attr(),
    createdAt: attr(),
    // Status
    status: attr(),
    statusOptions: Ember.computed(function () {
      return ['draft', 'inactive', 'active'];
    }),
    // Computed
    fillColor: '#B2499D',
    baseColor: '#CED9DE'
  });

  _exports.default = _default;
});