define("bb-web/pods/book/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qN5Pss34",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showStatusBar\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"provider/provider-status-bar\",null,[[\"showStatusBar\",\"provider\"],[[24,[\"showStatusBar\"]],[24,[\"model\",\"provider\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[1,[22,\"mkt/alert-bar\"],false],[0,\"\\n\"]],\"parameters\":[]}],[1,[22,\"simple-nav\"],false],[0,\"\\n\\n\"],[7,\"main\",true],[10,\"class\",\"app-body md\"],[8],[0,\"\\n\\t\\n\\t\"],[7,\"header\",true],[10,\"class\",\"pad-top pad-bottom\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"provider/provider-card\",null,[[\"provider\",\"showDetail\",\"css\"],[[24,[\"provider\"]],true,\"no-margin\"]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"booking/booking-flow\",null,[[\"provider\"],[[24,[\"provider\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\\n\"],[9],[0,\"\\n\\n\"],[15,\"footers/simple-footer\",[]]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bb-web/pods/book/template.hbs"
    }
  });

  _exports.default = _default;
});