define("bb-web/services/search", ["exports", "bb-web/config/environment", "algoliasearch"], function (_exports, _environment, _algoliasearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var algoliaConfig = _environment.default['ember-algolia'];

  var _default = Ember.Service.extend({
    client: '',
    environment: _environment.default.environment,
    // Models
    provider: null,
    // Computed
    mainFilters: Ember.computed(function () {
      return 'status:verified OR status:active OR status:unClaimed';
    }),
    discardFilters: Ember.computed('provider', function () {
      return "(discarded_at = ???)";
    }),
    providerFilters: Ember.computed('provider', function () {
      return "(provider_id = ".concat(Ember.get(this, 'provider.id'), ")");
    }),
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'client', (0, _algoliasearch.default)(algoliaConfig.algoliaId, algoliaConfig.algoliaKey));
    },
    // Methods
    prepIndexes: function prepIndexes(indexName, query, hits) {
      return {
        indexName: indexName + '_' + Ember.get(this, 'environment'),
        query: query,
        params: {
          filters: Ember.get(this, 'mainFilters'),
          hitsPerPage: hits
        }
      };
    },
    searchIndexes: function searchIndexes(indexes) {
      return this.get('client').search(indexes);
    },
    searchIndex: function searchIndex(indexName, query, page) {
      var index = this.get('client').initIndex(indexName + '_' + Ember.get(this, 'environment'));
      return index.search({
        query: query,
        page: page,
        filters: Ember.get(this, 'mainFilters'),
        hitsPerPage: 2
      });
    },
    searchProviderIndex: function searchProviderIndex(indexName, query, page) {
      var index = this.get('client').initIndex(indexName + '_' + Ember.get(this, 'environment'));
      return index.search({
        query: query,
        filters: Ember.get(this, 'providerFilters'),
        hitsPerPage: 10
      });
    }
  }); // search one index - https://www.algolia.com/doc/api-reference/api-methods/search/
  // search multiple indexes - https://www.algolia.com/doc/api-reference/api-methods/multiple-queries/
  // search parameters - https://www.algolia.com/doc/api-reference/api-parameters/


  _exports.default = _default;
});