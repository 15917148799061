define("bb-web/pods/message/serializer", ["exports", "bb-web/pods/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    isConversation: false,
    serialize: function serialize(snapshot) {
      var data = this._super.apply(this, arguments);

      var json = {
        id: snapshot.id,
        status: snapshot.attr('status'),
        message: snapshot.attr('message'),
        postableType: snapshot.attr('postableType'),
        postableId: snapshot.attr('postableId')
      };
      return Ember.get(this, 'isConversation') ? json : data;
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key) {
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});