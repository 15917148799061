define("bb-web/pods/components/app/app-verification/component", ["exports", "bb-web/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    classNames: '',
    // Computed
    isAuthenticated: Ember.computed.alias('session.isAuthenticated'),
    stagingSession: Ember.computed.alias('currentUser.stagingSession'),
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      !Ember.get(this, 'fastboot.isFastBoot') ? this._checkAccess() : null;
    },
    // Methods
    _checkAccess: function _checkAccess() {
      var isStaging = _environment.default.environment == 'staging';

      if (isStaging && !Ember.get(this, 'isAuthenticated') && !Ember.get(this, 'stagingSession')) {
        this.routeFor(this.router.currentPath).transitionTo('mkt.welcome');
      }
    }
  });

  _exports.default = _default;
});