define("bb-web/pods/bank-account/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    // Relationships
    provider: belongsTo('provider'),
    // Foreign Keys
    providerId: attr(),
    // Attributes
    accountHolderName: attr(),
    token: attr(),
    bankName: attr(),
    lastFour: attr(),
    stripeBankId: attr(),
    primary: attr(),
    // Computed
    isPrimary: Ember.computed.alias('primary'),
    summary: Ember.computed('bankName', 'lastFour', function () {
      return Ember.get(this, 'bankName') + ' ••••' + Ember.get(this, 'lastFour');
    })
  });

  _exports.default = _default;
});