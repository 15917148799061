define("bb-web/pods/components/google-map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RQMAgg8w",
    "block": "{\"symbols\":[\"g\"],\"statements\":[[4,\"if\",[[24,[\"location\"]]],null,{\"statements\":[[4,\"g-map\",null,[[\"backgroundColor\",\"draggable\",\"lat\",\"lng\",\"mapTypeControl\",\"styles\",\"zoom\",\"zoomControl\"],[\"#F4F5F7\",[24,[\"draggable\"]],[24,[\"location\",\"latitude\"]],[24,[\"location\",\"longitude\"]],[24,[\"mapTypeControl\"]],[24,[\"styles\"]],[24,[\"zoom\"]],[24,[\"zoomControl\"]]]],{\"statements\":[[0,\"\\t\\t\\n\"],[4,\"if\",[[24,[\"radius\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"circle\"]],\"expected `g.circle` to be a contextual component but found a string. Did you mean `(component g.circle)`? ('bb-web/pods/components/google-map/template.hbs' @ L14:C5) \"],null]],[[\"lat\",\"lng\",\"radius\",\"fillColor\",\"fillOpacity\",\"strokeColor\",\"strokeOpacity\",\"strokeWeight\"],[[24,[\"location\",\"latitude\"]],[24,[\"location\",\"longitude\"]],[24,[\"radius\"]],\"#B2499D\",0.3,\"#B2499D\",0.5,1]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"marker\"]],\"expected `g.marker` to be a contextual component but found a string. Did you mean `(component g.marker)`? ('bb-web/pods/components/google-map/template.hbs' @ L24:C4) \"],null]],[[\"lat\",\"lng\",\"icon\"],[[24,[\"location\",\"latitude\"]],[24,[\"location\",\"longitude\"]],[24,[\"icon\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/google-map/template.hbs"
    }
  });

  _exports.default = _default;
});