define("bb-web/pods/categories/detail/route", ["exports", "bb-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Hooks
    model: function model(params) {
      return this.store.findRecord('category', params.slug);
    },
    setupController: function setupController(controller, model) {
      controller.set('category', model);
    },
    // Methods
    _setHeadTags: function _setHeadTags(model, transition) {
      Ember.set(this, 'headData.pageTitle', "".concat(model.get('name'), " - Categories - Beauty Broker"));
      Ember.set(this, 'headData.title', "Browse Beauty Professionals by ".concat(model.get('name'), " Services"));
      Ember.set(this, 'headData.description', "".concat(model.get('description')));
      Ember.set(this, 'headData.image', "".concat(model.get('photoFB')));
      Ember.set(this, 'headData.twitterImage', "".concat(model.get('photoTwitter')));
      Ember.set(this, 'headData.hasBreadcrumbs', true);
      Ember.set(this, 'headData.breadcrumbs', JSON.stringify(this._formatBreadcrumbs(model)));
    },
    _formatBreadcrumbs: function _formatBreadcrumbs(model) {
      var url = _environment.default.APP.defaultWebHost;
      var id = model.get('name');
      return [{
        "@type": "ListItem",
        "position": 1,
        "name": "Categories",
        "item": url + "/categories"
      }, {
        "@type": "ListItem",
        "position": 2,
        "name": id,
        "item": url + "/categories/" + id
      }];
    }
  });

  _exports.default = _default;
});