define("bb-web/router", ["exports", "bb-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('mkt', {
      path: '/'
    }, function () {
      this.route('about');
      this.route('contact');
      this.route('not-found');
      this.route('not-authorized');
      this.route('legal', function () {
        this.route('terms');
        this.route('privacy');
      });
      this.route('for-providers');
      this.route('news', function () {
        this.route('detail', {
          path: '/:slug'
        });
      });
      this.route('faq');
      this.route('welcome');
    });
    this.route('user', {
      path: 'user/:username'
    }, function () {
      this.route('bookings', function () {
        this.route('detail', {
          path: ':booking_id'
        });
      });
      this.route('notifications');
      this.route('cards');
      this.route('settings');
      this.route('receipts');
      this.route('charges', {
        path: ':charge_id'
      });
      this.route('refunds', {
        path: ':refund_id'
      });
      this.route('locations', function () {
        this.route('detail', {
          path: ':location_id'
        });
      });
      this.route('conversations', function () {
        this.route('detail', {
          path: ':conversation_id'
        });
      });
    });
    this.route('login');
    this.route('register');
    this.route('forgot-password');
    this.route('reset-password');
    this.route('admin', {
      path: 'admin/:provider_id'
    }, function () {
      this.route('exit');
      this.route('dashboard');
      this.route('bookings', function () {
        this.route('detail', {
          path: ':booking_id'
        });
      });
      this.route('settings', function () {
        this.route('notifications');
        this.route('integrations');
        this.route('banking');
        this.route('widget');
        this.route('photos');
        this.route('availability');
        this.route('insurance');
        this.route('promotions');
        this.route('policies');
        this.route('profile');
        this.route('merchant');
        this.route('location');
      });
      this.route('payouts', function () {
        this.route('detail', {
          path: '/:payout_id'
        });
      });
      this.route('customers', function () {
        this.route('detail', {
          path: '/:customer_id'
        });
      });
      this.route('services', function () {
        this.route('detail', {
          path: '/:service_id'
        });
      });
      this.route('reports');
      this.route('schedule');
      this.route('getting-started');
      this.route('inbox');
    });
    this.route('providers', function () {
      this.route('new');
      this.route('detail', {
        path: '/:username'
      });
    });
    this.route('cities', function () {
      this.route('detail', {
        path: '/:slug'
      });
    });
    this.route('categories', function () {
      this.route('detail', {
        path: '/:slug'
      });
    });
    this.route('new');
    this.route('search');
    this.route('browse');
    this.route('reviews');
    this.route('unsubscribe');
    this.route('sign-up');
    this.route('book', {
      path: 'providers/:username/book'
    });
  });
  var _default = Router;
  _exports.default = _default;
});