define("bb-web/pods/providers/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MKj0P7YC",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"simple-nav\",null,[[\"prevRoute\"],[\"mkt.for-providers\"]]],false],[0,\"\\n\\n\"],[7,\"main\",true],[10,\"class\",\"app-body lg\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"flow\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"provider/provider-flow\",null,[[\"user\"],[[24,[\"user\"]]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[15,\"footers/simple-footer\",[]]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bb-web/pods/providers/new/template.hbs"
    }
  });

  _exports.default = _default;
});