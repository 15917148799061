define("bb-web/pods/components/setup/setup-flow-faq/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'section-block border',
    // Services
    panelActions: Ember.inject.service(),
    // Models
    provider: null,
    steps: null,
    currentStep: null,
    // Hooks
    didInsertElement: function didInsertElement() {
      if (!Ember.get(this, 'fastboot.isFastBoot')) {
        window.innerWidth < 900 ? Ember.get(this, 'panelActions').closeAll() : null;
      }
    }
  });

  _exports.default = _default;
});