define("bb-web/pods/components/search/search-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kIm+4bbr",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"search/search-autocomplete\",null,[[\"term\",\"query\",\"index\",\"indexes\",\"results\",\"combinedHits\",\"placeholder\",\"isAdmin\",\"isBar\",\"isMkt\",\"isRoute\",\"isActive\",\"isLoading\",\"updateQuery\",\"selectResult\",\"clearSearch\"],[[24,[\"term\"]],[24,[\"query\"]],[24,[\"index\"]],[24,[\"indexes\"]],[24,[\"results\"]],[24,[\"combinedHits\"]],[24,[\"placeholder\"]],[24,[\"isAdmin\"]],[24,[\"isBar\"]],[24,[\"isMkt\"]],[24,[\"isRoute\"]],[24,[\"isActive\"]],[24,[\"isLoading\"]],[28,\"action\",[[23,0,[]],\"updateQuery\"],null],[28,\"action\",[[23,0,[]],\"selectResult\"],null],[28,\"action\",[[23,0,[]],\"clearSearch\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/search/search-form/template.hbs"
    }
  });

  _exports.default = _default;
});