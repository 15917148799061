define("bb-web/pods/components/admin/admin-sidenav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xICaL79V",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/admin/admin-sidenav/template.hbs"
    }
  });

  _exports.default = _default;
});