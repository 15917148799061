define("bb-web/pods/components/user/update-password-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oz4k3Gk5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"section-title\"],[8],[0,\"\\n\\t\"],[7,\"h4\",true],[8],[0,\"Change Password\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"section-body\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[8],[0,\"New Password\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"value\",\"class\",\"placeholder\"],[\"password\",[24,[\"user\",\"newPassword\"]],\"form-control\",\"••••••••\"]]],false],[0,\"\\t\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[8],[0,\"Confirm New Password\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"value\",\"class\",\"placeholder\"],[\"password\",[24,[\"user\",\"newPasswordConfirmation\"]],\"form-control\",\"••••••••\"]]],false],[0,\"\\t\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"section-footer flex-between\"],[8],[0,\"\\n\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-primary btn-xl btn-block\"],[10,\"type\",\"submit\"],[8],[0,\"\\n\\t\\t\"],[4,\"if\",[[24,[\"saveUser\",\"isRunning\"]]],null,{\"statements\":[[1,[28,\"loading-indicator\",null,[[\"size\",\"color\"],[\"20\",\"white\"]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[]},{\"statements\":[[0,\"Save\"],[1,[28,\"fa-icon\",[\"check\"],[[\"class\"],[\"right small\"]]],false]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/user/update-password-form/template.hbs"
    }
  });

  _exports.default = _default;
});