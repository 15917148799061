define("bb-web/pods/components/link-external/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    class: '',
    attributeBindings: ['href', 'target', 'disabled', 'rel'],
    href: '',
    target: '_blank',
    rel: 'noopener',
    disabled: false
  });

  _exports.default = _default;
});