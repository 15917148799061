define("bb-web/pods/mkt/legal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P/dE73jq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixed-top\"],[8],[0,\"\\n\\t\"],[1,[22,\"mkt/alert-bar\"],false],[0,\"\\n\\t\"],[1,[28,\"mkt-nav\",null,[[\"isMkt\"],[true]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"mkt-header-spacer\"],[8],[9],[0,\"\\n\\n\"],[7,\"nav\",true],[10,\"class\",\"app-subnav border-bottom\"],[8],[0,\"\\n\\t\"],[7,\"ul\",true],[10,\"class\",\"container-fluid pad-top pad-bottom flex-start\"],[8],[0,\"\\n\\t\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"class\",\"route\"],[\"nav-link\",\"mkt.legal.terms\"]],{\"statements\":[[0,\"Terms of Service\"]],\"parameters\":[]},null],[9],[0,\"\\n\\t\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"class\",\"route\"],[\"nav-link\",\"mkt.legal.privacy\"]],{\"statements\":[[0,\"Privacy Policy\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[1,[28,\"mkt/mkt-prefooter\",null,[[\"status\",\"dataLoading\"],[\"active\",true]]],false],[0,\"\\n\\n\"],[15,\"footers/main-footer\",[]]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bb-web/pods/mkt/legal/template.hbs"
    }
  });

  _exports.default = _default;
});