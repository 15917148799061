define("bb-web/pods/customer/model", ["exports", "ember-data", "bb-web/utils/compress-photos"], function (_exports, _emberData, _compressPhotos) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    location: belongsTo(),
    provider: belongsTo(),
    bookings: hasMany('booking'),
    cards: hasMany('card'),
    notes: hasMany(),
    // Keys
    userId: attr(),
    providerId: attr(),
    // Attrs
    photo: attr(),
    name: attr(),
    email: attr(),
    phone: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    totalBookings: attr(),
    totalRevenue: attr(),
    // Computed
    photoMin: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'));
    })
  });

  _exports.default = _default;
});