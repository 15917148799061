define("bb-web/pods/user/bookings/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ooDhDMLP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"section-block\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"section-title flex-between\"],[8],[0,\"\\n\\t\\t\"],[7,\"h6\",true],[8],[0,\"Bookings\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"section-body lg\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"booking/booking-list\",null,[[\"order\",\"model\",\"dataLoading\",\"showList\",\"type\",\"description\"],[\"id desc\",[24,[\"model\"]],true,true,\"index\",\"Looks like you haven't made any bookings yet.\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/user/bookings/index/template.hbs"
    }
  });

  _exports.default = _default;
});