define("bb-web/pods/components/provider/provider-status-bar/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'alert-bar provider text-center',
    // Models
    provider: null,
    // Actions
    actions: {
      dismissAlert: function dismissAlert() {
        Ember.set(this, 'showStatusBar', false);
      }
    }
  });

  _exports.default = _default;
});