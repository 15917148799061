define("bb-web/pods/components/mkt/mkt-prefooter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rVMBck2C",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"prefooter-block med flex\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row flex-between\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-7\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"prefooter-content margin-bottom\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h2\",true],[8],[0,\"Salon and Spa Services at Your Fingertips\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h5\",true],[10,\"class\",\"reg\"],[8],[0,\"Browse and book the best beauty providers in your area and have them come to you.\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-5 flex-end\"],[8],[0,\"\\n\\t\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-white btn-xl\",\"browse\"]],{\"statements\":[[0,\"Find Providers\"],[1,[28,\"fa-icon\",[\"chevron-right\"],[[\"class\"],[\"margin-left\"]]],false]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/mkt/mkt-prefooter/template.hbs"
    }
  });

  _exports.default = _default;
});