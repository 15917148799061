define("bb-web/pods/mkt/news/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['status'],
    status: 'active',
    category_slug: '',
    // Actions
    actions: {
      filterCategory: function filterCategory(category) {
        Ember.set(this, 'category_slug', category.slug);
      }
    }
  });

  _exports.default = _default;
});