define("bb-web/pods/provider/serializer", ["exports", "bb-web/pods/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    attrs: {
      setup: {
        serialize: false
      },
      setupSteps: {
        serialize: false
      },
      totalReviews: {
        serialize: false
      },
      avgReviewScore: {
        serialize: false
      },
      setupStepsComplete: {
        serialize: false
      },
      ssnLastFourProvided: {
        serialize: false
      },
      insuranceCertificateProvided: {
        serialize: false
      }
    },
    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);

      return json;
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      if (snapshot.attr(key) != null && snapshot.attr(key) != "") {
        this._super.apply(this, arguments);
      }

      if (key == 'phone' && !Ember.isEmpty(json.attributes[key])) {
        json.attributes[key] = '1' + snapshot.attr(key);
      }

      if (key == 'social') {
        Object.keys(json.attributes['social']).forEach(function (key) {
          var url = json.attributes['social'][key];

          if (!Ember.isEmpty(url)) {
            Ember.set(json.attributes['social'], key, !url.includes('http') ? "https://".concat(url) : url);
          }
        });
      }
    },
    normalize: function normalize(typeClass, hash) {
      var fields = Ember.get(typeClass, 'fields');
      fields.forEach(function (type, field) {
        if (field == 'phone') {
          hash.attributes[field] = hash.attributes[field].slice(1);
        }
      });
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});