define("bb-web/pods/admin/settings/policies/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mLBgI8+N",
    "block": "{\"symbols\":[],\"statements\":[[7,\"main\",true],[10,\"class\",\"admin-body xl\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-block\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"policy/policy-form\",null,[[\"provider\",\"policy\"],[[24,[\"provider\"]],[24,[\"policy\"]]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/admin/settings/policies/template.hbs"
    }
  });

  _exports.default = _default;
});