define("bb-web/pods/message/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    user: belongsTo(),
    provider: belongsTo(),
    conversation: belongsTo(),
    // Keys
    postableId: attr(),
    postableType: attr(),
    conversationId: attr(),
    // Attrs
    status: attr(),
    // Unread, Read
    message: attr(),
    updatedAt: attr(),
    createdAt: attr(),
    // Computed
    postable: Ember.computed('postableId', 'postableType', function () {
      if (Ember.get(this, 'postableType') == 'User') {
        return this.store.findRecord('user', Ember.get(this, 'postableId'));
      }

      return this.store.findRecord('provider', Ember.get(this, 'postableId'));
    }),
    isRead: Ember.computed.equal('status', 'read'),
    isUnread: Ember.computed.not('isRead')
  });

  _exports.default = _default;
});