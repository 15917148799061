define("bb-web/pods/components/setup/setup-flow-empty/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'empty-block index',
    title: '',
    msg: ''
  });

  _exports.default = _default;
});