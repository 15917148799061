define("bb-web/mixins/mask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    phoneMask: '(999) 999-9999',
    dobMask: [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    ssnMask: [/[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    // Methods
    _formatDOB: function _formatDOB(model) {
      if (!Ember.isEmpty(Ember.get(model, 'dob'))) {
        var dob = Ember.get(model, 'dob').replace(/\D+/g, '');
        model.set('dob', dob);
      }
    },
    _formatSSN: function _formatSSN(model) {
      if (!Ember.isEmpty(Ember.get(model, 'idNumber'))) {
        var idNumber = Ember.get(model, 'idNumber').replace(/\D+/g, '');
        model.set('idNumber', idNumber);
      }
    }
  });

  _exports.default = _default;
});