define("bb-web/pods/components/provider/provider-flow-promo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AWx9jvoM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-title\"],[8],[0,\"\\n\\t\"],[7,\"h3\",true],[8],[0,\"Promo Code\"],[9],[0,\"\\n\\t\"],[7,\"h6\",true],[8],[0,\"If you have a promo code, please enter it here or skip ahead.\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"form-body md\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[8],[0,\"Promotion Code\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"id\",\"type\",\"value\",\"class\",\"placeholder\"],[\"promo_input\",\"text\",[24,[\"promo\",\"code\"]],\"form-control\",\"BEAUTYTODAY\"]]],false],[0,\"\\t\\n\\t\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"form-footer flex-between\"],[8],[0,\"\\n\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-text-gray pad\"],[11,\"onclick\",[28,\"perform\",[[24,[\"showDashboard\"]]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n\\t\\t\"],[4,\"if\",[[24,[\"showDashboard\",\"isRunning\"]]],null,{\"statements\":[[1,[28,\"loading-indicator\",null,[[\"size\",\"color\"],[\"20\",\"gray\"]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[]},{\"statements\":[[0,\"Skip Step\"],[1,[28,\"fa-icon\",[\"chevron-right\"],[[\"class\"],[\"margin-left sm\"]]],false]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-primary\"],[10,\"type\",\"submit\"],[8],[0,\"\\n\\t\\t\"],[4,\"if\",[[24,[\"fetchAndSavePromo\",\"isRunning\"]]],null,{\"statements\":[[1,[28,\"loading-indicator\",null,[[\"size\",\"color\"],[\"20\",\"white\"]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[]},{\"statements\":[[0,\"Save\"],[1,[28,\"fa-icon\",[\"check\"],[[\"class\"],[\"sm margin-left\"]]],false]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/provider/provider-flow-promo/template.hbs"
    }
  });

  _exports.default = _default;
});