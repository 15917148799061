define("bb-web/pods/components/misc/intercom-btn/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: 'btn btn-text-primary',
    // Services
    intercom: Ember.inject.service(),
    // Events
    click: function click() {
      Ember.get(this, 'intercom').showNewMessage();
      console.log(Ember.get(this, 'intercom').isOpen);
    }
  });

  _exports.default = _default;
});