define("bb-web/utils/compress-photos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function compressPhoto(value) {
    var width = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 320;
    var height = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 320;
    var fit = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'max';

    if (value && value.includes('filestack')) {
      return value.replace('.com', ".com/quality=value:70/output=format:jpg/resize=w:".concat(width, ",h:").concat(height, ",fit:").concat(fit));
    }

    return value;
  }

  var _default = compressPhoto;
  _exports.default = _default;
});