define("bb-web/initializers/head-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('route', 'headData', 'service:headData');
  }

  var _default = {
    name: 'headData',
    initialize: initialize
  };
  _exports.default = _default;
});