define("bb-web/mixins/statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    statusClass: Ember.computed('status', function () {
      var status = Ember.get(this, 'status'); // Generic

      if (status == 'draft') {
        return 'badge-light-gray';
      } // Providers


      if (status == 'verified') {
        return 'badge-primary';
      }

      if (status == 'active') {
        return 'badge-primary';
      }

      if (status == 'unverified') {
        return 'badge-warning';
      }

      if (status == 'inactive') {
        return 'badge-warning';
      }

      if (status == 'unclaimed') {
        return 'badge-accent-3';
      }

      if (status == 'disabled') {
        return 'badge-danger';
      } // Bookings


      if (status == 'draft') {
        return 'badge-gray';
      }

      if (status == 'pending') {
        return 'badge-gray';
      }

      if (status == 'accepted') {
        return 'badge-secondary';
      }

      if (status == 'completed') {
        return 'badge-primary';
      }

      if (status == 'declined') {
        return 'badge-danger';
      }

      if (status == 'canceled') {
        return 'badge-danger';
      } // Conversations


      if (status == 'closed') {
        return 'badge-gray';
      } // Promos


      if (status == 'paid') {
        return 'badge-primary';
      } // Payouts


      if (status == 'succeeded') {
        return 'badge-primary';
      }

      if (status == 'failed') {
        return 'badge-danger';
      }
    }),
    statusBg: Ember.computed('status', function () {
      var status = Ember.get(this, 'status'); // Generic

      if (status == 'draft') {
        return 'light-gray-bg';
      } // Bookings


      if (status == 'pending') {
        return 'light-gray-bg';
      }

      if (status == 'accepted') {
        return 'secondary-bg';
      }

      if (status == 'complete') {
        return 'primary-bg';
      }

      if (status == 'declined') {
        return 'red-bg';
      }

      if (status == 'canceled') {
        return 'red-bg';
      } // Provider


      if (status == 'inactive') {
        return 'gray-bg';
      }

      if (status == 'pending') {
        return 'secondary-bg';
      }

      if (status == 'active') {
        return 'primary-bg';
      }

      if (status == 'unclaimed') {
        return 'badge-accent-3';
      }

      if (status == 'disabled') {
        return 'badge-danger';
      }
    }),
    searchClass: Ember.computed('result.status', function () {
      var status = Ember.get(this, 'result.status'); // Provider

      if (status == 'draft') {
        return 'badge-light-gray';
      }

      if (status == 'unverified') {
        return 'badge-warning';
      }

      if (status == 'verified') {
        return 'badge-primary';
      }

      if (status == 'active') {
        return 'badge-primary';
      }

      if (status == 'inactive') {
        return 'badge-secondary';
      }

      if (status == 'unclaimed') {
        return 'badge-accent-3';
      }

      if (status == 'disabled') {
        return 'badge-danger';
      } // Bookings


      if (status == 'pending') {
        return 'badge-gray';
      }

      if (status == 'accepted') {
        return 'badge-secondary';
      }

      if (status == 'complete') {
        return 'badge-primary';
      }

      if (status == 'declined') {
        return 'badge-dark-gray';
      }

      if (status == 'canceled') {
        return 'badge-danger';
      } // Payouts


      if (status == 'succeeded') {
        return 'badge-primary';
      }

      if (status == 'failed') {
        return 'badge-danger';
      }
    })
  });

  _exports.default = _default;
});