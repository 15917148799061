define("bb-web/pods/admin/settings/promotions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fx9VzS67",
    "block": "{\"symbols\":[],\"statements\":[[7,\"main\",true],[10,\"class\",\"admin-body xl\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-block\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"promo/promo-form\",null,[[\"provider\"],[[24,[\"model\",\"provider\"]]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-block\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"admin-form-title\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h4\",true],[10,\"class\",\"bold\"],[8],[0,\"Promotion Credits\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h6\",true],[8],[0,\"Promotion codes registered and applied by your customers.\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"section-body sm\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"promo/promo-list\",null,[[\"model\",\"dataLoading\"],[[24,[\"model\"]],false]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/admin/settings/promotions/template.hbs"
    }
  });

  _exports.default = _default;
});