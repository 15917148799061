define("bb-web/instance-initializers/raven", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var service = appInstance.lookup('service:raven');

    Ember.onerror = function (error) {
      service.captureException(error);
    };

    Ember.RSVP.on('error', function (error) {
      if (error.name !== 'TransitionAborted') {
        service.captureException(error);
      }
    });
  }

  var _default = {
    name: 'raven',
    initialize: initialize
  };
  _exports.default = _default;
});