define("bb-web/pods/components/auth/register-flow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FclznHx1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-title\"],[8],[0,\"\\n\\t\"],[7,\"h3\",true],[8],[0,\"Real Quick...\"],[9],[0,\"\\n\\t\"],[7,\"h6\",true],[8],[0,\"What are you looking to do with Beauty Broker?\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"form-body md\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row flex-between\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-6\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"btn flow-select lg\"],[12,\"data-test-user-btn\",\"\"],[3,\"action\",[[23,0,[]],\"showUser\"]],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"fa-icon\",[\"shopping-cart\"],[[\"size\",\"class\"],[\"2x\",\"gray-color\"]]],false],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h5\",true],[10,\"class\",\"bold\"],[8],[0,\"I'm an Individual\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"p\",true],[8],[0,\"I'd like to browse and book beauty services in my area.\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-6\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"btn flow-select lg\"],[12,\"data-test-provider-btn\",\"\"],[3,\"action\",[[23,0,[]],\"showProvider\"]],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"fa-icon\",[\"store\"],[[\"size\",\"class\"],[\"2x\",\"gray-color\"]]],false],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h5\",true],[10,\"class\",\"bold\"],[8],[0,\"I'm a Provider\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"p\",true],[8],[0,\"I'd like to list my professional services and accept bookings.\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/auth/register-flow/template.hbs"
    }
  });

  _exports.default = _default;
});