define("bb-web/mixins/scheduler", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Services
    bookingService: Ember.inject.service('booking'),
    // Models
    service: Ember.computed.alias('bookingService.service'),
    // Computed
    yesterday: (0, _moment.default)().endOf('today').subtract(1, 'd'),
    startDate: Ember.computed('booking.startDate', function () {
      return Ember.get(this, 'booking.startDate') ? (0, _moment.default)(Ember.get(this, 'booking.startDate')) : (0, _moment.default)().minute(60);
    }),
    startTime: Ember.computed('booking.startDate', function () {
      return (0, _moment.default)(Ember.get(this, 'booking.startDate'));
    }),
    formattedStartTime: Ember.computed('booking.startDate', function () {
      return (0, _moment.default)(Ember.get(this, 'booking.startDate')).format();
    }),
    bookingStartOfDay: Ember.computed('startDate', function () {
      return (0, _moment.default)(Ember.get(this, 'startDate')).startOf('day');
    }),
    bookingEndOfDay: Ember.computed('startDate', function () {
      return (0, _moment.default)(Ember.get(this, 'startDate')).endOf('day');
    }),
    availableDates: Ember.computed('availability.dates.[]', function () {
      if (Ember.get(this, 'availability.dates')) {
        var dates = Ember.get(this, 'availability.dates').map(function (date) {
          return (0, _moment.default)(date);
        });
        dates = Ember.get(this, 'isAdmin') ? dates : dates.filter(function (date) {
          return _moment.default.now() < (0, _moment.default)(date);
        });
        return dates;
      }

      return [];
    }),
    formattedAvailableDates: Ember.computed('availability.dates.[]', function () {
      if (Ember.get(this, 'availability.dates')) {
        var dates = Ember.get(this, 'availability.dates').map(function (date) {
          return {
            date: (0, _moment.default)(date),
            formattedDate: (0, _moment.default)(date).format()
          };
        });
        dates = Ember.get(this, 'isAdmin') ? dates : dates.filter(function (date) {
          return _moment.default.now() < date.date;
        });
        return dates;
      }

      return [];
    }),
    // Methods
    _setBookingDate: function _setBookingDate(startDate) {
      var formattedDate = (0, _moment.default)(startDate).format();
      Ember.set(this, 'booking.startDate', formattedDate);
    },
    _setBookingTime: function _setBookingTime(startTime) {
      var date = (0, _moment.default)(Ember.get(this, 'startDate').format());

      var startDate = this._setTimeOnDate(date, startTime);

      Ember.set(this, 'booking.startDate', (0, _moment.default)(startDate).format());
    },
    _setTimeOnDate: function _setTimeOnDate(date, time) {
      var duration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      var hours = time.hours();
      var minutes = duration > 0 ? duration : time.minutes();
      return (0, _moment.default)(date).hours(hours).minutes(minutes).seconds(0);
    }
  });

  _exports.default = _default;
});