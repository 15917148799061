define("bb-web/pods/components/provider/provider-insurance-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "foFW7WFu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"section-body lg\"],[8],[0,\"\\n\\t\"],[7,\"iframe\",true],[10,\"src\",\"https://app.thimble.com/home?brokerid=PKTXJW7DP\"],[10,\"border\",\"0\"],[10,\"frameborder\",\"0\"],[10,\"marginwidth\",\"0\"],[10,\"marginheight\",\"0\"],[10,\"allow\",\"geolocation *;\"],[10,\"style\",\"height:700px;width:100%;max-width:100%;max-height:100%;\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/provider/provider-insurance-widget/template.hbs"
    }
  });

  _exports.default = _default;
});