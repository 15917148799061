define("bb-web/pods/components/flash-messages/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['flash-messages'],
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});