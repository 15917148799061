define("bb-web/pods/admin/exit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ewqa8+kT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"taskRunning\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"loading-overlay\",null,[[\"model\",\"solid\",\"background\",\"title\",\"message\"],[[24,[\"provider\"]],true,\"primary-bg\",[24,[\"title\"]],[24,[\"message\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/admin/exit/template.hbs"
    }
  });

  _exports.default = _default;
});