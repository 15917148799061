define("bb-web/pods/admin/settings/insurance/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.modelFor('admin');
    },
    setupController: function setupController(controller, model) {
      controller.set('provider', model);
    }
  });

  _exports.default = _default;
});