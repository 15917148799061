define("bb-web/pods/user/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l61F4HxL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"main\",true],[10,\"class\",\"app-body sm\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"app-block\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\t\\t\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-3\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"user/user-card\",null,[[\"user\",\"showCard\",\"css\"],[[24,[\"user\"]],true,\"no-border\"]]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-9\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"user/user-form\",null,[[\"user\"],[[24,[\"user\"]]]]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/user/settings/template.hbs"
    }
  });

  _exports.default = _default;
});