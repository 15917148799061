define("bb-web/pods/admin/settings/availability/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        provider: this.modelFor('admin'),
        schedule: this.store.queryRecord('schedule', params)
      });
    }
  });

  _exports.default = _default;
});