define("bb-web/pods/components/setup/setup-flow-account/component", ["exports", "bb-web/pods/components/merchant/merchant-form/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'div',
    classNames: '',
    editing: true,
    // Computed
    notEditing: Ember.computed.not('editing'),
    // Models
    provider: null,
    setup: null,
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);

      Ember.get(this, 'setup.profileComplete') ? Ember.set(this, 'editing', false) : null;
    },
    didInsertElement: function didInsertElement() {
      window.mixpanel.track('Viewed setup flow account', {
        provider: Ember.get(this, 'provider.id')
      });
    },
    // Actions
    actions: {
      editStep: function editStep() {
        Ember.set(this, 'editing', true);
      },
      nextAction: function nextAction(provider) {
        Ember.get(this, 'provider.hasDirtyAttributes') ? Ember.get(this, 'saveMerchant').perform(provider) : this.nextAction();
      }
    }
  });

  _exports.default = _default;
});