define("bb-web/services/fetch", ["exports", "bb-web/config/environment", "fetch"], function (_exports, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    // Services
    session: Ember.inject.service(),
    // Properties
    host: _environment.default.APP.dataHost,
    namespace: 'v1',
    // Computed
    authToken: Ember.computed('session.data.authenticated.token', function () {
      return 'JWT ' + Ember.get(this, 'session.data.authenticated.token');
    }),
    headers: Ember.computed('authToken', function () {
      return {
        'X-Requested-with': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.api+json',
        'Authorization': Ember.get(this, 'authToken')
      };
    }),
    // Tasks
    get: function get(url, data) {
      return (0, _fetch.default)(this.formatUrl(url), {
        method: 'GET',
        headers: Ember.get(this, 'headers')
      });
    },
    post: function post(url, data) {
      return (0, _fetch.default)(this.formatUrl(url), {
        method: 'POST',
        headers: Ember.get(this, 'headers'),
        body: JSON.stringify(data)
      });
    },
    put: function put(url, data) {
      return (0, _fetch.default)(this.formatUrl(url), {
        method: 'PUT',
        headers: Ember.get(this, 'headers'),
        body: JSON.stringify(data)
      });
    },
    // Methods
    formatUrl: function formatUrl(url) {
      return "".concat(Ember.get(this, 'host'), "/").concat(Ember.get(this, 'namespace')).concat(url);
    },
    formatData: function formatData(value) {
      return {
        data: {
          attributes: value
        }
      };
    },
    formatArrayData: function formatArrayData(values) {
      return {
        data: values
      };
    }
  });

  _exports.default = _default;
});