define("bb-web/pods/admin/schedule/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        provider: this.modelFor('admin')
      });
    },
    afterModel: function afterModel(model, transition) {
      this._setHeadTags(model, transition);
    },
    // Methods
    _setHeadTags: function _setHeadTags(model, transition) {
      Ember.set(this, 'headData.pageTitle', "Schedule - ".concat(model.provider.get('name'), " - Beauty Broker"));
      Ember.set(this, 'headData.title', "".concat(model.provider.get('name'), "'s Schedule"));
    }
  });

  _exports.default = _default;
});