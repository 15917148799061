define("bb-web/templates/svgs/audio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gYaxjqx7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[11,\"fill\",[22,\"svgColor\"]],[11,\"width\",[22,\"width\"]],[11,\"height\",[22,\"height\"]],[10,\"viewBox\",\"0 0 55 50\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"g\",true],[10,\"transform\",\"matrix(1 0 0 -1 0 80)\"],[8],[0,\"\\n\\t\\t\"],[7,\"rect\",true],[10,\"width\",\"10\"],[10,\"height\",\"20\"],[10,\"rx\",\"3\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"animate\",true],[10,\"attributeName\",\"height\"],[10,\"begin\",\"0s\"],[10,\"dur\",\"4.3s\"],[10,\"values\",\"20;45;57;80;64;32;66;45;64;23;66;13;64;56;34;34;2;23;76;79;20\"],[10,\"calcMode\",\"linear\"],[10,\"repeatCount\",\"indefinite\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"rect\",true],[10,\"x\",\"15\"],[10,\"width\",\"10\"],[10,\"height\",\"80\"],[10,\"rx\",\"3\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"animate\",true],[10,\"attributeName\",\"height\"],[10,\"begin\",\"0s\"],[10,\"dur\",\"2s\"],[10,\"values\",\"80;55;33;5;75;23;73;33;12;14;60;80\"],[10,\"calcMode\",\"linear\"],[10,\"repeatCount\",\"indefinite\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"rect\",true],[10,\"x\",\"30\"],[10,\"width\",\"10\"],[10,\"height\",\"50\"],[10,\"rx\",\"3\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"animate\",true],[10,\"attributeName\",\"height\"],[10,\"begin\",\"0s\"],[10,\"dur\",\"1.4s\"],[10,\"values\",\"50;34;78;23;56;23;34;76;80;54;21;50\"],[10,\"calcMode\",\"linear\"],[10,\"repeatCount\",\"indefinite\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"rect\",true],[10,\"x\",\"45\"],[10,\"width\",\"10\"],[10,\"height\",\"30\"],[10,\"rx\",\"3\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"animate\",true],[10,\"attributeName\",\"height\"],[10,\"begin\",\"0s\"],[10,\"dur\",\"2s\"],[10,\"values\",\"30;45;13;80;56;72;45;76;34;23;67;30\"],[10,\"calcMode\",\"linear\"],[10,\"repeatCount\",\"indefinite\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/templates/svgs/audio.hbs"
    }
  });

  _exports.default = _default;
});