define("bb-web/pods/components/user/user-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N4mooNzj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showPreview\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"row flex\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"img\",true],[10,\"class\",\"app-card-img user round lazyload\"],[11,\"data-src\",[24,[\"user\",\"photoMin\"]]],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-card-content\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h3\",true],[10,\"class\",\"bold\"],[8],[1,[24,[\"user\",\"name\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h6\",true],[8],[0,\"@\"],[1,[24,[\"user\",\"username\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h6\",true],[8],[0,\"Joined \"],[1,[28,\"moment-format\",[[24,[\"user\",\"createdAt\"]],\"MMMM Do, YYYY\"],null],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row flex\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-3 col-md-12\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"img\",true],[10,\"class\",\"app-card-img user round lazyload\"],[11,\"data-src\",[24,[\"user\",\"photoMin\"]]],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-9 col-md-12\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-card-content\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h3\",true],[10,\"class\",\"bold\"],[8],[1,[24,[\"user\",\"name\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h6\",true],[8],[0,\"@\"],[1,[24,[\"user\",\"username\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h6\",true],[8],[0,\"Joined \"],[1,[28,\"moment-format\",[[24,[\"user\",\"createdAt\"]],\"MMMM Do, YYYY\"],null],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/user/user-card/template.hbs"
    }
  });

  _exports.default = _default;
});