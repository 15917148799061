define("bb-web/pods/components/auth/register-flow/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    classNames: 'form-block',
    classNameBindings: ['border'],
    border: false,
    // Models
    // Actions
    actions: {
      showUser: function showUser() {
        this.router.transitionTo('sign-up');
      },
      showProvider: function showProvider() {
        this.router.transitionTo('providers.new');
      }
    }
  });

  _exports.default = _default;
});