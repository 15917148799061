define("bb-web/pods/components/misc/state-dropdown/component", ["exports", "usa-states"], function (_exports, _usaStates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    classNames: '',
    value: '',
    placeholder: '',
    // Models
    states: '',
    state: '',
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);

      var states = new _usaStates.UsaStates();
      Ember.set(this, 'states', states.states);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      Ember.set(this, 'state', Ember.get(this, 'states').find(function (state) {
        return state.name == Ember.get(_this, 'value');
      }));
    },
    // Actions
    actions: {
      setState: function setState(state) {
        Ember.set(this, 'value', state.name);
        Ember.set(this, 'state', state);
      }
    }
  });

  _exports.default = _default;
});