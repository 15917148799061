define("bb-web/pods/components/review/review-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dMTDGnkC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"fetchData\",\"isRunning\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"loading-indicator\",null,[[\"size\",\"classNames\"],[\"40\",\"loading-section small\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"reviews\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\\t\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"margin-bottom flex-between\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"star-rating\",null,[[\"rating\",\"fillColor\",\"baseColor\",\"readOnly\"],[[24,[\"reviewsAvg\"]],[24,[\"fillColor\"]],[24,[\"baseColor\"]],true]]],false],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h2\",true],[10,\"class\",\"primary\"],[8],[1,[22,\"reviewsAvg\"],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"h6\",true],[8],[0,\"Based on \"],[1,[22,\"reviewsLength\"],false],[0,\"x reviews\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\\t\\t\"],[1,[28,\"empty-message\",null,[[\"faIcon\",\"title\",\"type\",\"description\"],[\"medal\",\"Reviews\",[24,[\"type\"]],[24,[\"description\"]]]]],false],[0,\"\\n\\n\\t\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/review/review-preview/template.hbs"
    }
  });

  _exports.default = _default;
});