define("bb-web/pods/components/mkt/alert-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2OHziRkj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"alert-bar-inner flex-end\"],[8],[0,\"\\n\\t\"],[7,\"h6\",true],[8],[0,\"Become a Provider\"],[1,[28,\"fa-icon\",[\"chevron-right\"],[[\"class\"],[\"right\"]]],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/mkt/alert-bar/template.hbs"
    }
  });

  _exports.default = _default;
});