define("bb-web/pods/reviews/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xX0GYrHu",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"simple-nav\",null,[[\"prevRoute\"],[\"mkt.index\"]]],false],[0,\"\\n\\n\"],[7,\"main\",true],[10,\"class\",\"app-body lg\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"flow\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"container-x-small text-left\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"review/review-form\",null,[[\"model\",\"border\",\"inModal\"],[[24,[\"model\"]],true,false]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[15,\"footers/simple-footer\",[]]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bb-web/pods/reviews/template.hbs"
    }
  });

  _exports.default = _default;
});