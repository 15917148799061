define("bb-web/pods/activity/adapter", ["exports", "bb-web/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    // Properties
    params: null,
    // Models
    providerID: null,
    // Methods
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super.apply(this, arguments);

      var providerID = Ember.get(this, 'providerID');
      return url.replace("v1/", "v1/providers/".concat(providerID, "/"));
    }
  });

  _exports.default = _default;
});