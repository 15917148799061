define("bb-web/pods/components/category/category-swiper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "So5watxP",
    "block": "{\"symbols\":[\"category\"],\"statements\":[[4,\"if\",[[24,[\"fetchData\",\"isRunning\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"loading-indicator\",null,[[\"size\",\"classNames\"],[\"40\",\"loading-section\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"categories\"]]],null,{\"statements\":[[4,\"swiper-container\",null,[[\"options\"],[[24,[\"swiperOptions\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"categories\"]]],null,{\"statements\":[[4,\"swiper-slide\",null,null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[28,\"category/category-card\",null,[[\"category\",\"showMkt\",\"css\"],[[23,1,[]],true,\"no-border no-pad\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"mkt-swiper-btns\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn mkt-swiper-btn prev\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"prevSlide\"]],[8],[1,[28,\"fa-icon\",[\"chevron-left\"],null],false],[9],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn mkt-swiper-btn next\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"nextSlide\"]],[8],[1,[28,\"fa-icon\",[\"chevron-right\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\\t\"],[1,[28,\"empty-message\",null,[[\"faIcon\",\"title\",\"type\",\"description\"],[\"cut\",\"Categories\",[24,[\"type\"]],[24,[\"description\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/category/category-swiper/template.hbs"
    }
  });

  _exports.default = _default;
});