define("bb-web/pods/admin/bookings/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        provider: this.modelFor('admin'),
        booking: this.store.findRecord('booking', params.booking_id, params),
        isAdmin: true
      });
    },
    setupController: function setupController(controller, model) {
      model.notableId = model.booking.id;
      model.notableType = 'Booking';
      controller.set('model', model);
    }
  });

  _exports.default = _default;
});