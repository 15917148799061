define("bb-web/utils/currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = currency;

  function currency(value) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

    if (Ember.isEmpty(value) || isNaN(value)) {
      value = 0.00;
    }

    return parseFloat(value).toFixed(digits);
  }
});