define("bb-web/pods/components/mkt/mkt-hero-swiper/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: '',
    swipeOptions: null,
    showPhotoSwiper: false,
    slideIndex: 0,
    // Models
    swiper: null,
    photos: null,
    photo: null,
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);

      this._setSwipeOptions();

      Ember.set(this, 'isLoaded', true);
    },
    // Methods
    _setSwipeOptions: function _setSwipeOptions() {
      Ember.set(this, 'swipeOptions', {
        autoplay: true,
        fade: true,
        loop: true,
        parallax: true,
        pagination: {
          el: '.mkt-hero-swiper-pagination'
        }
      });
    }
  });

  _exports.default = _default;
});