define("bb-web/pods/components/filters/status-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    className: '',
    type: 'Booking',
    statusOptions: null,
    currentStatus: null,
    // Models
    model: null,
    dropdown: null,
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'statusOptions', this._setStatusOptions(Ember.get(this, 'type')));
    },
    // Actions
    actions: {
      setStatus: function setStatus(status, dd) {
        console.log(status);
        this.setFilter('status', status);
        dd.actions.close();
      }
    },
    // Methods
    _setStatusOptions: function _setStatusOptions(modelName) {
      switch (modelName) {
        case 'Booking':
          return ['pending', 'accepted', 'declined', 'completed', 'canceled'];

        case 'Service':
          return ['draft', 'active', 'inactive'];

        case 'Payout':
          return ['draft', 'pending', 'succeeded', 'failed'];
      }
    }
  });

  _exports.default = _default;
});