define("bb-web/pods/components/empty-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E0XZPmqT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"faIcon\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"empty-img \",[22,\"faColor\"]]]],[8],[0,\"\\n\\t\\t\"],[1,[28,\"fa-icon\",[[24,[\"faIcon\"]]],[[\"size\",\"prefix\"],[\"2x\",[24,[\"faType\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"empty-content\"],[8],[0,\"\\n\\t\"],[7,\"h5\",true],[10,\"class\",\"bold\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\\t\"],[7,\"p\",true],[10,\"class\",\"\"],[8],[1,[22,\"description\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"action\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-text-primary\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"action\"]]],null]]],[8],[0,\"\\n\\t\\t\"],[1,[22,\"actionText\"],false],[1,[28,\"fa-icon\",[\"chevron-right\"],[[\"class\"],[\"right small\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"showLink\"]],[24,[\"link\"]]],null]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-primary btn-xl btn-block\",[24,[\"link\"]]]],{\"statements\":[[0,\"\\t\\tBe the first\"],[1,[28,\"fa-icon\",[\"chevron-right\"],[[\"class\"],[\"right small\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/empty-message/template.hbs"
    }
  });

  _exports.default = _default;
});