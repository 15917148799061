define("bb-web/pods/user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mBnGvQQD",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"mkt/alert-bar\"],false],[0,\"\\n\"],[1,[28,\"mkt-nav\",null,[[\"isMkt\"],[true]]],false],[0,\"\\n\\n\"],[7,\"nav\",true],[10,\"class\",\"container-fluid d-none d-md-block pad border-bottom\"],[8],[0,\"\\n\\t\"],[7,\"ul\",true],[10,\"class\",\"flex-start\"],[8],[0,\"\\n\\t\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"nav-link\",\"user.bookings\",[24,[\"user\",\"username\"]]]],{\"statements\":[[0,\"Bookings\"]],\"parameters\":[]},null],[9],[0,\"\\n\\t\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"nav-link\",\"user.cards\",[24,[\"user\",\"username\"]]]],{\"statements\":[[0,\"Cards\"]],\"parameters\":[]},null],[9],[0,\"\\n\\t\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"nav-link\",\"user.conversations\",[24,[\"user\",\"username\"]]]],{\"statements\":[[0,\"Inbox\"]],\"parameters\":[]},null],[9],[0,\"\\n\\t\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"nav-link\",\"user.locations\",[24,[\"user\",\"username\"]]]],{\"statements\":[[0,\"Locations\"]],\"parameters\":[]},null],[9],[0,\"\\n\\t\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"nav-link\",\"user.receipts\",[24,[\"user\",\"username\"]]]],{\"statements\":[[0,\"Receipts\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[0,\"\\t\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"nav-link\",\"user.settings\",[24,[\"user\",\"username\"]]]],{\"statements\":[[0,\"Settings\"]],\"parameters\":[]},null],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"user/user-context\",[],[[\"@user\"],[[23,0,[\"user\"]]]]],[0,\"\\n\\n\"],[15,\"footers/main-footer\",[]]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bb-web/pods/user/template.hbs"
    }
  });

  _exports.default = _default;
});