define("bb-web/pods/mkt/for-providers/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model, transition) {
      this._setHeadTags(transition);
    },
    _setHeadTags: function _setHeadTags(model, transition) {
      Ember.set(this, 'headData.pageTitle', 'For Providers - Beauty Broker');
      Ember.set(this, 'headData.title', 'For Providers');
      Ember.set(this, 'headData.description', "Create a free provider account with Beauty Broker today to start building your personal brand and book appointments from anywhere.");
    }
  });

  _exports.default = _default;
});