define("bb-web/pods/components/provider/featured-provider-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XiZeRvc4",
    "block": "{\"symbols\":[\"provider\"],\"statements\":[[4,\"if\",[[24,[\"isLoaded\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"fetchData\",\"isRunning\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"loading-indicator\",null,[[\"size\",\"classNames\"],[\"40\",\"loading-section\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"providers\"]]],null,{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"featured-swiper-btns\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"button\",true],[10,\"class\",\"btn swipe-btn large swiper-btn-prev\"],[8],[1,[28,\"fa-icon\",[\"chevron-left\"],null],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"button\",true],[10,\"class\",\"btn swipe-btn large swiper-btn-next\"],[8],[1,[28,\"fa-icon\",[\"chevron-right\"],null],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\\n\"],[4,\"swiper-container\",null,[[\"tagName\",\"options\",\"class\"],[\"section\",[24,[\"swipeOptions\"]],\"featured-swiper-container\"]],{\"statements\":[[0,\"\\t\\t\\t\\n\"],[4,\"each\",[[24,[\"providers\"]]],null,{\"statements\":[[4,\"swiper-slide\",null,[[\"class\"],[\"featured-swipe-card\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[1,[28,\"provider/provider-card\",null,[[\"provider\",\"showSwiper\",\"isFeatured\",\"css\"],[[23,1,[]],true,true,\"no-border no-pad text-left\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/provider/featured-provider-list/template.hbs"
    }
  });

  _exports.default = _default;
});