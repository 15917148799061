define("bb-web/pods/components/receipt/receipt-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X4xpVZSG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showPreview\"]]],null,{\"statements\":[[0,\"\\t\\n\\t\"],[7,\"div\",true],[10,\"class\",\"flex-between\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h5\",true],[8],[7,\"b\",true],[8],[1,[28,\"format-price\",[[24,[\"receipt\",\"total\"]]],null],false],[9],[9],[0,\"\\n\\t\\t\\t\"],[7,\"h6\",true],[8],[1,[24,[\"receipt\",\"card\",\"brand\"]],false],[0,\" ****\"],[1,[24,[\"receipt\",\"card\",\"lastFour\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"h6\",true],[8],[1,[28,\"moment-format\",[[24,[\"receipt\",\"createdAt\"]],\"MMM Do, YYYY @ hh:mma\"],null],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"h6\",true],[11,\"class\",[29,[\"badge sm \",[24,[\"receipt\",\"statusClass\"]]]]],[8],[1,[28,\"capitalize\",[[24,[\"receipt\",\"status\"]]],null],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/receipt/receipt-card/template.hbs"
    }
  });

  _exports.default = _default;
});