define("bb-web/pods/components/loading-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gflrgKbj",
    "block": "{\"symbols\":[],\"statements\":[[15,\"svgs/puff\",[]]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bb-web/pods/components/loading-indicator/template.hbs"
    }
  });

  _exports.default = _default;
});