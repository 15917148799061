define("bb-web/pods/misc-item/serializer", ["exports", "bb-web/pods/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    serialize: function serialize(snapshot) {
      var data = this._super.apply(this, arguments);

      var json = {
        id: snapshot.id,
        qty: snapshot.attr('qty'),
        title: snapshot.attr('title'),
        description: snapshot.attr('description'),
        amount: snapshot.attr('amount')
      };
      return json;
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key) {
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});