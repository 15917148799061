define("bb-web/pods/providers/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // redirect() {
    // 	if(this.currentUser.get('user.provider.id')) {
    // 		this.transitionTo('admin.index', this.currentUser.get('user.provider.id'));
    // 	}
    // },
    beforeModel: function beforeModel(transition) {
      return Ember.RSVP.resolve(this._super.apply(this, arguments));
    },
    model: function model() {
      return this.currentUser.get('user');
    },
    setupController: function setupController(controller, model) {
      controller.set('user', model);
    }
  });

  _exports.default = _default;
});