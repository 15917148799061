define("bb-web/pods/reset-password/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['id', 'token'],
    id: '',
    token: ''
  });

  _exports.default = _default;
});