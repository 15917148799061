define("bb-web/mixins/reset-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Services
    fastboot: Ember.inject.service(),
    // Properties
    isFastboot: Ember.computed.reads('fastboot.isFastBoot'),
    activate: function activate() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'isFastboot')) {
        return;
      }

      window.scrollTo(0, 0);
    }
  });

  _exports.default = _default;
});