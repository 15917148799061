define("bb-web/pods/components/wrapper/time-picker-wrapper/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Props
    dropdown: true,
    dates: null,
    date: '',
    // Computed
    momentDate: Ember.computed('date', function () {
      if (this.get('date')) {
        return (0, _moment.default)(Ember.get(this, 'date'));
      }

      return (0, _moment.default)();
    }),
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);

      Ember.isEmpty(Ember.get(this, 'dates')) ? this._buildTimeline() : null;
    },
    // Actions
    actions: {
      setTime: function setTime(date) {
        var hours = (0, _moment.default)(date).hours();
        var minutes = (0, _moment.default)(date).minutes();
        var newDate = (0, _moment.default)(Ember.get(this, 'date')).hours(hours).minutes(minutes);
        this.setTime(newDate.format());
      }
    },
    // Methods
    _buildTimeline: function _buildTimeline() {
      var dates = [];
      var startDate = (0, _moment.default)().startOf('d');
      var endDate = (0, _moment.default)().endOf('d');

      while (startDate <= endDate) {
        dates.pushObject((0, _moment.default)(startDate));
        startDate = startDate.add(30, 'm');
      }

      Ember.set(this, 'dates', dates);
    }
  });

  _exports.default = _default;
});