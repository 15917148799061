define("bb-web/pods/components/search/search-result/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'search-result border-bottom flex-between',
    // Models
    result: null,
    results: null,
    // Computed
    indexName: Ember.computed('results', function () {
      var indexName = Ember.get(this, 'results.index').split('_')[0];

      if (indexName == 'city') {
        return 'cities';
      }

      if (indexName == 'category') {
        return 'categories';
      }

      return indexName + 's';
    }),
    // Events
    click: function click() {
      if (Ember.get(this, 'indexName') == 'services') {
        this.router.transitionTo('providers.detail', Ember.get(this, 'result.provider_username'));
      } else if (Ember.get(this, 'indexName') == 'categories') {
        this.router.transitionTo(Ember.get(this, 'indexName') + '.detail', Ember.get(this, 'result.slug'));
      } else if (Ember.get(this, 'indexName') == 'cities') {
        this.router.transitionTo(Ember.get(this, 'indexName') + '.detail', Ember.get(this, 'result.slug'));
      } else if (Ember.get(this, 'indexName') == 'providers') {
        this.router.transitionTo(Ember.get(this, 'indexName') + '.detail', Ember.get(this, 'result.username'));
      }
    },
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});