define("bb-web/pods/booking/adapter", ["exports", "bb-web/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    providerID: null,
    userID: null,
    customerID: null,
    status: null,
    // Methods
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super.apply(this, arguments);

      var customerID = Ember.get(this, 'customerID');
      var userID = Ember.get(this, 'userID');
      var providerID = Ember.get(this, 'providerID');

      if (!Ember.isEmpty(providerID)) {
        return url.replace("v1/", "v1/providers/".concat(providerID, "/"));
      }

      if (!Ember.isEmpty(userID)) {
        return url.replace("v1/", "v1/users/".concat(userID, "/"));
      }

      if (!Ember.isEmpty(customerID)) {
        return url.replace("v1/", "v1/customers/".concat(userID, "/"));
      }

      return url;
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      if (Ember.get(this, 'status') == 'pending') {
        return this._buildURL() + "/bookings/".concat(id, "/pending");
      }

      if (Ember.get(this, 'status') == 'accepted') {
        return this._buildURL() + "/bookings/".concat(id, "/accept");
      }

      if (Ember.get(this, 'status') == 'declined') {
        return this._buildURL() + "/bookings/".concat(id, "/decline");
      }

      if (Ember.get(this, 'status') == 'completed') {
        return this._buildURL() + "/bookings/".concat(id, "/complete");
      }

      if (Ember.get(this, 'status') == 'canceled') {
        return this._buildURL() + "/bookings/".concat(id, "/cancel");
      }

      return this._buildURL() + "/bookings/".concat(id);
    }
  });

  _exports.default = _default;
});