define("bb-web/pods/components/butter/article-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'app-card',
    classNameBindings: ['css', 'noBorderBottom'],
    border: false,
    isAdmin: false,
    // Computed
    noBorderBottom: Ember.computed('article', 'articles', function () {
      return Ember.get(this, 'articles.lastObject.id') == Ember.get(this, 'article.id') ? true : false;
    }),
    // Model
    article: null,
    articles: null,
    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
    },
    // Methods
    _loopCategories: function _loopCategories(article) {
      var _this = this;

      article.categories.forEach(function (category) {
        _this._setCategoryClass(category);
      });
    },
    _setCategoryClass: function _setCategoryClass(category) {
      if (category.slug == 'changelog') {
        category.class = 'badge-light-gray';
      }
    },
    _loopTags: function _loopTags(article) {
      var _this2 = this;

      article.tags.forEach(function (tag) {
        _this2._setTagClass(tag);
      });
    },
    _setTagClass: function _setTagClass(tag) {
      if (tag.slug == 'feature') {
        tag.class = 'badge-primary';
      }

      if (tag.slug == 'upgrade') {
        tag.class = 'badge-secondary';
      }

      if (tag.slug == 'bug-fix') {
        tag.class = 'badge-accent-3';
      }

      if (tag.slug == 'removal') {
        tag.class = 'badge-danger';
      }
    }
  });

  _exports.default = _default;
});