define("bb-web/pods/mkt/about/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model, transition) {
      this._setHeadTags(transition);
    },
    _setHeadTags: function _setHeadTags(model, transition) {
      Ember.set(this, 'headData.pageTitle', 'About Us - Beauty Broker');
      Ember.set(this, 'headData.title', 'About Beauty Broker');
      Ember.set(this, 'headData.description', "Beauty Broker is a digital marketplace where certified beauty professionals can build their personal brand, market their skills, and build their mobile business -- all in one place.");
    }
  });

  _exports.default = _default;
});