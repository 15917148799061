define("bb-web/pods/application/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);

      delete json.data.relationships;
      return json;
    }
  });

  _exports.default = _default;
});