define("bb-web/pods/providers/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YdauwiO6",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"mkt/alert-bar\"],false],[0,\"\\n\"],[1,[28,\"mkt-nav\",null,[[\"isMkt\"],[true]]],false],[0,\"\\n\\n\"],[4,\"breadcrumb-trail\",null,null,{\"statements\":[[0,\"\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"mkt.index\"]],{\"statements\":[[0,\"Home\"]],\"parameters\":[]},null],[9],[0,\"\\n\\t\"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"providers.index\"]],{\"statements\":[[0,\"Providers\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"main\",true],[10,\"class\",\"mkt-body pad-top pad-bottom\"],[8],[0,\"\\n\\n\\t\"],[7,\"section\",true],[10,\"class\",\"container pad-top pad-bottom\"],[8],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-text-primary\",\"mkt.index\"]],{\"statements\":[[1,[28,\"fa-icon\",[\"chevron-left\"],[[\"class\"],[\"left\"]]],false],[0,\"Back Home\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\\n\\t\"],[7,\"section\",true],[10,\"class\",\"container \"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-section-block\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-section-title\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h2\",true],[8],[0,\"Providers\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h6\",true],[8],[0,\"Browse through all our providers.\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-section-body\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"provider/provider-list\",null,[[\"dataLoading\",\"showPaginator\",\"showCard\"],[true,true,true]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[15,\"footers/main-footer\",[]]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bb-web/pods/providers/index/template.hbs"
    }
  });

  _exports.default = _default;
});