define("bb-web/pods/photo/model", ["exports", "ember-data", "bb-web/utils/compress-photos"], function (_exports, _emberData, _compressPhotos) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    provider: belongsTo(),
    // Keys
    providerId: attr(),
    // Attr
    photo: attr(),
    position: attr(),
    title: attr(),
    description: attr(),
    // Computed
    photoMin: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'), '1000', '750');
    }),
    photoFB: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'), '1000', '750');
    }),
    photoTwitter: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'), '750', '750', 'crop');
    })
  });

  _exports.default = _default;
});