define("bb-web/pods/mkt/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showSelect: true,
    showscheduler: false,
    showLocation: false,
    showReview: false,
    showConfirm: false,
    // Actions
    actions: {
      showSelect: function showSelect() {
        Ember.set(this, 'showSelect', true);
        Ember.set(this, 'showScheduler', false);
        Ember.set(this, 'showReview', false);
        Ember.set(this, 'showCard', false);
        Ember.set(this, 'showConfirm', false);
      },
      showScheduler: function showScheduler() {
        Ember.set(this, 'showSelect', false);
        Ember.set(this, 'showScheduler', true);
        Ember.set(this, 'showReview', false);
        Ember.set(this, 'showCard', false);
        Ember.set(this, 'showConfirm', false);
      },
      showReview: function showReview() {
        Ember.set(this, 'showSelect', false);
        Ember.set(this, 'showScheduler', false);
        Ember.set(this, 'showReview', true);
        Ember.set(this, 'showCard', false);
        Ember.set(this, 'showConfirm', false);
      },
      showCard: function showCard() {
        Ember.set(this, 'showSelect', false);
        Ember.set(this, 'showScheduler', false);
        Ember.set(this, 'showReview', false);
        Ember.set(this, 'showCard', true);
        Ember.set(this, 'showConfirm', false);
      },
      showConfirm: function showConfirm() {
        Ember.set(this, 'showSelect', false);
        Ember.set(this, 'showScheduler', false);
        Ember.set(this, 'showReview', false);
        Ember.set(this, 'showCard', false);
        Ember.set(this, 'showConfirm', true);
      }
    }
  });

  _exports.default = _default;
});