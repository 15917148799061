define("bb-web/pods/user/conversations/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        user: this.modelFor('user'),
        conversation: this.store.findRecord('conversation', params.conversation_id, {
          include: 'messages'
        })
      });
    }
  });

  _exports.default = _default;
});