define("bb-web/pods/providers/detail/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // Computed
    showStatusBar: Ember.computed('model.provider.status', function () {
      if (!Ember.get(this, 'model.provider.isActive')) return true;
    })
  });

  _exports.default = _default;
});