define("bb-web/pods/mkt/for-providers/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    subtotal: 100,
    platformFee: Ember.computed('subtotal', function () {
      return Ember.get(this, 'subtotal') * 0.07;
    }),
    stripeFee: Ember.computed('subtotal', 'platformFee', function () {
      var subtotal = Ember.get(this, 'subtotal');
      var platformFee = Ember.get(this, 'platformFee');
      return (subtotal + platformFee) / 0.971 + 0.30 - (subtotal + platformFee);
    }),
    serviceFee: Ember.computed('subtotal', 'platformFee', 'stripeFee', function () {
      var platformFee = Ember.get(this, 'platformFee');
      var stripeFee = Ember.get(this, 'stripeFee');
      return platformFee + stripeFee;
    }),
    total: Ember.computed('subtotal', 'serviceFee', function () {
      var subtotal = Ember.get(this, 'subtotal');
      var serviceFee = Ember.get(this, 'serviceFee');
      return subtotal + serviceFee;
    })
  });

  _exports.default = _default;
});