define("bb-web/pods/book/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Hooks
    model: function model(params) {
      return this.store.findRecord('provider', params.username, {
        include: 'location,policy,services,categories'
      });
    },
    afterModel: function afterModel(model, transition) {
      this._setHeadTags(model, transition);
    },
    setupController: function setupController(controller, model) {
      controller.set('provider', model);
    },
    // Methods
    _setHeadTags: function _setHeadTags(model, transition) {
      Ember.set(this, 'headData.pageTitle', "Book ".concat(model.get('name'), " - Beauty Broker"));
      Ember.set(this, 'headData.title', "Book ".concat(model.get('name'), " Today"));
    }
  });

  _exports.default = _default;
});