define("bb-web/pods/admin/services/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        provider: this.modelFor('admin'),
        service: this.store.findRecord('service', params.service_id, {
          include: 'category'
        })
      });
    }
  });

  _exports.default = _default;
});