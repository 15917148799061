define("bb-web/pods/provider/model", ["exports", "ember-data", "bb-web/config/environment", "bb-web/mixins/statuses", "bb-web/utils/compress-photos"], function (_exports, _emberData, _environment, _statuses, _compressPhotos) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend(_statuses.default, {
    availability: belongsTo(),
    city: belongsTo(),
    conversations: hasMany(),
    location: belongsTo(),
    policy: belongsTo(),
    schedule: belongsTo(),
    bankAccounts: hasMany('bank-account'),
    categories: hasMany('category'),
    messages: hasMany(),
    photos: hasMany(),
    review: hasMany(),
    services: hasMany(),
    // Keys
    userId: attr(),
    cityId: attr(),
    // Attrs
    name: attr(),
    username: attr(),
    description: attr(),
    company: attr(),
    dob: attr(),
    email: attr(),
    photo: attr(),
    phone: attr(),
    ssnLastFour: attr(),
    ssnLastFourProvided: attr(),
    insuranceFileName: attr(),
    insuranceCertificate: attr(),
    insuranceCertificateProvided: attr(),
    createdAt: attr(),
    idNumber: attr(),
    totalReviews: attr(),
    avgReviewScore: attr(),
    // Status
    status: attr(),
    isDraft: Ember.computed.equal('status', 'draft'),
    isActive: Ember.computed.equal('status', 'active'),
    isUnverified: Ember.computed.equal('status', 'unverified'),
    isVerified: Ember.computed.equal('status', 'verified'),
    isUnclaimed: Ember.computed.equal('status', 'unclaimed'),
    isDisabled: Ember.computed.equal('status', 'disabled'),
    // Settings
    settings: attr(),
    termsOfService: Ember.computed.alias('settings.terms_of_service'),
    smsNotifications: Ember.computed.alias('settings.sms_notifications'),
    emailNotifications: Ember.computed.alias('settings.email_notifications'),
    mktNotifications: Ember.computed.alias('settings.mkt_notifications'),
    travelProximity: Ember.computed.alias('settings.travel_proximity'),
    // Social
    social: attr(),
    web: Ember.computed.alias('social.web'),
    facebook: Ember.computed.alias('social.facebook'),
    twitter: Ember.computed.alias('social.twitter'),
    instagram: Ember.computed.alias('social.instagram'),
    socialSetup: Ember.computed('social', function () {
      var social = Ember.get(this, 'social');
      var keys = !Ember.isEmpty(social) ? Object.keys(social) : null;
      var setup = [];

      if (keys) {
        keys.forEach(function (key) {
          social[key] != null ? setup.pushObject(true) : null;
        });
      }

      return !Ember.isEmpty(setup) ? true : false;
    }),
    // Setup
    setup: attr(),
    setupSteps: attr(),
    setupStepsComplete: attr(),
    setupStepsPercentage: Ember.computed('setupStepsComplete', 'setupSteps', function () {
      var percent = parseFloat(Ember.get(this, 'setupSteps')) / parseFloat(Ember.get(this, 'setupStepsComplete'));
      return parseFloat(percent * 100).toFixed(2) + '%';
    }),
    insuranceComplete: Ember.computed.alias('setup.insurance_complete'),
    // Computed
    photoMin: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'), '320', '320');
    }),
    photoFB: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'), '600', '600');
    }),
    photoTwitter: Ember.computed('photo', function () {
      return (0, _compressPhotos.default)(Ember.get(this, 'photo'), '600', '600');
    }),
    featuredPhoto: Ember.computed('photos.[]', function () {
      return Ember.get(this, 'photos').sortBy('position').get('firstObject');
    }),
    profileURL: Ember.computed(function () {
      var host = _environment.default.APP.defaultWebHost;
      return "".concat(host, "/providers/").concat(Ember.get(this, 'username'));
    }),
    shareTitle: Ember.computed(function () {
      return "Book ".concat(Ember.get(this, 'name'), " on Beauty Broker");
    })
  });

  _exports.default = _default;
});