define("bb-web/pods/application/adapter", ["exports", "ember-data", "bb-web/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;

  var _default = JSONAPIAdapter.extend({
    host: _environment.default.APP.dataHost,
    authorizer: 'authorizer:application',
    namespace: 'v1',
    // Services
    session: Ember.inject.service(),
    // Computed
    authToken: Ember.computed('session.data.authenticated.token', function () {
      return 'JWT ' + Ember.get(this, 'session.data.authenticated.token');
    }),
    headers: Ember.computed('authToken', function () {
      return {
        'X-Requested-with': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.api+json',
        'Authorization': Ember.get(this, 'authToken')
      };
    }),
    // Overrides
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      return this.ajax(url, "PUT", {
        data: data
      });
    }
  });

  _exports.default = _default;
});