define("bb-web/pods/components/photo/photo-collage/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    classnames: '',
    showPhotoSwiper: false,
    // Models
    model: null,
    photos: null,
    selectedPhoto: null,
    // Computed
    featuredPhoto: Ember.computed('photos.[]', 'photos.@each.{title}', function () {
      return Ember.get(this, 'photos').find(function (photo) {
        return photo.position == 0;
      });
    }),
    filteredPhotos: Ember.computed('photos.[]', 'photos.@each.{title}', function () {
      return Ember.get(this, 'photos').filter(function (photo) {
        return photo.position > 0 && photo.position < 3;
      });
    }),
    // Actions
    actions: {
      showPhotoSwiper: function showPhotoSwiper(photo) {
        Ember.set(this, 'selectedPhoto', photo);
        Ember.set(this, 'showPhotoSwiper', true);
      }
    }
  });

  _exports.default = _default;
});