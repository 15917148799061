define("bb-web/pods/components/provider/provider-claim-starter/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isDesktop: false,
    // Models
    provider: null,
    // Actions
    actions: {
      submitClaim: function submitClaim() {
        Ember.get(this, 'claimProvider').perform();
      }
    }
  });

  _exports.default = _default;
});