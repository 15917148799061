define("bb-web/pods/components/activity/activity-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UrqizNo/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showPreview\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"route\",\"model\"],[[24,[\"activity\",\"activityURL\"]],[24,[\"activity\",\"activitableId\"]]]],{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"flex-between\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h6\",true],[10,\"class\",\"primary-font\"],[8],[7,\"b\",true],[8],[1,[24,[\"activity\",\"subject\"]],false],[9],[9],[0,\"\\n\\t\\t\\t\"],[7,\"h6\",true],[10,\"class\",\"badge sm badge-light-gray\"],[8],[1,[28,\"moment-from-now\",[[24,[\"activity\",\"createdAt\"]]],null],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[10,\"class\",\"no-margin\"],[8],[1,[24,[\"activity\",\"message\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showList\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[[24,[\"activity\",\"activityURL\"]],[24,[\"activity\",\"activitableId\"]]]],{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"app-card-content no-pad\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h6\",true],[10,\"class\",\"badge sm badge-light-gray\"],[8],[1,[28,\"moment-from-now\",[[24,[\"activity\",\"createdAt\"]]],null],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"h6\",true],[10,\"class\",\"primary-font\"],[8],[7,\"b\",true],[8],[1,[24,[\"activity\",\"subject\"]],false],[9],[9],[0,\"\\n\\t\\t\\t\"],[7,\"p\",true],[10,\"class\",\"no-margin\"],[8],[1,[24,[\"activity\",\"message\"]],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/activity/activity-card/template.hbs"
    }
  });

  _exports.default = _default;
});