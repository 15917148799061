define("bb-web/pods/admin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y3wU38KK",
    "block": "{\"symbols\":[],\"statements\":[[5,\"admin/admin-nav\",[],[[\"@provider\"],[[22,\"provider\"]]]],[0,\"\\n\"],[5,\"admin/admin-context\",[],[[\"@provider\"],[[22,\"provider\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/admin/template.hbs"
    }
  });

  _exports.default = _default;
});