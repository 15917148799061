define("bb-web/mixins/list-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Properties
    params: null,
    // Sorting
    sortValue: Ember.computed('params.order', function () {
      var value = Ember.get(this, 'params.order').split(' ');
      return value[0];
    }),
    sortDirection: 'asc',
    // Actions
    actions: {
      sortList: function sortList(property, direction) {
        this._setDirection(property, direction);

        var newOrder = property + ' ' + Ember.get(this, 'sortDirection');
        Ember.set(this, 'params.order', newOrder);

        this._fetchData();
      },
      filterList: function filterList(filter, value) {
        var params = Ember.get(this, 'params');
        params.set(filter, value);

        this._fetchData();
      },
      clearFilters: function clearFilters() {
        this._clearParams();

        this._fetchData();
      }
    },
    // Methods
    _setDirection: function _setDirection(property, direction) {
      var order = Ember.get(this, 'params.order');

      if (order.includes(property) && order.includes('asc')) {
        Ember.set(this, 'sortDirection', 'desc');
      } else {
        Ember.set(this, 'sortDirection', 'asc');
      }
    },
    _clearParams: function _clearParams() {
      Ember.set(this, 'params', null);
      Ember.set(this, 'params', Ember.Object.create());

      this._setParams();
    },
    _fetchData: function _fetchData() {
      Ember.get(this, 'fetchData').perform();
    }
  });

  _exports.default = _default;
});