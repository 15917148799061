define("bb-web/pods/user/bookings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WqVV4N/w",
    "block": "{\"symbols\":[],\"statements\":[[7,\"main\",true],[10,\"class\",\"app-body pad-top pad-bottom sm\"],[8],[0,\"\\t\\t\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\t\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-3\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"user/user-card\",null,[[\"user\",\"showCard\"],[[24,[\"model\",\"user\"]],true]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-9\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/user/bookings/template.hbs"
    }
  });

  _exports.default = _default;
});