define("bb-web/helpers/format-price", ["exports", "bb-web/utils/currency"], function (_exports, _currency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (value) {
    return '$' + (0, _currency.default)(value[0], value[1]).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  });

  _exports.default = _default;
});