define("bb-web/pods/schedule/adapter", ["exports", "bb-web/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    providerID: null,
    // Methods
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super.apply(this, arguments);

      var providerID = Ember.get(this, 'providerID');

      if (!Ember.isEmpty(providerID)) {
        return url.replace("v1/", "v1/providers/".concat(providerID, "/"));
      }

      return url;
    },
    urlForQueryRecord: function urlForQueryRecord() {
      return this._buildURL() + "/schedule";
    }
  });

  _exports.default = _default;
});