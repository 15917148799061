define("bb-web/pods/card/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    user: belongsTo(),
    customer: belongsTo(),
    charges: hasMany('charge'),
    // Foreign Keys
    cardableId: attr(),
    cardableType: attr(),
    // Attrs
    token: attr(),
    brand: attr(),
    name: attr(),
    lastFour: attr(),
    expMonth: attr(),
    expYear: attr(),
    funding: attr(),
    country: attr(),
    primary: attr(),
    createdAt: attr()
  });

  _exports.default = _default;
});