define("bb-web/pods/components/browse/browse-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bKPDSxzY",
    "block": "{\"symbols\":[\"category\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"power-select-wrapper\"],[8],[0,\"\\n\\t\"],[1,[28,\"place-autocomplete-field\",null,[[\"value\",\"handlerController\",\"placeChangedCallback\",\"inputClass\",\"types\",\"placeholder\",\"setValueWithProperty\",\"preventSubmit\"],[[24,[\"city\"]],[23,0,[]],[28,\"action\",[[23,0,[]],\"setCity\"],null],\"form-control city-search\",\"(cities)\",\"Select your city\",\"formatted_address\",true]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"power-select-wrapper\"],[8],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"searchEnabled\",\"placeholder\",\"onChange\"],[[24,[\"categories\"]],[24,[\"category\"]],false,\"Select a service...\",[28,\"action\",[[23,0,[]],\"setCategory\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"capitalize\",[[23,1,[\"name\"]]],null],false],[0,\" services\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"power-select-wrapper\"],[8],[0,\"\\n\\t\"],[1,[28,\"wrapper/date-picker-wrapper\",null,[[\"date\",\"setDate\"],[[24,[\"params\",\"date\"]],[28,\"action\",[[23,0,[]],\"setDate\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[7,\"div\",true],[10,\"class\",\"power-select-wrapper\"],[8],[0,\"\\n\\t\"],[1,[28,\"wrapper/time-picker-wrapper\",null,[[\"date\",\"setTime\"],[[24,[\"params\",\"date\"]],[28,\"action\",[[23,0,[]],\"setTime\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"button\",true],[10,\"aria-label\",\"Search for Provider\"],[10,\"class\",\"btn btn-primary\"],[10,\"type\",\"submit\"],[8],[0,\"\\n\\t\"],[4,\"if\",[[24,[\"searchProviders\",\"isRunning\"]]],null,{\"statements\":[[1,[28,\"loading-indicator\",null,[[\"size\",\"color\"],[\"18\",\"white\"]]],false],[0,\"\\n\\t\"]],\"parameters\":[]},{\"statements\":[[1,[28,\"fa-icon\",[\"search\"],null],false]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/components/browse/browse-bar/template.hbs"
    }
  });

  _exports.default = _default;
});