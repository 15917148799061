define("bb-web/pods/components/admin/admin-context/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Services
    sideMenu: Ember.inject.service(),
    // Models
    provider: null,
    // Hooks	
    init: function init() {
      this._super();

      this._setAdapters(this.get('provider'));

      Ember.set(this, 'isLoaded', true);
    },
    didInsertElement: function didInsertElement() {
      this.sideMenu.set('isAdmin', true);
      window.mixpanel.track('Viewed dashboard', {
        provider: Ember.get(this, 'provider.id')
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.sideMenu.set('isAdmin', false);

      this._clearAdapters();
    },
    // Methods
    _setAdapters: function _setAdapters(provider) {
      this.store.adapterFor('activity').set('providerID', provider.get('id'));
      this.store.adapterFor('bank-account').set('providerID', provider.get('id'));
      this.store.adapterFor('booking').set('providerID', provider.get('id'));
      this.store.adapterFor('card').set('providerID', provider.get('id'));
      this.store.adapterFor('charge').set('providerID', provider.get('id'));
      this.store.adapterFor('conversation').set('providerID', provider.get('id'));
      this.store.adapterFor('customer').set('providerID', provider.get('id'));
      this.store.adapterFor('event').set('providerID', provider.get('id'));
      this.store.adapterFor('interval').set('providerID', provider.get('id'));
      this.store.adapterFor('location').set('providerID', provider.get('id'));
      this.store.adapterFor('message').set('providerID', provider.get('id'));
      this.store.adapterFor('note').set('providerID', provider.get('id'));
      this.store.adapterFor('payout').set('providerID', provider.get('id'));
      this.store.adapterFor('photo').set('providerID', provider.get('id'));
      this.store.adapterFor('policy').set('providerID', provider.get('id'));
      this.store.adapterFor('promo').set('providerID', provider.get('id'));
      this.store.adapterFor('refund').set('providerID', provider.get('id'));
      this.store.adapterFor('schedule').set('providerID', provider.get('id'));
      this.store.adapterFor('service').set('providerID', provider.get('id'));
      this.store.adapterFor('setup').set('providerID', provider.get('id'));
    },
    _clearAdapters: function _clearAdapters() {
      this.store.adapterFor('activity').set('providerID', null);
      this.store.adapterFor('bank-account').set('providerID', null);
      this.store.adapterFor('booking').set('providerID', null);
      this.store.adapterFor('card').set('providerID', null);
      this.store.adapterFor('charge').set('providerID', null);
      this.store.adapterFor('conversation').set('providerID', null);
      this.store.adapterFor('customer').set('providerID', null);
      this.store.adapterFor('event').set('providerID', null);
      this.store.adapterFor('interval').set('providerID', null);
      this.store.adapterFor('location').set('providerID', null);
      this.store.adapterFor('message').set('providerID', null);
      this.store.adapterFor('note').set('providerID', null);
      this.store.adapterFor('payout').set('providerID', null);
      this.store.adapterFor('photo').set('providerID', null);
      this.store.adapterFor('policy').set('providerID', null);
      this.store.adapterFor('promo').set('providerID', null);
      this.store.adapterFor('refund').set('providerID', null);
      this.store.adapterFor('schedule').set('providerID', null);
      this.store.adapterFor('service').set('providerID', null);
      this.store.adapterFor('setup').set('providerID', null);
    }
  });

  _exports.default = _default;
});