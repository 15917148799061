define("bb-web/pods/admin/services/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.modelFor('admin');
    },
    afterModel: function afterModel(model, transition) {
      this._setHeadTags(model, transition);
    },
    setupController: function setupController(controller, model) {
      controller.set('provider', model);
    },
    // Methods
    _setHeadTags: function _setHeadTags(model, transition) {
      Ember.set(this, 'headData.pageTitle', "Services - ".concat(model.get('name'), " - Beauty Broker"));
      Ember.set(this, 'headData.title', "".concat(model.get('name'), "'s Services"));
    }
  });

  _exports.default = _default;
});