define("bb-web/pods/mkt/news/index/route", ["exports", "bb-web/config/environment", "bb-web/mixins/query-params", "buttercms"], function (_exports, _environment, _queryParams, _buttercms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var butter = (0, _buttercms.default)(_environment.default.butterCMS.apiKey);

  var _default = Ember.Route.extend(_queryParams.default, {
    model: function model(params) {
      return butter.category.list().then(function (response) {
        return response.data.data;
      });
    },
    afterModel: function afterModel(model) {
      this._setHeadTags(model);
    },
    setupController: function setupController(controller, model) {
      model = model.toArray();
      model.unshift({
        name: 'All',
        slug: ''
      });
      controller.set('categories', model);
    },
    // Methods
    _setHeadTags: function _setHeadTags() {
      Ember.set(this, 'headData.pageTitle', 'News - Beauty Broker');
      Ember.set(this, 'headData.title', 'The Latest News');
      Ember.set(this, 'headData.description', "Get the latest news and announcements from Beauty Broker here.");
    }
  });

  _exports.default = _default;
});