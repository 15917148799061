define("bb-web/pods/components/admin/admin-dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fDe+uHGX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showMobile\"]]],null,{\"statements\":[[0,\"\\t\"],[15,\"views/dashboard/mobile\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"prevWidth\"]]],null,{\"statements\":[[0,\"\\t\"],[15,\"views/dashboard/desk\",[]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "bb-web/pods/components/admin/admin-dashboard/template.hbs"
    }
  });

  _exports.default = _default;
});