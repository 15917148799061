define("bb-web/extensions/service-extensions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.reopen({
    // Methods
    routeFor: function routeFor() {
      var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'application';
      return this._lookupFor('route', path);
    },
    controllerFor: function controllerFor() {
      var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'application';
      return this._lookupFor('controller', path);
    },
    componentFor: function componentFor(path) {
      return this._lookupFor('component', path);
    },
    _lookupFor: function _lookupFor(type, path) {
      return Ember.getOwner(this).lookup("".concat(type, ":").concat(path));
    }
  });

  _exports.default = _default;
});