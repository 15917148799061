define("bb-web/pods/admin/settings/availability/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['include'],
    include: 'intervals,events'
  });

  _exports.default = _default;
});