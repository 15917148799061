define("bb-web/pods/components/booking/draft-booking-list/component", ["exports", "bb-web/pods/components/booking/booking-list/component", "bb-web/pods/components/booking/booking-list/template", "moment", "ember-concurrency"], function (_exports, _component, _template, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_template.default, {
    tagName: '',
    classNames: '',
    layout: _template.default,
    type: 'index',
    description: "Looks like you don't have any draft bookings.",
    dataLoading: true,
    showPagintor: true,
    showPreview: false,
    showCard: false,
    showList: false,
    showTable: false,
    // Models
    model: null,
    provider: null,
    bookings: null,
    // Methods
    _setParams: function _setParams() {
      Ember.set(this, 'params', Ember.Object.create());
      var params = Ember.get(this, 'params');
      params.set('order', 'id desc');
      params.set('status', 'draft');
      params.set('page', 1);
      params.set('page_size', 6);
      params.set('include', 'customer,line_items');
    }
  });

  _exports.default = _default;
});