define("bb-web/pods/admin/getting-started/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EqzYz/6u",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\",true],[10,\"class\",\"admin-header\"],[8],[0,\"\\n\\t\"],[7,\"h4\",true],[10,\"class\",\"bold\"],[8],[0,\"Getting Started\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"main\",true],[10,\"class\",\"app-body lg\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container-fluid pad-top pad-bottom\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"setup/setup-flow\",null,[[\"model\",\"dataLoading\"],[[24,[\"model\"]],true]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bb-web/pods/admin/getting-started/template.hbs"
    }
  });

  _exports.default = _default;
});