define("bb-web/pods/search/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model, transition) {
      this._setHeadTags(model);
    },
    _setHeadTags: function _setHeadTags(model) {
      Ember.set(this, 'headData.pageTitle', "Search - Beauty Broker");
      Ember.set(this, 'headData.title', "Search for a Beauty Professional or Service");
      Ember.set(this, 'headData.description', "Search our database of beauty professionals by name, category, location, and services offered.");
    }
  });

  _exports.default = _default;
});