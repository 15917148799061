define("bb-web/pods/reviews/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel(transition) {
      return Ember.RSVP.resolve(this._super.apply(this, arguments)); // return this.get('session').authenticate('authenticator:token', transition.queryParams.email, transition.queryParams.token);
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        booking: this.store.findRecord('booking', params.booking_id, {
          include: 'line_items,card,provider'
        }),
        user: this.currentUser.get('user')
      });
    }
  });

  _exports.default = _default;
});