define("bb-web/pods/policy/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    provider: belongsTo(),
    // Attrs
    travelDistance: attr('number'),
    travelCharge: attr('number'),
    minBookingCharge: attr('number'),
    // Computed
    policies: Ember.computed('travelDistance', 'travelCharge', 'minBookingCharge', function () {
      return [{
        type: 'distance',
        name: 'Travel Distance',
        value: Ember.get(this, 'travelDistance'),
        description: "This is how far the provider is typically willing to travel for a booking."
      }, {
        type: 'currency',
        name: 'Travel Charge',
        value: Ember.get(this, 'travelCharge'),
        description: "Travel charges cover the provider's expense for travel."
      }, {
        type: 'currency',
        name: 'Minimum Booking',
        value: Ember.get(this, 'minBookingCharge'),
        description: "This is minimum booking subtotal allowed by this provider."
      }];
    }),
    radius: Ember.computed('travelDistance', function () {
      return parseFloat(Ember.get(this, 'travelDistance')) ? parseFloat(Ember.get(this, 'travelDistance')) * 1500 : 0;
    })
  });

  _exports.default = _default;
});