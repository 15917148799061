define("bb-web/pods/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "bb-web/config/environment"], function (_exports, _applicationRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    // Services
    sideMenu: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    headData: Ember.inject.service(),
    panelActions: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    // Hooks
    beforeModel: function beforeModel() {
      if (Ember.get(this, 'fastboot.isFastBoot')) {
        return;
      }

      this._loadMixpanel();

      return this._loadCurrentUser();
    },
    afterModel: function afterModel(model, transition) {
      this._setHeadTags(transition);
    },
    // Actions
    actions: {
      didTransition: function didTransition() {
        if (Ember.get(this, 'fastboot.isFastBoot')) {
          return;
        }

        window.mixpanel ? window.mixpanel.track('visit', {
          url: this.router.get('currentRoute.name')
        }) : null;
        this.get('sideMenu').close();
        this.send('closeModal');
      },
      openModal: function openModal(componentPath, model, record, action) {
        Ember.set(this.controllerFor('application'), 'showModal', true);
        Ember.set(this.controllerFor('application'), 'componentPath', componentPath);
        Ember.set(this.controllerFor('application'), 'model', model);
        Ember.set(this.controllerFor('application'), 'record', record);
        Ember.set(this.controllerFor('application'), 'action', action);
        document.getElementsByTagName('body')[0].classList.add('modal-open');
      },
      closeModal: function closeModal() {
        Ember.set(this.controllerFor('application'), 'showModal', false);
        document.getElementsByTagName('body')[0].classList.remove('modal-open');
      },
      error: function error(_error, transition) {
        var _this = this;

        console.log(_error);

        if (!Ember.isEmpty(_error.errors)) {
          _error.errors.forEach(function (error) {
            if (error.status == '403' || error.status == '401') {
              _this.transitionTo('mkt.not-authorized');

              return;
            }

            if (error.status == '404') {
              _this.transitionTo('mkt.not-found');

              return;
            }
          });
        }

        this._genericError("".concat(_error.code, " ").concat(_error.message));
      }
    },
    // Methods
    _loadMixpanel: function _loadMixpanel() {
      return !Ember.get(this, 'fastboot.isFastBoot') ? window.mixpanel.init(_environment.default.mixpanel.token) : null;
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);

      return this._loadCurrentUser();
    },
    _loadCurrentUser: function _loadCurrentUser() {
      if (this.get('session.isAuthenticated')) {
        return this.currentUser.get('loadUser').perform();
      }
    },
    _setHeadTags: function _setHeadTags(model, transition) {
      Ember.set(this, 'headData.siteName', 'Beauty Broker');
      Ember.set(this, 'headData.environment', _environment.default.environment);
      Ember.set(this, 'headData.logo', "".concat(_environment.default.APP.defaultWebHost, "/branding/bb-logo-share.jpg"));
      Ember.set(this, 'headData.favicon', "".concat(_environment.default.APP.defaultWebHost, "/branding/bb-icon.png"));
      Ember.set(this, 'headData.image', "".concat(_environment.default.APP.defaultWebHost, "/seo/tools.jpg"));
      Ember.set(this, 'headData.twitterImage', "".concat(_environment.default.APP.defaultWebHost, "/seo/tools-twitter.jpg"));
      Ember.set(this, 'headData.url', "".concat(_environment.default.APP.defaultWebHost));
      Ember.set(this, 'headData.facebookID', "".concat(_environment.default.facebook.appId));
      Ember.set(this, 'headData.pageTitle', 'Find and Book Beauty Professionals - Beauty Broker');
      Ember.set(this, 'headData.title', 'Find and Book Certified Beauty Professionals');
      Ember.set(this, 'headData.description', "Beauty Broker is an online marketplace that connects you to certified beauty professionals in your area that cover a variety of services from a basic haircut to bespoke spa-like treatments.");
      Ember.set(this, 'headData.hasBreadcrumbs', false);
      Ember.set(this, 'headData.breadcrumbs', JSON.stringify([]));
    }
  });

  _exports.default = _default;
});